import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Enquiry } from '@app/_models/enquiry.model';
import { EnquiryService } from '@app/_services/enquiry.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  enquiryForm: FormGroup;
  isLoading:boolean = false;
  loading = false;
  submitted = false;
  error = '';
  success = '';
  warning = '';
  constructor(private formBuilder: FormBuilder,
    private enquiryService: EnquiryService,private title: Title, private meta: Meta
    ) { 
      this.enquiryForm =this.formBuilder.group({
        name: new FormControl('', Validators.required),        
        email: new FormControl('',[Validators.required,Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
        subject: new FormControl('', Validators.required),
        message: new FormControl('', Validators.required),
        iAgree: new FormControl('', [Validators.required]),   
      });
      this.title.setTitle("Contact Us - KRL INC - Accurate Aerial Roof Reporting Services");
      this.meta.updateTag({ name: 'description', content: "If you're looking for a professional, reliable, and affordable roof reporting service, look no further than KRL INC. Contact us today to see for yourself why we are the trusted choice for all roof inspections, claim adjusters, etc." })
      this.meta.updateTag({ name: 'keywords', content: "Accurate aerial roof measurement reports Canada and usa,Satellite measured roof report USA and Canada,Best aerial roof reports USA and Canada" })

     }

  get f():any { return this.enquiryForm.controls; }

  onSubmitEnquiry() {
   
    if(this.submitted){
      return;
    }
    this.submitted = true;
    this.success = '';
    this.error = '';
    this.warning = '';
    // stop here if form is invalid
    if (this.enquiryForm.invalid) {
        return;
    }
    let enquiryData = this.enquiryForm.value;
    this.error = '';
    this.loading = true;
    this.enquiryService.sendEnquiry(enquiryData as Enquiry)
        .subscribe({
            next: (data:any) => {
                this.submitted = false;
                if(data.status){
                  this.success=data.message;
                  this.enquiryForm.reset();
                  this.enquiryForm.markAsUntouched();
                  this.enquiryForm.markAsPristine();
                }else{
                  this.error=data.message;
                }
                this.loading = false;
            },
            error: error => {
                this.submitted = false;
                this.error=error.message;
             
                this.loading = false;
            }
        });
}
}
