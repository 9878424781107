import { Component } from '@angular/core';

@Component({
  selector: 'app-our-features',
  templateUrl: './our-features.component.html',
  styleUrls: ['./our-features.component.scss']
})
export class OurFeaturesComponent {

}
