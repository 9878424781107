export class User {
    data?: UserData;
}

export class UserData {
    accessToken?: string;
    email?: string
    id?: number
    roles?: string[]
    tokenType?: string | "Bearer" | "Basic";
    username?: string
    user?: Profile
}

export class Profile{
    address?:string;
    city?:string;
    company?:string;
    countyId?:number
    email?:string;
    firstName?:string;
    lastName?:string;
    phoneNumber?:string;
    postCode?:number
    secondaryEmail?:string;
    stateId?:number;
}