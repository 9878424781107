import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle("Privacy Policy - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "" })
  }
}
