<section class="pt-0">
	<div class="container">
		<!-- Tabs and alert START -->

		<div class="row mb-4">
			<div class="col-12">
				<!-- Buttons -->
				<div class="hstack gap-3 justify-content-between justify-content-md-end">
					<!-- Filter offcanvas button -->

					<!-- <button class="btn btn-primary-soft btn-primary-check mb-0 d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
						
							<i class="bi bi-funnel"></i>Filter
					</button> -->
					<ul class="navbar-nav d-xl-none">
						<li class="nav-item ">
							<a class="nav-link rounded px-3 py-3 py-xl-0 sort-icon" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
								<i class="bi bi-funnel"></i> Filter
							</a>
						</li>
					</ul>
					<ul class="navbar-nav">
						<!-- Nav item 1 Demos -->
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle rounded px-3 py-3 py-xl-0 sort-icon" href="#" id="categoryMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="bi bi-filter-circle"></i> Sort by {{sortLable}}
							</a>
							<ul class="dropdown-menu" aria-labelledby="categoryMenu">
		
								<!-- Hotels -->
								<li class="mb-1">
									<a (click)="sortBy('orderDate')" class="dropdown-item bg-light-hover d-flex align-items-center rounded p-2" 
									[ngClass]="{'active': sortByStr==='orderDate' }">
										<!-- Icon -->
										<div class="icon-md bg-primary bg-opacity-10 rounded text-primary flex-shrink-0">
											<i class="bi bi-calendar-check"></i></div>
										<div class="ms-2">
											<div class="mb-0">Order Date</div>
											<p class="small mb-0 text-body">Sort Data By Order Date</p>
										</div>
									</a>	
								</li>
								
								<!-- Cabs -->
								<li class="mb-1">
									<a (click)="sortBy('orderItemStatus')" 
									class="dropdown-item bg-light-hover d-flex align-items-center rounded p-2"
									[ngClass]="{'active': sortByStr==='orderItemStatus' }">
										<!-- Icon -->
										<div class="icon-md bg-primary bg-opacity-10 rounded text-primary flex-shrink-0">
											<i class="bi bi-card-checklist"></i></div>
										<div class="ms-2">
											<div class="mb-0">Status</div>
											<p class="small mb-0 text-body">Sort By Order Status</p>
										</div>
									</a>	
								</li>
							</ul>
						</li>
					</ul>

					<button  *ngIf="!isMobile" data-bs-toggle="tooltip" 
						data-bs-placement="top" 
						title="Sort Ascending / Decending" 
						class="btn btn-primary-soft btn-primary-check mb-0 sort-direction" 
						type="button" (click)="sortOrderRevers()">
						<i class="bi bi-sort-down-alt" *ngIf="sortDirectionStr === 'asc'"></i>
						<i class="bi bi-sort-up-alt" *ngIf="sortDirectionStr === 'desc'"></i>
					</button>

					<button  *ngIf="isMobile" 
						class="btn btn-primary-soft btn-primary-check mb-0 sort-direction" 
						type="button" (click)="sortOrderRevers()">
						<i class="bi bi-sort-down-alt" *ngIf="sortDirectionStr === 'asc'"></i>
						<i class="bi bi-sort-up-alt" *ngIf="sortDirectionStr === 'desc'"></i>
					</button>
				</div>
			</div>
		</div>
	
		
		<div class="row g-2 mb-4" *ngIf="filterTags.length>0">
			
			<div class="col-lg-3">
				<h4 class="mb-0"></h4>
			</div>

			<div class="col-lg-9  d-flex justify-content-between">
				<div class="hstack flex-wrap gap-3 d-flex ">
					<div class="d-flex flex-wrap" *ngFor="let tag of filterTags">
						<div class="alert bg-light fade show small px-3 py-1 mb-0" role="alert">
							<span class="me-1" >
								<i class="bi bi-person fa-fw me-2" *ngIf="tag.key==='customerName'"></i>
								<i class="bi bi-geo-alt me-2" *ngIf="tag.key==='address'"></i>
								<i class="bi bi-calendar me-2" *ngIf="tag.key==='date'"></i>
								<i class="bi bi-patch-exclamation me-2" *ngIf="tag.key==='status'"></i>
								{{tag.value}}</span>
							
						</div>
						<button type="button" class="btn-close small p-2" data-bs-dismiss="alert" aria-label="Close" (click)="removeFromFilter(tag.key)"></button>
					</div>		
				</div>
				<div class="d-flex justify-content-md-end">
					<button type="button" class="btn btn-sm btn-primary-soft mb-0" (click)="clearAllAppliedFilters()"> Clear all</button>
				</div>
			</div>
		</div>

		<div class="row">
			<!-- Left sidebar START -->
			<aside class="col-xl-3 col-xxl-3">
				<!-- Responsive offcanvas body START -->
				<div class="offcanvas-xl offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
					<div class="offcanvas-header">
						<h5 class="offcanvas-title" id="offcanvasSidebarLabel">Advance Filters</h5>
						<button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
					</div>
					<div class="offcanvas-body flex-column p-3 p-xl-0">
						<div class="rounded-3 shadow max-height-inc">

							

							<!-- Hotel type START -->
							<div class="card card-body rounded-0 rounded-top p-4">
								<!-- <div class="col-12 mb-4 ">
										<ul class="nav nav-pills nav-pills-dark type-toggle" id="pills-tab" role="tablist">
											<li class="nav-item" role="presentation">
												<button class="nav-link rounded-start rounded-0 mb-0 active" id="pills-one-way-tab" data-bs-toggle="pill" data-bs-target="#pills-one-way" type="button" role="tab" aria-selected="true" (click)="toggle('order')">Orders</button>
											</li>
											<li class="nav-item" role="presentation">
												<button class="nav-link rounded-end rounded-0 mb-0" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('old-order')">Old Orders</button>
											</li>
										</ul>
								</div>	 -->
								<!-- <hr class="my-0"> -->
								<h6 class="mb-2 ">Search by Customer</h6>

								<div class="col-12">
									<input type="text" placeholder="Customer Name"
										class="form-guest-selector-custome form-control selection-result"  [(ngModel)]="filter.customerName">
									<!-- <ul class="nav nav-pills nav-pills-dark type-toggle" id="pills-tab" role="tablist">
										<li class="nav-item" role="presentation">
											<button class="nav-link rounded-start rounded-0 mb-0 " [ngClass]="{'active': groupBy==='orders'}" (click)="groupBy = 'orders'" id="pills-one-way-tab" data-bs-toggle="pill" data-bs-target="#pills-one-way" type="button" role="tab" aria-selected="true" >Orders</button>
										</li>
										<li class="nav-item" role="presentation">
											<button class="nav-link rounded-end rounded-0 mb-0" [ngClass]="{'active': groupBy==='individual'}" (click)="groupBy = 'individual'"  id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false">Individual</button>
										</li>
									</ul> -->
								</div>
							</div>

							<hr class="my-0">

							<div class="card card-body rounded-0 rounded-top p-4">
							
								<h6 class="mb-2">Search by Address</h6>
								<div class="dropdown guest-selector me-2">
									<input type="text" placeholder="Search by Address"
										class="form-guest-selector-custome form-control selection-result" [(ngModel)]="filter.address">
								</div>
							</div>

							<hr class="my-0">
							<div class="card card-body rounded-0 rounded-top p-4">
								<h6 class="mb-2">Status</h6>
								<div class="col-12">
									<div class="form-check">
										<input class="form-check-input" type="radio" [value]="null" id="label__for" [(ngModel)]="filter.status">
										<label class="form-check-label" for="label__for">All</label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="radio" [value]="orderStatus.PENDING" id="lable_all"  [(ngModel)]="filter.status">
										<label class="form-check-label" for="lable_all"> {{orderStatus.PENDING}} </label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="radio" [value]="orderStatus.PROCESSING" id="label_processing"  [(ngModel)]="filter.status"> 
										<label class="form-check-label" for="label_processing"> {{orderStatus.PROCESSING}}  </label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="radio" [value]="orderStatus.COMPLETED" id="label_completed"  [(ngModel)]="filter.status"> 
										<label class="form-check-label" for="label_completed"> {{orderStatus.COMPLETED}}  </label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="radio" [value]="orderStatus.CANCELED" id="label_cancelled"  [(ngModel)]="filter.status"> 
										<label class="form-check-label" for="label_cancelled"> {{orderStatus.CANCELED}}  </label>
									</div>
								</div>
							</div>

							<hr class="my-0">

							<!-- Price range START -->
							<div class="card card-body rounded-0 p-4">
								<!-- Title -->
								<h6 class="date-picker-label">Date range</h6>
								<!-- Price range group -->
								<div class="col-12">
									<date-time-picker [mode]="'range'" (dateChange)="filterDateChanged($event)" [clearDate]="clearDatePickerValue"></date-time-picker>
								</div>
							</div>
							<!-- Price range END -->
							<div class="d-flex justify-content-between p-2 p-xl-0 mt-xl-4  m-3">
								<button class="btn btn-link p-0 mb-0" (click)="clearAllAppliedFilters()" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" >Clear all</button>
								<button class="btn btn-primary mb-0" (click)="filterOrders()" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" >Filter Result</button>
							</div>
						</div><!-- Form End -->
					</div>
					<!-- Buttons -->
					
				</div>
				<!-- Responsive offcanvas body END -->
			</aside>
			<!-- Left sidebar END -->

			<!-- Main content START -->
			<div class="col-xl-9 col-xxl-9">
				<div class="vstack gap-4">
                    <app-loader *ngIf="isLoading"></app-loader>
					<app-no-data-found-placeholder style="padding:4%" *ngIf="orders.length==0 && !isLoading" [show_404]="false" [message]="myOrdersMessages.no_data_found" [subText]="myOrdersMessages.no_data_found_submessage" [showNavigationButton]="false"></app-no-data-found-placeholder>
					<app-pagination  *ngIf="!isLoading && orders.length>0" [current]="paginantion_CurrentPage" [total]="paginantion_Total" (goTo)="onGoTo($event)" (next)="onNext($event)" (previous)="onPrevious($event)">
					</app-pagination>
					<!-- <app-pagination [totalPages]="pagination.totalPages" [first]="pagination.first" [last]="pagination.last" [resetPagination]="resetPagination" (pageChange)="pageChange($event)" [ngClass]="{'d-none': isLoading}"></app-pagination> -->
					<ng-container *ngIf="!isLoading">
						<app-product-card-list [cart]="orders" [products]="products" [fromComponent]="tabs.MY_ORDERS" [isNew]="orderType"></app-product-card-list>
                    </ng-container>
					<app-pagination  *ngIf="!isLoading && orders.length>0" [current]="paginantion_CurrentPage" [total]="paginantion_Total" (goTo)="onGoTo($event)" (next)="onNext($event)" (previous)="onPrevious($event)">
					</app-pagination>
				</div>
			</div>
			<!-- Main content END -->
		</div> <!-- Row END -->
	</div>
</section>