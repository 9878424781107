import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CartItemUI, CartItemRequest, GetCartItemResponse } from '@app/_models';

import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(private http: HttpClient, private cartService: CartService) { }

  syncDataToServer() : Promise<boolean> {
   return new Promise((resolve) =>{
      try{
        const cart = this.cartService.getCartFromLocal() as CartItemUI[];
        cart.forEach(async(element:CartItemUI) => {
          let index = cart.indexOf(element);
          let cartItemRequest = new CartItemRequest(element);
          if(cartItemRequest.cartItemId === 0){
            await this.cartService.addToCartServer(cartItemRequest, index)
          }else{
            await this.cartService.updateToCartServer(cartItemRequest, index);
          }
        })
        resolve(true);
      }catch(error:any){
        resolve(false)
      }
    })
  }

  syncDataFromServer(): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        let localCart: CartItemUI[] = this.cartService.getCartFromLocal() as CartItemUI[];
        this.cartService.getCartFromServer().subscribe((data: GetCartItemResponse) => {
          if (data.status) {
            let serverCart = data?.data?.cartItems;
            serverCart?.forEach((sCart: any) => {
              let index = localCart.findIndex(x => x.cartItemId === sCart.cartItemId);
              if (index === -1) {
                localCart.push(new CartItemUI(sCart));
              }
            });
            this.cartService.updateCartItemsToLocal(localCart);
          }
        });
        resolve(true);
      } catch (error: any) {
        resolve(false);
      }
    })
  }

}
