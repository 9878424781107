<section class="common-sections-pb">
	<div class="container px-lg-2">

		<!-- Title -->
		<div class="row mb-4">
			<div class="col-12 text-center">
                <!-- <h2>Our Features</h2> -->
				<h2>Our <span class="text-primary">Features</span></h2>
            </div>
        </div>
		
		<!-- Content END -->

		<!-- Services START -->
		<div class="row g-4 justify-content-center mt-5">
			<!-- Service item -->
			<div class="col-sm-6 col-lg-4">
				<div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-2"><i class="fa-solid fa-heartbeat fs-5"></i></div>
				<h5 class="mt-2">Life saver</h5>
				<p class="mb-0">Eliminate the risk (accident) caused by climbing a ladder for roof measurements. Increases safety and no risk of injury.</p>
			</div>

			<!-- Service item -->
			<div class="col-sm-6 col-lg-4">
				<div class="icon-lg bg-success bg-opacity-10 text-success rounded-2"><i class="fas fa-gas-pump fs-5"></i></div>
				<h5 class="mt-2">Fuel saver</h5>
				<p class="mb-0">Expand your reach without filling your gas tank.</p>
			</div>

			<!-- Service item -->
			<div class="col-sm-6 col-lg-4">
				<div class="icon-lg bg-primary bg-opacity-10 text-primary rounded-2"><i class="fa fa-usd fs-5"></i></div>
				<h5 class="mt-2">Money saver</h5>
				<p class="mb-0">Reduce project estimation cost by 50% and more.</p>
			</div>

			<!-- Service item -->
			<div class="col-sm-6 col-lg-4">
				<div class="icon-lg bg-info bg-opacity-10 text-info rounded-2"><i class="bi bi-stopwatch-fill fs-5"></i></div>
				<h5 class="mt-2">Report on the go</h5>
				<p class="mb-0">Our service will give you a fast first visit to remote projects from your couch for closing a deal in your favour.</p>
			</div>

            <!-- Service item -->
			<div class="col-sm-6 col-lg-4">
				<div class="icon-lg bg-info bg-opacity-10 text-info rounded-2"><i class="bi bi-pencil-square fs-5"></i></div>
				<h5 class="mt-2">Do more bids</h5>
				<p class="mb-0">By creating a preliminary bid before you go out to the property, you'll be able to save time in the field.</p>
			</div>

            <!-- Service item -->
			<div class="col-sm-6 col-lg-4">
				<div class="icon-lg bg-info bg-opacity-10 text-info rounded-2"><i class="fa fa-cogs fs-5"></i></div>
				<h5 class="mt-2">Customization</h5>
				<p class="mb-0">Get your reports customized with your logo and other desired details to impress  your clients and add value to your company.</p>
			</div>
		</div>
        
		<!-- Services END -->
	</div>
</section>