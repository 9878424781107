import { Component } from '@angular/core';
import { APP_ICONS } from '@app/_services';

@Component({
  selector: 'app-user-profile-side-menu',
  templateUrl: './user-profile-side-menu.component.html',
  styleUrls: ['./user-profile-side-menu.component.scss']
})
export class UserProfileSideMenuComponent {

  public app_icon = APP_ICONS
}
