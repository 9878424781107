<app-services-page style="display: none;"></app-services-page>

<section class="pt-4 pt-md-5">
    <div class="container">
        <div class="row g-4 align-items-center">
            <div class="col-lg-7">
                <h1 class="mb-4 display-5">Looking For <span style="color: #664b9b;">Commercial Roof Reports</span></h1>
                <p class="mb-4">These Commercial roof reports are of Large commercial complexes and used for commercial purposes (churches, schools, hospitals multifamily condos, etc.) and are drafted using a combination of high-resolution satellite and drone imagery.</p>
                <a (click)="orderNow('commercial')" class="btn btn-primary mb-4">Order Now <i
                        class="fa-solid fa-arrow-right-long fa-fw"></i></a>
                <h6 class="fw-normal mb-1">What we offer nobody else is offering:</h6>
                <ul class="list-group list-group-borderless mb-0 small">
                    <li class="list-group-item d-flex mb-0">
                        <i class="fa-solid fa-check-circle text-success me-2 mt-1"></i>Detailed roof reports with
                        unmatched accuracy.
                    </li>
                    <li class="list-group-item d-flex mb-0">
                        <i class="fa-solid fa-check-circle text-success me-2 mt-1"></i>3D file which run directly on any
                        web browser and shows your in interactive 3D environment with measurements.
                    </li>
                    <li class="list-group-item d-flex mb-0">
                        <i class="fa-solid fa-check-circle text-success me-2 mt-1"></i>An HTML file with all facet
                        details for clear understanding of the roof.
                    </li>
                </ul>
            </div>
            <div class="col-lg-5 text-center">
                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/report_web/Com.webp" alt="">
            </div>
        </div>
    </div>
</section>
<section class="pt-0">
    <div class="container">
        <div class="row g-4 align-items-center">
            <div class="col-sm-6 col-md-3">
                <h4 class="mb-0">Numbers Of Reports Delivered</h4>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="card card-body text-center bg-light">
                    <div class="d-flex justify-content-center">
                        <h3 class="purecounter text-orange mb-0" data-purecounter-start="0" data-purecounter-end="28"
                            data-purecounter-delay="300">50</h3>
                        <span class="h3 text-orange mb-0">K+</span>
                    </div>
                    <h6 class="fw-normal mb-0">Residential Roof Reports</h6>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="card card-body text-center bg-light">
                    <div class="d-flex justify-content-center">
                        <h3 class="purecounter text-info mb-0" data-purecounter-start="0" data-purecounter-end="51"
                            data-purecounter-delay="300">27</h3>
                        <span class="h3 text-info mb-0">K+</span>
                    </div>
                    <h6 class="fw-normal mb-0">Commercial Roof Reports</h6>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="card card-body text-center bg-light">
                    <div class="d-flex justify-content-center">
                        <h3 class="purecounter text-primary mb-0" data-purecounter-start="0" data-purecounter-end="41"
                            data-purecounter-delay="300">3</h3>
                        <span class="h3 text-primary mb-0">K+</span>
                    </div>
                    <h6 class="fw-normal mb-0">Counties Covered Through US & Canda</h6>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-0 pt-md-5">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="bg-light p-4 p-sm-5 rounded-3">
					<!-- Title -->
					<h5 class="fs-4 text-center mb-4 mb-sm-5 justify-content-center"  style="text-align:justify;"><span class="text-primary">KRL INC.</span> roof reports are drafted using high-resolution satellite and drone imagery and offers several important benefits and serve various purposes</h5>

					<div class="row g-4">
						<!-- Card START -->
						<div class="col-md-6">
							<div class="card card-body p-4">
							 <div><img src="assets/images/element/remote-3.svg" class="h-70px mb-3" alt=""></div> 
								<h5>Remote Assessment</h5>
								<p class="mb-0"  style="text-align:justify;"><span class="text-primary">KRL INC.</span> Roof reports allow for remote assessment of residential roofs without the need for physical inspection. This is particularly useful in cases where accessing the roof may be challenging or unsafe.</p>
							</div>
						</div>
						<!-- Card END -->
<!-- Card START -->
<div class="col-md-6">
    <div class="card card-body p-4">
        <div><img src="assets/images/element/safety-1.svg" class="h-70px mb-3" alt=""></div>
        <h5>Safety</h5>
        <p class="mb-0" ><span class="text-primary">KRL INC.</span> roof reports eliminates the need for inspectors to physically climb onto roofs, thereby reducing the risk of accidents or injuries. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    </div>
</div>
<!-- Card END -->
						<!-- Card START -->
						<div class="col-md-6">
							<div class="card card-body p-4">
								<div><img src="assets/images/element/comprehensive-2.svg" class="h-70px mb-3" alt=""></div>
								<h5>Comprehensive Coverage</h5>
								<p class="mb-0" style="text-align:justify;">The use of high-resolution Satellite imagery provides an overview of the entire roof structure, while drone imagery allows for detailed close-ups of specific areas of interest. Together, they offer comprehensive coverage, ensuring that no potential issues are overlooked and draft an utmost accurate report. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
							</div>
						</div>
                        <div class="col-md-6">
							<div class="card card-body p-4">
								<div><img src="assets/images/element/data-analysis-2.svg" class="h-70px mb-3" alt=""></div>
								<h5>Data Analysis</h5>
								<p class="mb-0" style="text-align:justify;"><span class="text-primary">KRL INC.</span> comprehensive roof reports, which consist of XML files, HTML files, 3D CAD files, etc., can be analyzed using a variety of software tools to detect patterns, trends, or irregularities in roof conditions. This data-driven method improves assessment accuracy and supports well-informed decision-making for repairs or maintenance.</p>
								</div>
						</div>
						<!-- Card END -->

						

						
                      
<!-- Card START -->
<div class="col-md-6">
    <div class="card card-body p-4">
        <div><img src="assets/images/element/efficiency-1.svg" class="h-70px mb-3" alt=""></div>
        <h5>Efficiency</h5>
        <p class="mb-0" style="text-align:justify;">The use of Satellite and drone inspections makes <span class="text-primary">KRL INC.</span> roof reports delivery much faster and more efficient compared to traditional on-site inspections. This efficiency is particularly beneficial when assessing multiple properties or large residential complexes.

        </p>
    </div>
</div>
<!-- Card END -->
                        <div class="col-md-6">
							<div class="card card-body p-4">
								<div><img src="assets/images/element/saving.svg" class="h-70px mb-3" alt=""></div>
								<h5>Cost-Effective and Time-Saving</h5>
								<p class="mb-0" style="text-align:justify;">he use of <span class="text-primary">KRL INC.</span> roof reports makes estimation process more cost-effective and time-saving compared to traditional methods. It eliminates the need for manual inspections, reduces labor costs, and minimizes the time required to complete the assessment.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="common-sections-pb">
	<div class="container px-lg-2">

		<div class="row mb-4">
			<div class="col-12 text-center">
				<h2>Explore <span class="text-primary">Our Reports</span></h2>
			</div>
		</div>
		<div class="row justify-content-center" style="border-width: 1px;">
            <div class="tiny-slider arrow-round arrow-blur arrow-hover">
                <div class="tiny-slider-inner" data-autoplay="true" data-arrow="true" data-dots="false" data-items="1">
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/reports/comm1.webp" class="rounded" alt="Commercial report 1">
                            </div>
                            <div class="col-lg-6 mt-2">
                                <a class="btn btn-primary w-100 mb-0"  (click)="downlaodPDF()" >Download Sample</a>
                            </div>
                        </div>
                    </div>	
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/reports/comm2.webp" class="rounded" alt="Commercial report 2">
                            </div>
                            <div class="col-lg-6 mt-1">
                                <a class="btn btn-primary w-100 mb-0" (click)="downlaodPDF()"  >Download Sample</a>
                            </div>
                        </div>
                    </div>	
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/reports/comm3.webp" class="rounded" alt="Commercial report 3">
                            </div>
                            <div class="col-lg-6 mt-2">
                                <a class="btn btn-primary w-100 mb-0" (click)="downlaodPDF()"  >Download Sample</a>
                            </div>
                        </div>
                    </div>	                   
                </div>
            </div>
		</div>
	</div>
</section>



<section>
    <div class="container">
        <div class="row g-4">
            <div class="col-12">
                <!-- Action box START -->
                <div class="card card-body shadow p-4">
                    <div class="row g-4 justify-content-between align-items-center">
                        <!-- Image -->
                        <div class="col-sm-3 col-lg-2">
                            <img src="https://ik.imagekit.io/krlinc/assets/images/element/22.svg" alt="contact-us">
                        </div>

                        <!-- Title and content -->
                        <div class="col-sm-9 col-lg-6 col-xl-7">
                            <h4>Have More Questions?</h4>
                            <p class="mb-0">If you have any questions/query or need any assistance please contact us on corporate@krlinc.com</p>
                        </div>

                        <!-- Button -->
                        <div class="col-lg-3 col-xxl-2 d-grid">
                            <a [routerLink]="['/contact-us']" class="btn btn-primary mb-0">Contact Us</a>
                        </div>
                    </div>
                </div>
                <!-- Action box END -->
            </div>
        </div>
    </div>
</section>
