import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DATE_FORMATES } from '@app/_helpers';
import { Utill } from '@app/_helpers/utill';
import { CartItemRequest, OrdersGroupBy, Orders, CartItemUI } from '@app/_models';
import { CONFIG_MESSAGES, OrderService, STATUS, PAYMENT_STATUS, StorageService, TABS } from '@app/_services';
import { CartService } from '@app/_services/cart.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {

  public approvalLink: string | undefined = '';
  public cartItem: CartItemRequest[] | undefined = [];
  public total: any;
  public paymentStatus = '';
  public status: any = PAYMENT_STATUS;
  public orderStatus:any = STATUS;
  public myOrdersMessages:any= CONFIG_MESSAGES.my_order
  public isLoading: boolean = false;
  public orders:CartItemUI[]=[];
  orderMap: OrdersGroupBy[] = [];
  products = new Map();
  groupBy:string='orders'
  public tabs=TABS;
  filterTags:any[]=[];
  orderType = true;
  public pagination:any={
    totalPages : 0,
    totalElements : 0,
    size : 0,
    first : true,
    last : false,
    number: 0
  }
  public resetPagination:number = 0;
  public clearDatePickerValue = 0;

  paginantion_Total:number = 0;
  paginantion_CurrentPage:number = 1;
  isMobile:boolean=false;
  sortByStr:string = 'orderDate';
  sortDirectionStr:string = 'desc';

  constructor(private cartService: CartService,
    private ordersService: OrderService,
    private utill: Utill,
    private deviceService: DeviceDetectorService,private title: Title, private meta: Meta) { 
      this.title.setTitle("My Orders - KRL INC.");
        this.meta.updateTag({ name: 'description', content: "" })
    }

  ngOnInit(): void {
    this.isLoading = true;
    this.getOrders();
    this.isMobile = this.deviceService.isMobile();
  }

 
  clearCart() {
    this.cartService.clearCart()
  }

  proceedToPayment() {
    window.open(this.approvalLink, '_self');
  }

 
  getOrders(number?:number, sort?:Object) {
    let filterHasChange = false;
   let body:any = {
      page: (number && number>0)?(number-1):0
    }

    body['sortOrders'] = {
      [this.sortByStr] : this.sortDirectionStr,
    }

    Object.keys(this.filter).forEach((key: string)=>{
      if(this.filter[key]){
        body[key]=this.filter[key];
        // filterHasChange = true;
      }
    });
    if(filterHasChange){
      body.page = 0;
      this.resetPagination ++;
      this.pagination.number = 0;
    }
    this.generateFilterTags()
    this.isLoading = true;
    this.ordersService.getOrders(body,this.orderType).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        if (data.status == true) {
          this.mapOrderData(data.data.content);
          this.orders = [];
          if( this.pagination.number === 0){
            this.pagination.first = data.data.first
            this.pagination.last = data.data.last;
            this.pagination.totalPages = data.data.totalPages;
            this.pagination.totalElements = data.data.totalElements;
            this.pagination.size = data.data.size;
          }
          this.paginantion_Total = 0;
          this.paginantion_CurrentPage = 0;
          setTimeout(()=> {
            this.paginantion_Total = data.data.totalPages;
            this.paginantion_CurrentPage = data.data.number+1;
          },100)
          data.data.content.forEach((order: any) => {
            order['isCollapsed'] = false;
            let cartItemUI=new CartItemUI(order)
            let addons=[]
            let addonTotal=0
            if (order['addons']!=null){
            for(let addon of order['addons'])
            {
              addons.push(addon.displayName)
              addonTotal=addonTotal+addon.price
            }
          }
            cartItemUI.addedAddons=addons
            cartItemUI.price=Math.floor(cartItemUI.price)+(addonTotal)
            this.orders.push(cartItemUI);
          })
        }
      }, error: err => {
        this.isLoading = false;
        console.log(err);
      }
    });
  }

  mapOrderData(orders: Orders[]) {
    orders.forEach((order: Orders) => {
      let isOrderInvoiceAvailable = this.orderMap.findIndex(x => x.invoiceId == order.invoiceId);
      if (isOrderInvoiceAvailable === -1) {
        this.orderMap.push(new OrdersGroupBy({
          invoiceId: order.invoiceId,
          status: this.getAvgStatus(orders, order.invoiceId),
          orderDate: order.orderDate,
          orders: this.getSameInvoiceOrders(orders, order.invoiceId),
          total: this.getTotal(orders, order.invoiceId),
        }))
      }
    });
  }

  getSameInvoiceOrders(orders: Orders[], invoiceId: any): Orders[] {
    let sameOrders = orders.filter(x => x.invoiceId === invoiceId);
    return sameOrders
  }


  getAvgStatus(orders: Orders[], invoiceId: any) {
    return 'Success'
  }

  getTotal(orders: Orders[], invoiceId: any) {
    let sameOrders = orders.filter(x => x.invoiceId === invoiceId);
    var result = sameOrders.reduce(function (tot, arr) {
      return tot + arr.price;
    }, 0);
    return result
  }

  pageChange(number: number){
    this.getOrders(number);
  }

  onGoTo(event:any){
    this.paginantion_CurrentPage = event;
    this.getOrders(this.paginantion_CurrentPage);
  }

  onNext(event:any){
    this.paginantion_CurrentPage = event+1;
    this.getOrders(this.paginantion_CurrentPage);
  }
  onPrevious(event:any){
    this.paginantion_CurrentPage = event-1;
    this.getOrders(this.paginantion_CurrentPage);
  }

  filter:any={
    startDate: null,
    endDate: null,
    address:null,
    customerName:null,
    status:null
  }

  filterDateChanged(event: any){
    if(event && event.from && event.to){
      this.filter.startDate = this.utill.formateDate(new Date(event.from), DATE_FORMATES.YYYY_mm_DD) ;
      this.filter.endDate =this.utill.formateDate(new Date(event.to), DATE_FORMATES.YYYY_mm_DD) ;
    }
  }

  filterOrders(){
    this.getOrders();
  }

  removeFromFilter(key:string){
    if(key === 'date'){
      this.filter.endDate = null;
      this.filter.startDate = null;
      this.clearDatePickerValue++
    }else{
      this.filter[key]=null;
    }
    this.getOrders();
  }

  generateFilterTags(){
    this.filterTags = []
    Object.keys(this.filter).forEach(key=>{
      if(this.filter[key]){
        if(key === 'startDate'){
          this.filterTags.push({key:'date',value: this.filter['startDate']+' to '+ this.filter['endDate']})
        }else if(key !='endDate'){
          this.filterTags.push({key:key, value: this.filter[key]});
        }
      }
    })
  }

  clearAllAppliedFilters(){
    Object.keys(this.filter).forEach(key=>{
      this.filter[key] = null;
    });
    this.clearDatePickerValue++
    this.getOrders();
  }

  toggle(orderTypeStr:any)
  {
    this.orderType = orderTypeStr==='order'? true :false;
    this.getOrders();
  }

  sortBy(sortByStr:any){
    this.sortByStr = sortByStr;
    this.getOrders();
  }

  sortOrderRevers(){
    this.sortDirectionStr = (this.sortDirectionStr === 'asc') ?  'desc' : 'asc';
    this.getOrders();
  }

  get sortLable(){
    return this.sortByStr === 'orderDate' ? 'Order' : 'Status'
  }
}
