import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-property-map',
  templateUrl: './view-property-map.component.html',
  styleUrls: ['./view-property-map.component.scss']
})
export class ViewPropertyMapComponent {
  constructor(private route: ActivatedRoute) { }
  center: google.maps.LatLngLiteral = { lat: 36.456440, lng: -116.869582 };
  markerPosition: any;
  options: google.maps.MapOptions = {
    tilt: 0,
    mapTypeId: 'satellite',
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
    // streetViewControlOptions: {
    //   position: google.maps.ControlPosition.RIGHT_TOP,
    // },
    scrollwheel: true,
    disableDoubleClickZoom: true,
    zoom: 19,
    disableDefaultUI: true,
    heading: 90,
    fullscreenControl: true,
    scaleControl: true,
    streetViewControl: false,
    
  };
  markerOptions: google.maps.MarkerOptions = { 
    draggable: false,  
    clickable: false
  };
  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
     
      try{
        
        let  lng: number = +params["lat"];
        let lat: number = +params["lng"];
        this.markerPosition = { lat: lng, lng: lat };
        this.center = this.markerPosition;
      }catch{

      }
      
      
    });
   
  }
}
