<!-- Cancellation modal START -->
<div class="modal fade" id="cancellation" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<!-- Title -->
			<div class="modal-header" *ngIf="title">
				<h5 class="modal-title" id="cancellationlabel">{{title}}</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>

			<!-- Body -->
			<div class="modal-body p-3" *ngIf="message">

                <div>
                    {{message}}
                </div>
			</div>
            <div class="modal-body action p-3">
                    <a class="btn btn-sm btn-primary-soft mb-0" data-bs-toggle="modal" data-bs-target="#cancellation"  (click)="onYes()">Yes</a>    
                    <a class="btn btn-sm btn-light mb-0"  data-bs-toggle="modal" data-bs-target="#cancellation" (click)="onCancel()">Cancel</a>    
            </div>
		</div>
	</div>
</div>
<!-- Cancellation modal END -->