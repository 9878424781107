import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TYPE } from '@app/alert/alert.component';
import { AlertService, AuthenticationService } from '@app/_services';
import { CountryService } from '@app/_services/country.service';
import { environment } from '@environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

  public country: any[] = [];
  public state: any = [];
  public registerForm!: FormGroup;
  public passwordStrengthValid: boolean = false;
  public loading: boolean = false;
  public type = TYPE;
  public signUpCompleted=false

  constructor(
    private countryService: CountryService,
    fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private title: Title, private meta: Meta) {
      this.title.setTitle("Sign Up - KRL INC.");
      this.meta.updateTag({ name: 'description', content: "KRL INC. provides the best most accurate and most affordable aerial roof reports in USA and Canada. Our expert team uses satellite imagery, state-of-the-art technology, and industry-leading practices to provide detailed and reliable reports." })
    this.signUpCompleted=false
    // redirect to home if already logged in
    if (this.authService.userValue) {
      this.router.navigate(['/']);
      return
    }
    this.registerForm = fb.group({
      email: fb.control('', [Validators.required]),
      secondaryEmail: fb.control(''),
      phoneNumber: fb.control('', [Validators.pattern('[- +()0-9]*$')]),
      address: fb.control('', [Validators.required]),
      city: fb.control('', [Validators.required]),
      countyId: fb.control('', [Validators.required]),
      postCode: fb.control('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      stateId: fb.control('', [Validators.required]),
      company: fb.control(''),
      firstName: fb.control('', [Validators.required]),
      lastName: fb.control('', [Validators.required]),
      password: fb.control('', [Validators.required]),
      check: fb.control('', [Validators.required]),
      iAgree: fb.control('', [Validators.required]),
      emailVerificationUrl: fb.control(environment.emailVerifyUrl, [Validators.required])
    });
  }

  ngOnInit() {
    this.countryService.getCountryAndState().subscribe((data: any) => {
      this.country = data;
      this.state = data[0].states;
      this.registerForm.controls['countyId'].setValue(this.country[0].id);
      this.registerForm.controls['stateId'].setValue(this.state[0].id);
    });

    this.registerForm.addValidators(this.createCompareValidator(this.registerForm.controls['password'], this.registerForm.controls['check']));
  }

  onCountryChange(event: any) {
    this.state = [];
    if (event?.target?.value) {
      this.state = this.country.find(data => data.id === parseInt(event.target.value)).states;
    }
  }


  createCompareValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if ((controlTwo.value == '' && controlOne.value == '') || controlOne.value !== controlTwo.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }

  passwordValid(event: any) {
    this.passwordStrengthValid = event;
  }

  onSubmit() {
    if (this.registerForm.invalid) return
    this.loading = true;
    this.authService.register(this.registerForm.value).subscribe({
      next: data => {
        if (data.status) {
          // this.alertService.showAlert({
          //   message: 'Signup completed successfully, please Login.',
          //   type: TYPE.success,
          //   mode: 'fixed-header',
          // });
          // this.router.navigate(['/login'])
          this.signUpCompleted=true
        }
      },
      error: err => {
        console.log(err);
        this.loading = false;
      }
    })
  }
}
