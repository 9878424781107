import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-accuracy',
  templateUrl: './accuracy.component.html',
  styleUrls: ['./accuracy.component.scss']
})
export class AccuracyComponent {
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle("Accuracy - KRL INC - Accurate Aerial Roof Reporting Services USA, Canada");
    this.meta.updateTag({ name: 'description', content: "At KRL INC, we understand the importance of providing accurate and reliable roof reporting services. We take great care to ensure that our drafted reports are as comprehensive and accurate as possible." })
    this.meta.updateTag({ name: 'keywords', content: "Accurate aerial roof measurement reports Canada and usa,Accurate satellite measured roof report USA and Canada,Best and accurate aerial roof reports USA and Canada" })

  }
}
