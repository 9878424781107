import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CopyrightsComponent } from './copyrights/copyrights.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { DateTimePickerComponent } from './date-picker/date-picker.component';
import { OrderNowComponent } from './order-now/order-now.component';
import { NotificationComponent } from './notification/notification.component';
import { FaqComponent } from './faq/faq.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { AlertComponent } from './alert/alert.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GoogleMap } from '@angular/google-maps';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { UserProfileSideMenuComponent } from './user-profile-side-menu/user-profile-side-menu.component';
import { NoDataFoundPlaceholderComponent } from './no-data-found-placeholder/no-data-found-placeholder.component';
import { ProductCardListComponent } from './product-card/product-card.component';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { PaymentStatusPageComponent } from './payment-status-page/payment-status-page.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OurFeaturesComponent } from './our-features/our-features.component';
import { WhyChooseUsComponent } from './why-choose-us/why-choose-us.component';
import { WhoUsesOurReportsComponent } from './who-uses-our-reports/who-uses-our-reports.component';
import { AppIntoVideoComponent } from './app-into-video/app-into-video.component';
import { AccuracyComponent } from './accuracy/accuracy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { UpdateLogoComponent } from './update-logo/update-logo.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MobileBottomNavigationComponent } from './mobile-bottom-navigation/mobile-bottom-navigation.component';
import { ClientTestimonialsComponent } from './client-testimonials/client-testimonials.component';
import { ViewPropertyMapComponent } from './view-property-map/view-property-map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LazyImgDirective } from './shared/directive/lazy-image-directive';
import { BluePrintReportComponent } from './blue-print-report/blue-print-report.component';
import { TooltipModule } from './shared/directive/tooltip/tooltip.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { BrowserNotSupportComponent } from './browser-not-support/browser-not-support.component';
import { ViewPropertyThreedMapComponent } from './view-property-threed-map/view-property-threed-map.component';
import { SafeUrlPipe } from './_helpers/safe-url.pipe';
import { CommercialReportComponent } from './commercial-report/commercial-report.component';
import { GutterReportComponent } from './gutter-report/gutter-report.component';
import { ResidentialReportComponent } from './residential-report/residential-report.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    CopyrightsComponent,
    DateTimePickerComponent,
    OrderNowComponent,
    NotificationComponent,
    FaqComponent,
    PricingPageComponent,
    ContactUsComponent,
    ServicesPageComponent,
    AlertComponent,
    SignUpComponent,
    PasswordStrengthComponent,
    CartComponent,
    CheckoutComponent,
    UserProfileSideMenuComponent,
    NoDataFoundPlaceholderComponent,
    ProductCardListComponent,
    LoaderComponent,
    ConfirmationPopupComponent,
    PaymentStatusPageComponent,
    MyOrdersComponent,
    OurFeaturesComponent,
    WhyChooseUsComponent,
    WhoUsesOurReportsComponent,
    AppIntoVideoComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    AccuracyComponent,
    AboutUsComponent,
    PaginationComponent,
    ForgotPasswordComponent,
    MyProfileComponent,
    UpdateLogoComponent,
    MobileBottomNavigationComponent,
    ChangePasswordComponent,
    ClientTestimonialsComponent,
    ViewPropertyMapComponent,
    LazyImgDirective,
    BluePrintReportComponent,
    BrowserNotSupportComponent,
    ViewPropertyThreedMapComponent,   
    SafeUrlPipe, CommercialReportComponent, GutterReportComponent, ResidentialReportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    FormsModule,
    FlatpickrModule.forRoot(),
    GoogleMapsModule,
    //@ts-nocheck
    GooglePlaceModule,
    TooltipModule,
    NgxPayPalModule,
   
  ],
  exports: [ReactiveFormsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // // provider used to create fake backend
    // fakeBackendProvider,
    GoogleMap,
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
