import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-commercial-report',
  templateUrl: './commercial-report.component.html',
  styleUrls: ['./commercial-report.component.scss']
})
export class CommercialReportComponent {
  constructor(private title: Title, private meta: Meta, private router: Router) {
    this.title.setTitle("Commercial Roof Reports USA, Canada - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "Large commercial complex and used for commercial purpose." })
    this.meta.updateTag({ name: 'keywords', content: "Commercial Roof Report,Commercial Roof Report USA,Commercial Roof Report Canada" })

  }

  orderNow(type: string) {
    this.router.navigateByUrl('/order-now', { state: { search: '', reportType: type } });
  
  }
  
  downlaodPDF(){
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href ='assets/downloads/commercial-sample-report.pdf'
    link.download = 'commercial-sample-report';
    link.click();
    link.remove();
  }
}
