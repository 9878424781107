<!-- Update logo START -->
<div class="card border">
    <!-- Card header -->
    <div class="card-header border-bottom">
      <h4 class="card-header-title">Update Logo</h4>
    </div>

    <!-- Card body START -->
    <div class="card-body">
      <!-- Profile photo -->
      <div class="col-12">
        <label class="form-label">Upload your company logo</label>
        <div class="d-flex align-items-center">
          <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
            <!-- Avatar place holder -->
            <span class="avatar avatar-xl">
              <img id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow"
                src="https://ik.imagekit.io/krlinc/assets/images/avatar/01.jpg" alt="Profile Avatar">
            </span>
          </label>
          <!-- Upload button -->
          <!-- <label class="btn btn-sm btn-primary-soft mb-0" for="uploadfile-1">Change</label>
          <input id="uploadfile-1" class="form-control d-none" type="file"> -->
        </div>
      </div>
    </div>
    <!-- Card body END -->
  </div>
  <!-- Update logo END -->