import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { GoogleMap, MapGeocoder, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { TYPE } from '@app/alert/alert.component';
import { Utill } from '@app/_helpers/utill';
import { CartItemRequest, CartItemUI } from '@app/_models';
import { AlertService, SEARCHED_STATE, StorageService, UI_MODE, } from '@app/_services';
import { CartService } from '@app/_services/cart.service';
import { ProductService } from '@app/_services/product.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-order-now',
  templateUrl: './order-now.component.html',
  styleUrls: ['./order-now.component.scss']
})
export class OrderNowComponent {

  cartForm: FormGroup = new FormGroup({
    address: new FormControl('', Validators.required),
    longitude: new FormControl('', Validators.required),
    latitude: new FormControl('', Validators.required),
    reportType: new FormControl('', Validators.required),
    customerName: new FormControl(''),
    pitch: new FormControl(''),
    expectedDeliveryWithin: new FormControl('72 Hours'),
    includeSecondaryStructure: new FormControl('No'),
    includeObstructions: new FormControl('No'),
    notes: new FormControl(''),
    modelingInstructions: new FormControl(''),
    addedAddons:new FormArray([])
  });
  markerPosition: any;
  address: string = "";
  products: any;
  productOptions: any;
  productAddons: any;
  pitchValues: any;
  expectedDeliveryWithinValues: any;
  yesNo: string[] = ["No", "Yes"];
  price: number = 0;
  buttonTitle: string = 'Add To Cart';
  selectedReportType: number = 30;
  submitted = false;
  formattedaddress = " ";
  showIncludeObstructions:boolean=false
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
    clickable: true,
    animation: google.maps.Animation.BOUNCE,
  };

  markerInfo: any = '';
  indexID: number = -1;
  cartItemId: number = 0;
  selectedAddressType: string = 'Enter Address';
  isExpanded: boolean = false;
  isAddressChecked: boolean = true;
  isLatLongChecked: boolean = false;
  loading: boolean = false;
  center: google.maps.LatLngLiteral = { lat: 43.651070, lng: -79.347015 };
  options: google.maps.MapOptions = {
    tilt: 0,
    mapTypeId: 'satellite',
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: true,
    panControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    zoom: 19,
    disableDefaultUI: false,
    heading: 90,
    fullscreenControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    gestureHandling: "greedy",
  };
  searchState: any = null;
  reportTypeState: any = null;
  isLoading: boolean = false;
  addonsFormArray:any=new FormArray([]);
  addonsNames:any={}
  showIncludeSecondaryStructure:boolean=true
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  @ViewChild('map', { static: true }) map!: GoogleMap;
  @ViewChild('EXPANED_ITEM') expanedItem!: ElementRef;
  @ViewChild('COLLAPSED_VIEW') collapsedItem!: ElementRef;
  @ViewChild(MapMarker) mapMarker!: MapMarker;
  @ViewChild('AUTOCOMPLETE_INPUT', { read: ElementRef }) streetElem!: ElementRef;
  isMobileView: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private cartService: CartService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private geocoder: MapGeocoder,
    private router: Router,
    private storeService: StorageService,
    private utill: Utill,
    private alertService: AlertService,
    private deviceService: DeviceDetectorService,
    private title: Title, private meta: Meta) {
      this.title.setTitle("Order Now - KRL INC.");
      this.meta.updateTag({ name: 'description', content: "KRL INC. provides the best most accurate and most affordable aerial roof reports in USA and Canada. Our expert team uses satellite imagery, state-of-the-art technology, and industry-leading practices to provide detailed and reliable reports." })
      
      this.meta.updateTag({ name: 'keywords', content: "Residential roof reports,Commercial roof reports,Gutter length reports,Blueprint reports" })

      this.isMobileView = this.deviceService.isMobile()
    let searchState = this.storeService.getLocalSt(SEARCHED_STATE);
    this.searchState = this.router.getCurrentNavigation()?.extras?.state?.['search'] ? this.router.getCurrentNavigation()?.extras?.state?.['search'] : searchState?.search;
    this.reportTypeState = this.router.getCurrentNavigation()?.extras?.state?.['reportType'] ? this.router.getCurrentNavigation()?.extras?.state?.['reportType'] : '';
  }

  ngOnInit(): void {
    let index = this.route.snapshot.paramMap.get('itemIndex') as any;
    let itemId = this.route.snapshot.paramMap.get('itemId') as any;
    this.buttonTitle = 'Add To Cart';
    this.indexID = -1;
    this.getProductsAndOptions();
 
    if (index) {
      // this.isExpanded = true;
      this.indexID = parseInt(itemId);
      this.buttonTitle = 'Update';
      setTimeout(() => {
        this.updateCart(index, itemId);
      })


    } else {
      this.setLocationAndAddress();
    }

    

  }

  afterViewInit() {
    setTimeout(() => {
      if(this.map){
        var aListener = google.maps.event.addListener(this.map, 'click', function (event: any) {
          // Try to prevent event propagation to the map
          if(event){
           
            event.stop();
            event.cancelBubble = true;
            if (event.stopPropagation) {
              event.stopPropagation();
            }
            if (event.preventDefault) {
              event.preventDefault();
            } else {
              event.returnValue = false;
            }
          }
        });
      }
    },2000)
  }

  addMarker(event: google.maps.MapMouseEvent, marker: MapMarker) {
    if (event != null) {
      let l = event.latLng?.toJSON();
      if (l != null) {
        this.markerPosition = l;
        this.cartForm.patchValue({ latitude: this.markerPosition.lat });
        this.cartForm.patchValue({ longitude: this.markerPosition.lng });
      }
    }
    this.openInfoWindow(marker);
    event.stop();
  }


  onChangeReportTypeChange(event: any) {
    this.selectedReportType = event.target.value;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i]["id"] == this.selectedReportType) {
        this.price = this.products[i]["price"];
      }
    }
  }

  private setLocationAndAddress() {
    if (this.searchState && (this.searchState['address'] || this.searchState['latLong'])) {
      if (this.searchState['latLong']) {
        this.setLatlong(this.searchState['latLong']);
      } else if (this.searchState['address']) {
        this.setAddressSearch(this.searchState['address'])
      }
      this.storeService.removeItemFromLocalSt(SEARCHED_STATE);
    } else {
      this.route.queryParams.subscribe((params: any) => {
        this.address = params["address"];
        let latlngpar = params["latlng"];
        const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
        if (regexExp.test(latlngpar)) {
          const latlng = latlngpar.split(",");
          this.markerPosition = { lat: Number(latlng[0].trim()), lng: Number(latlng[1].trim()) };
          this.center = this.markerPosition;
          this.cartForm.patchValue({ latitude: this.markerPosition.lat });
          this.cartForm.patchValue({ longitude: this.markerPosition.lng });
        } else if (this.address) {
          this.setAddressSearch(this.address);
        }
      });
    }

  }

  private getProductsAndOptions() {
    this.productService.getProductsAndOptions().subscribe({
      next: (data: any) => {
        if (data['status'] == true) {
          // this.products = data['data']['products'].reverse();
          this.products = data['data']['products']
          let reportTypeSetStatus = false

          for (let i = 0; i < this.products.length; i++) {
            if (this.products[i]["name"].toUpperCase().indexOf(this.reportTypeState.toUpperCase().trim()) != -1) {
              this.cartForm.controls['reportType'].setValue(this.products[i].id)
              
              this.parseProductAddons(this.products[i].addons);
              reportTypeSetStatus = true;
              if(this.products[i]['id']==51 || this.products[i]['name'].toLowerCase().includes("blueprint"))
              {
                this.showIncludeSecondaryStructure=false
              }else{
                this.showIncludeSecondaryStructure=true
              }
              break;
            }

          }


          if (!reportTypeSetStatus) {
            this.cartForm.controls['reportType'].setValue(this.selectedReportType)
          }


          for (let i = 0; i < this.products.length; i++) {
            if (this.products[i]["id"] == this.selectedReportType) {

              this.price = this.products[i]["price"];
            }

          }
          this.productOptions = data['data']['options'];
          for (let i = 0; i < this.productOptions.length; i++) {
            if (this.productOptions[i]["name"] == "Pitch") {
              this.pitchValues = this.productOptions[i]["values"];
            }
            if (this.productOptions[i]["name"] == "Expected Delivery Within") {
              this.expectedDeliveryWithinValues = this.productOptions[i]["values"];
            }
          }
        }
      }, error: err => {
        // err.error.message;
        console.log(err);
      }
    });
  }

  private updateCart(index: string, itemId?: any) {
    this.isLoading = true;
    this.cartService.getCartFromServer().subscribe((res: any) => {
      if (res && res.data && res.data.cartItems && res.data.cartItems.length > 0) {
        this.isLoading = false;
        let cartToEdit = res.data.cartItems.find((item: any) => item.cartItemId == itemId);
        if (cartToEdit) {
          this.cartItemId = cartToEdit.cartItemId;
          let cartItemUI=new CartItemUI(cartToEdit)
          this.cartForm.patchValue(cartItemUI);
          const checkAddons:FormArray= this.cartForm.get('addedAddons') as FormArray
          this.onProductTypeChange(cartToEdit.productId)
          if(cartItemUI.addedAddons==null)
          {
            cartItemUI.addedAddons=[]
          }
          for (let i = 0; i < cartItemUI.addedAddons.length; i++) {
            checkAddons.push(new FormControl(cartItemUI.addedAddons[i]))
            for(let productAddon of this.productAddons)
            {
              if(productAddon.value==cartItemUI.addedAddons[i])
              {
                productAddon.selected=true
              }
            }
          }
          // 
          this.markerPosition = { lat: Number(this.cartForm.controls["latitude"].value), lng: Number(this.cartForm.controls["longitude"].value) };
          this.center = this.markerPosition;
          this.confirmLocationSelection()
        }
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.cartForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.cartForm.invalid) {
      return;
    }
    this.loading = true;
    let cartData = this.cartForm.value;
    if (this.indexID > -1) {
      this.cartService.updateItemOnIndex({ ...this.cartForm.value, cartItemId: this.cartItemId }, this.indexID, true, ()=>{
        this.cartService.refreshCount();
        this.router.navigate(['/cart']);  
      });
      return
    } else {
      //this.cartService.addItem();
      this.cartService.addCartItem(new CartItemRequest(cartData as CartItemUI)).subscribe({
        next: () => {
          this.loading = false;
          this.cartService.refreshCount();
          this.router.navigate(['/cart']);
        },
        error: (e) => {
          this.loading = false;
          this.router.navigate(['/cart']);
        },
        complete: () => { this.loading = false; },

      });

    }



  }

  public AddressChange(address: any, marker: MapMarker) {
    this.formattedaddress = address.formatted_address;
    this.cartForm.patchValue({ address: address.formatted_address });
    this.address = address.formatted_address;
    this.onAddressSearch(marker)
  }
  public setAddressSearch(address: any) {
    this.address = address
    this.cartForm.patchValue({ address: address })
    this.geocoder.geocode({
      address: address
    }).subscribe((data: any) => {
      if (data?.results.length > 0) {
        let results = data?.results;
        this.markerPosition = results[0].geometry.location.toJSON();
        this.center = this.markerPosition;
        this.cartForm.patchValue({ latitude: this.markerPosition.lat });
        this.cartForm.patchValue({ longitude: this.markerPosition.lng });
        this.setMarker();
      } else {
        this.showInvalidAddressAlert();
      }
    });
  }

  async setLatlong(latlong: string) {
    var latlng = this.utill.getLatlongFromString(latlong);
    this.cartForm.patchValue({ latitude: latlng.lat });
    this.cartForm.patchValue({ longitude: latlng.lng });
    this.geocoder.geocode({ location: latlng }).subscribe((data: any) => {
      if (data?.results.length > 0) {
        this.address = data?.results[0].formatted_address;
        this.cartForm.controls['address'].setValue(data?.results[0].formatted_address);
        this.markerPosition = { lat: latlng.lat, lng: latlng.lng };
        this.center = this.markerPosition;
        this.setMarker();
      } else {
        this.showInvalidAddressAlert();
      }
    })
  }

  showInvalidAddressAlert() {
    this.alertService.showAlert({
      message: 'Entered Address/Latlong is invalid, Please enter a valid address',
      type: TYPE.info,
      mode: 'fixed-header',
    });
  }
  setMarker() {
    setTimeout(() => {
      this.infoWindow?.open(this.mapMarker);
    });
    return;
  }
  relocateMap() {
    this.center = { lat: 0, lng: 0 };
    setTimeout(() => {
      this.center = this.markerPosition;
    })
  }
  public onAddressSearch(marker: MapMarker) {
    this.geocoder.geocode({
      address: this.cartForm.controls["address"].value
    }).subscribe((data: any) => {
      if (data?.results.length > 0) {
        let results = data?.results;
        this.markerPosition = results[0].geometry.location.toJSON();
        this.center = this.markerPosition;
        this.cartForm.patchValue({ latitude: this.markerPosition.lat });
        this.cartForm.patchValue({ longitude: this.markerPosition.lng });
        this.openInfoWindow(marker)
      }
    });
  }

  openInfoWindow(marker: MapMarker, draggedEvent?: google.maps.MapMouseEvent) {
    if (draggedEvent) {
      if (draggedEvent != null) {
        let l = draggedEvent.latLng?.toJSON();
        if (l != null) {
          this.markerPosition = l;
          this.cartForm.patchValue({ latitude: this.markerPosition.lat });
          this.cartForm.patchValue({ longitude: this.markerPosition.lng });
        }
      }
    }
    if (!this.address) {
      this.setLatlong(this.markerPosition.lat + ',' + this.markerPosition.lng);
    }

    this.infoWindow?.open(marker, this.markerPosition);
  }

  closeInfowindow() {
    this.infoWindow?.close()
  }

  confirmLocationSelection(element?: HTMLElement) {
    if (this.cartForm.valid) {
      this.isExpanded = !this.isExpanded;
      this.closeInfowindow();
      //  let el = document.getElementById('EXPANED_ITEM');
      //   el && el.scrollIntoView({behavior: 'smooth'});
      setTimeout(() => {
        this.expanedItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100)
    } else {
      this.cartForm.markAsTouched();
      this.cartForm.controls['address'].markAllAsTouched();
    }
  }

  editLocation(marker: MapMarker) {
    this.isExpanded = !this.isExpanded;
    this.openInfoWindow(marker);
    setTimeout(() => {
      this.collapsedItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100)
  }

  onOrderEnterClicked(event?: any) {
    if (this.address) {
      this.cartForm.controls["address"].setValue(this.address);
      this.setAddressSearch(this.cartForm.controls["address"].value);
    }
    if(this.isMobileView){
      setTimeout(() => {
        this.collapsedItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100)
    }
    return false;
  }
  
  
  private parseProductAddons(productAddons:any) {
    // let i=1
    
    if(productAddons!=null){
      this.productAddons=[]
    productAddons.forEach((productAddon:any) =>{
      let pA={
        'value':productAddon.name.split("(")[0].toLowerCase()+"_"+productAddon.product_id+"_"+productAddon.addon_id,
        'name' :productAddon.name,
        'selected':false,
        'price':productAddon.price
       
      }
      this.productAddons.push(pA)
    }); 
  } else{
    this.productAddons=null
  }

  
  }
  get addonsArray() : FormArray {
    return this.cartForm.get('addons') as FormArray
  }
  onProductTypeChange(productId:any)
  {
    let product = this.products.filter((item:any) => {
      const found = ['id'].every((field, index) => { 
          return item[field] && item['id'] === productId
      })
      return found
    });  
  if(product[0]['id']==51 || product[0]['name'].toLowerCase().includes("blueprint"))
  {
    this.showIncludeSecondaryStructure=false
  }else{
    this.showIncludeSecondaryStructure=true
  }
  this.parseProductAddons(product[0]['addons']);

  const checkAddons:FormArray= this.cartForm.get('addedAddons') as FormArray
  for (let i = checkAddons.length; i >0 ; i--) {
    checkAddons.removeAt(i-1)
  }   
  }

  onAddonChange(e:any)
  {
    const checkAddons:FormArray= this.cartForm.get('addedAddons') as FormArray
    if(e.target.checked)
    {
      checkAddons.push(new FormControl(e.target.value))
    }else{
      var i=0;
      checkAddons.controls.forEach((item:any)=>{
        if(item.value==e.target.value)
        {
          checkAddons.removeAt(i);
          return;
        }
        i++;
      })
      
    }
  }
}
