export * from './authentication.service';
export * from './forgot-password.service';
export * from './user.service';
export * from './storage.service';
export * from './sync.service';
export * from './confirmation-popup.service';
export * from './order.service';
export * from './profile.service';
export * from './alert.service'

export const USER_AUTH = 'auth-user';
export const CART = 'cart';
export const USER = 'user';
export const SEARCHED_STATE = 'searched_state'
export const REMEMBER = 'remeberEmailAddress'

export const APP_ICONS = {
    CART: 'fa-solid fa-bag-shopping me-2',
    CART_CHECK: 'bi bi-bag-check me-2',
    MY_PROFILE: 'bi bi-person fa-fw me-2',
    USER: 'bi bi-person fa-fw me-2',
    ORDER_NOW: 'fa-solid fa-hotel me-2',
    MY_ORDERS: 'bi bi-bookmark-check fa-fw me-2',
    NOTIFICATION_BELL: 'bi bi-bell fa-fw',
    HELP_CENTER: 'bi bi-info-circle fa-fw me-2',
    SIGN_OUT: 'bi bi-power fa-fw me-2',
    MAP_ADDRESS: 'bi bi-map',
    LAT_LONG: 'bi bi-geo-alt',
    LOGIN_SIGNUP: 'fa-solid fa-right-to-bracket me-2', //fa-solid fa-globe-americas me-2
    CHECKOUT: 'fa-solid fa-cart-shopping',
    BILLING: 'fa-solid fa-coins',
    BILLING_COMPEITED: 'bi bi-patch-check fa-fw me-1',

    path: {
        AVATAR: 'https://ik.imagekit.io/krlinc/assets/images/avatar/12.jpg'
    }

};

export const MESSAGE_TYPE = {
    danger: 'danger',
    success: 'success',
    info: 'info',
    warning: 'warning'
}

export const CONFIG_MESSAGES = {
    cart: {
        cart_empty: 'Your cart is empty!',
        cart_empty_submessage: 'Please go to order-now to place a new order.',
        delete_title: 'Confirmation to delete this cart item',
        delete_message: 'Are you sure you want to delete selected cart Item?'
    },
    my_order: {
        no_data_found: 'Oops! No data found',
        no_data_found_submessage: 'Please try update/remove the applied filters.'
    }
}

export const PAYMENT_STATUS = { SUCCESS: 'success', FAILED: 'failed', ERROR: 'cancel' }
export const  STATUS = { PENDING: 'Pending', COMPLETED: 'Completed', CANCELED: 'Canceled', SUSPENDED: 'Suspended', PROCESSING: 'Processing',REFUNDED: 'Refunded',PAYMENT_PENDING: 'Payment Pending' }
export const TABS = {
    CART: 'cart',
    CHECKOUT: 'checkout',
    PAYMENT_STATUS: 'payment_status',
    MY_ORDERS: 'my_orders',
    MY_PROFILE: 'my-profile',
    USER: 'user',
    ORDER_NOW: 'order-now',

}

export const TAGS = {
    Primary: '<span class="badge text-bg-primary">$type</span>',
    Secondary: '<span class="badge text-bg-secondary">$type</span>',
    Success: '<span class="badge text-bg-success">$type</span>',
    Danger: '<span class="badge text-bg-danger">$type</span>',
    Warning: '<span class="badge text-bg-warning">$type</span>',
    Info: '<span class="badge text-bg-info">$type</span>',
    Light: '<span class="badge text-bg-light">$type</span>',
    Dark: '<span class="badge text-bg-dark">$type</span>',
}

export const PAYMENT_TAGS = {
    Success: '<span class="badge text-bg-success" >Payment $type</span>',
    Danger: '<span class="badge text-bg-danger">Payment $type</span>',
}
export const COLOR_CLASS = {
    PENDING : 'text-primary',
    PROCESSING: 'text-info',
    COMPLETED: 'text-success',
    CANCELED: 'text-danger',
    REFUNDED: 'text-dark',
    PAYMENT_PENDING: 'text-danger',
}

export const FEATURE = {
    NOTIFICATION: false
}