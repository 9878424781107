<!-- =======================
Main banner START -->
<section class="pt-2 pt-md-2" #contactus id="contactus">
	<div class="container">
		<div class="row mb-4">
			<div class="col-12 text-center">
				<h1 class="mb-0">Let's connect</h1>
			</div>
		</div>
		<div class="row g-4 flex d-flex justify-content-center">
			<div class="col-md-6 col-xl-4">
				<div class="card card-body shadow text-center align-items-center h-100">
					
					<div class="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2"><i class="bi bi-inboxes-fill fs-5"></i></div>
					
					<h5>Email us</h5>
					<a href="mailto:corporate@krlinc.com" class="btn btn-link text-decoration-underline p-0 mb-0"><i class="bi bi-envelope me-1"></i>corporate@krlinc.com</a>
				</div>
			</div>
			<div class="col-md-6 col-xl-4">
				<!-- Svg decoration -->
				<figure class="position-absolute top-0 end-0 z-index-1 mt-n4 ms-n7">
					<svg class="fill-warning" width="77px" height="77px">
						<path d="M76.997,41.258 L45.173,41.258 L67.676,63.760 L63.763,67.673 L41.261,45.171 L41.261,76.994 L35.728,76.994 L35.728,45.171 L13.226,67.673 L9.313,63.760 L31.816,41.258 L-0.007,41.258 L-0.007,35.725 L31.816,35.725 L9.313,13.223 L13.226,9.311 L35.728,31.813 L35.728,-0.010 L41.261,-0.010 L41.261,31.813 L63.763,9.311 L67.676,13.223 L45.174,35.725 L76.997,35.725 L76.997,41.258 Z"></path>
					</svg>
				</figure>

				<div class="card card-body shadow text-center align-items-center h-100">
					<!-- Icon -->
					<div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2"><i class="bi bi-globe2 fs-5"></i></div>
					<!-- Title -->
					<h5>Social media</h5>
					<!-- Buttons -->
					<ul class="list-inline mb-0">
						<li class="list-inline-item"> <a class="btn btn-sm bg-facebook px-2 mb-0" href="#"><i class="fab fa-fw fa-facebook-f"></i></a> </li>
						<li class="list-inline-item"> <a class="btn btn-sm bg-instagram px-2 mb-0" href="#"><i class="fab fa-fw fa-instagram"></i></a> </li>
						<li class="list-inline-item"> <a class="btn btn-sm bg-twitter px-2 mb-0" href="#"><i class="fab fa-fw fa-twitter"></i></a> </li>
						<li class="list-inline-item"> <a class="btn btn-sm bg-linkedin px-2 mb-0" href="#"><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- =======================
Contact form and vector START -->
<section class="pt-0 pt-lg-5" >
	<div class="container">
		<div class="row g-3 g-lg-4 align-items-center">
			<!-- Vector image START -->
			<div class="col-lg-6 text-center">
				<img src="https://ik.imagekit.io/krlinc/assets/images/element/contact.png" alt="Contact Us 1">
			</div>
			<!-- Vector image END -->

			<!-- Contact form START -->
			<div class="col-lg-6">
				<div class="card bg-light p-4">
					<!-- Svg decoration -->
					<figure class="position-absolute end-0 bottom-0 mb-n4 me-n2">
						<svg class="fill-orange" width="104.2px" height="95.2px">
							<circle cx="2.6" cy="92.6" r="2.6"/>
							<circle cx="2.6" cy="77.6" r="2.6"/>
							<circle cx="2.6" cy="62.6" r="2.6"/>
							<circle cx="2.6" cy="47.6" r="2.6"/>
							<circle cx="2.6" cy="32.6" r="2.6"/>
							<circle cx="2.6" cy="17.6" r="2.6"/>
							<circle cx="2.6" cy="2.6" r="2.6"/>
							<circle cx="22.4" cy="92.6" r="2.6"/>
							<circle cx="22.4" cy="77.6" r="2.6"/>
							<circle cx="22.4" cy="62.6" r="2.6"/>
							<circle cx="22.4" cy="47.6" r="2.6"/>
							<circle cx="22.4" cy="32.6" r="2.6"/>
							<circle cx="22.4" cy="17.6" r="2.6"/>
							<circle cx="22.4" cy="2.6" r="2.6"/>
							<circle cx="42.2" cy="92.6" r="2.6"/>
							<circle cx="42.2" cy="77.6" r="2.6"/>
							<circle cx="42.2" cy="62.6" r="2.6"/>
							<circle cx="42.2" cy="47.6" r="2.6"/>
							<circle cx="42.2" cy="32.6" r="2.6"/>
							<circle cx="42.2" cy="17.6" r="2.6"/>
							<circle cx="42.2" cy="2.6" r="2.6"/>
							<circle cx="62" cy="92.6" r="2.6"/>
							<circle cx="62" cy="77.6" r="2.6"/>
							<circle cx="62" cy="62.6" r="2.6"/>
							<circle cx="62" cy="47.6" r="2.6"/>
							<circle cx="62" cy="32.6" r="2.6"/>
							<circle cx="62" cy="17.6" r="2.6"/>
							<circle cx="62" cy="2.6" r="2.6"/>
							<circle cx="81.8" cy="92.6" r="2.6"/>
							<circle cx="81.8" cy="77.6" r="2.6"/>
							<circle cx="81.8" cy="62.6" r="2.6"/>
							<circle cx="81.8" cy="47.6" r="2.6"/>
							<circle cx="81.8" cy="32.6" r="2.6"/>
							<circle cx="81.8" cy="17.6" r="2.6"/>
							<circle cx="81.8" cy="2.6" r="2.6"/>
							<circle cx="101.7" cy="92.6" r="2.6"/>
							<circle cx="101.7" cy="77.6" r="2.6"/>
							<circle cx="101.7" cy="62.6" r="2.6"/>
							<circle cx="101.7" cy="47.6" r="2.6"/>
							<circle cx="101.7" cy="32.6" r="2.6"/>
							<circle cx="101.7" cy="17.6" r="2.6"/>
							<circle cx="101.7" cy="2.6" r="2.6"/>
						</svg>
					</figure>

					<!-- Card header -->
					<div class="card-header bg-light p-0 pb-3">
						<h3 class="mb-0">Message Us</h3>
					</div>

					<!-- Card body START -->
					<div class="card-body p-0">
						<form class="row g-2" [formGroup]="enquiryForm">
							<!-- Name -->
							<div class="col-md-6">
								<label class="form-label">Your name *</label>
								<input type="text" class="form-control" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" >
							</div>
							<!-- Email -->
							<div class="col-md-6">
								<label class="form-label">Email address *</label>
								<input type="email" class="form-control" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" >
								<div class="font-italic" *ngIf="f.email.touched && f.email.errors&& f.email.errors?.['pattern']">
									<small class="text-danger font-weight-700">
										  Please enter valid email address.
									</small>
								  </div>
							</div>
							<!-- Mobile number -->
							<div class="col-12">
								<label class="form-label">Subject *</label>
								<input type="text" class="form-control" class="form-control" formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" >
							</div>
							<!-- Message -->
							<div class="col-12">
								<label class="form-label">Message *</label>
								<textarea class="form-control" rows="3" class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
							</div>
							
							<!-- Checkbox -->
							<div class="col-12 form-check ms-2">
								<input type="checkbox" class="form-check-input" id="iAgree" formControlName="iAgree">
								<label class="form-check-label" for="exampleCheck1">
									By submitting this form you agree to our terms and conditions.
								</label>
							</div>
							<ul class="list-unstyled" *ngIf="error">
								<li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> {{error}}</li>
							</ul>
							<ul class="list-unstyled" *ngIf="success">
								<li class="mb-1"><i class="bi bi-check-circle text-success me-2"></i> {{success}}</li>
							</ul>
							<ul class="list-unstyled" *ngIf="warning">
								<li class="mb-1"><i class="bi bi-exclamation-circle text-warning me-2"></i> {{warning}}</li>
							</ul>
							<!-- Button -->
							<div class="col-12">
								<button class="btn btn-primary mb-0" type="submit" (click)="onSubmitEnquiry()" [disabled]="loading" [disabled]="!enquiryForm.valid  || !enquiryForm.value.iAgree || loading">
									
									<span *ngIf="!loading">Send Message</span>
											<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>

								</button>
							</div>	
						</form>
					</div>
					<!-- Card body END -->
				</div>
			</div>
			<!-- Contact form END -->
		</div>
	</div>
</section>
<!-- =======================
Contact form and vector END -->
