import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle("Terms and Conditions - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "" })
  }
}
