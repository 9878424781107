<!-- =======================
Privacy Policy content START -->
<section class="pt-0 pt-lg-5">
    <div class="container">
        <!-- Title -->
        <div class="row mb-3 mb-sm-4">
            <div class="col-12 text-center">
                <h1>Privacy Policy</h1>
            </div>
        </div>
        <!-- Content START -->
        <div class="row mb-4 mb-md-5">
            <div class="col-md-10 mx-auto">
                <p class="fw-bold">At <strong class="text-primary">KRL INC</strong> , we take the privacy of our customers and website visitors very seriously.
                    This Privacy Policy
                    explains what personal information we collect from you, why we collect it, and how we use it.</p>
                <h5 class="mt-4">Information Collection and Use</h5>
                <p class="mb-10 text-justify">We collect personal information from you when you register for an account,
                    place an order, subscribe to
                    our newsletter, or complete a form on our website. The personal information we collect may include
                    your name, company name, email address, phone number, address, etc.</p>

                <p class="mb-10 text-justify">We may also collect information related to your use of our services, such
                    as the type of roof reporting
                    services you are interested in, and any other information you provide to us in the course of using
                    our
                    services.</p>

                <p class="mb-10 text-justify">We use this information to provide you with the services you request, to
                    communicate with you about
                    your order or account, and to send you promotional emails or other communications that may be of
                    interest to you. We may also use this information to improve our website and services.</p>


                <h5 class="mt-4">Information Sharing and Disclosure</h5>
                <p class="mb-10 text-justify">We will not share or disclose your personal information to any third party
                    ever without your consent,
                    except as required by law or as necessary to provide you with the services you request.</p>

                <p class="mb-10 text-justify">We may share your personal information with third-party service providers
                    who perform services on our
                    behalf, such as payment processors and email service providers. These third parties are
                    contractually
                    obligated to keep your personal information confidential and use it only for the purpose of
                    providing
                    services to us.</p>


                <h5 class="mt-4">Cookies and Other Tracking Technologies</h5>
                <p class="mb-10 text-justify">We use cookies and other tracking technologies to improve your experience
                    on our website and to
                    analyze website traffic. Cookies are small text files that are placed on your computer or mobile
                    device
                    when you visit our website. They allow us to recognize your device and store some information about
                    your preferences or past actions.</p>

                <p class="mb-10 text-justify">You can configure your browser to block cookies, or to alert you when
                    cookies are being sent. However,
                    some features of our website may not function properly if cookies are disabled.</p>


                <h5 class="mt-4">Your Rights and Choices</h5>
                <p class="mb-10 text-justify">You have the right to access, correct, or delete your personal information
                    that we have collected. You
                    can do this by logging into your account, or by contacting us at corporate@krlinc.com</p>

                <p class="mb-10 text-justify">You may also opt-out of receiving promotional emails from us by clicking
                    the unsubscribe link in any
                    email you receive, or by contacting us at the above contact information.</p>


                <h5 class="mt-4">Changes to This Privacy Policy</h5>
                <p class="mb-10 text-justify">We may update this Privacy Policy from time to time. If we make any
                    material changes to this policy, we
                    will notify you by posting the updated policy on our website, or by sending you an email or other
                    notification.</p>
                <h5 class="mt-4">Contact Us</h5>
                <p class="mb-10 text-justify">If you have any questions or concerns about this Privacy Policy, please
                   <a [routerLink]="['/contact-us']" >contact us</a>   or email us at <a href="mailto:corporate@krlinc.com">corporate@krlinc.com</a>
                    </p>

                <p class="mb-10 text-justify">This Privacy Policy is effective as of 28<sup>th</sup> August 2016 and was last
                    updated on 1<sup>st</sup>  January 2023.</p>

            </div>
        </div>
        <!-- Content END -->


    </div>
</section>
<!-- =======================
Privacy Policy content END -->