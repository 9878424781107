import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '@app/_services';
import { TYPE } from '@app/alert/alert.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private alertService: AlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.authenticationService.logout();
            }
            const error = err.error.message || err.statusText;
            let message = '';
            let type = TYPE.danger;
            // Common error handler
            if (err.error?.errorObject?.error && err.error?.errorObject?.error.length > 0) {
                message = err.error?.errorObject?.error[0];
                type = TYPE.danger;
            }
            if (err.error.message) {
                message = err.error.message;
                type = TYPE.danger;
            }
            if (err.error.data && err.error.data.emailNotVerified) {
                message = err.error.message;
                type = TYPE.warning;
            }
            if (err.error.data && err.error.data.emailOrUsername) {
                message = err.error.message;
                type = TYPE.warning;
            }
            if (err.error.data && err.error.data.userAccountLocked) {
                message = err.error.message;
                type = TYPE.warning;
            }
            if (err.error.data && err.error.data.userNotFound) {
                message = err.error.message;
                type = TYPE.warning;
            }
            if (message != '') {
                this.alertService.showAlert({
                    message: message,
                    type: type,
                    mode: 'fixed-header',
                });
            }

            return throwError({ message: error, extras: err.error });
        }))
    }
}