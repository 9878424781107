<section class="pt-0">
    
    <div class="">
        <div class="row">
            <div class="col-lg-12  d-flex flex  flex-column" *ngIf="threeDFileExist==false">

                <google-map #map [options]="options" [center]="center" class="map-container container  rounded-3" height="90%" width="100%">
                    <map-marker  #marker="mapMarker" [position]="markerPosition" [options]="markerOptions"  ></map-marker>

                </google-map>

            </div>
            <div class="col-lg-6  d-flex flex  flex-column" *ngIf="threeDFileExist==true">

                <google-map #map [options]="options" [center]="center" class="map-container container  rounded-3" height="90%" width="100%">
                    <map-marker  #marker="mapMarker" [position]="markerPosition" [options]="markerOptions"  ></map-marker>

                </google-map>

            </div>
            <div class="col-lg-6  d-flex flex  flex-column three-d-view" *ngIf="threeDFileExist==true">

               <embed [src]="threeDFileUrl | safeUrl"  style="width: 100%; height: 100%;"/>

            </div>
        </div>
    </div>
</section>