import { Component } from '@angular/core';
import { ConfirmationPopupService } from '@app/_services';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
  title?: string;
  message?: string;
  type?: string;

  constructor(private confirmService: ConfirmationPopupService) {
    this.confirmService.triggerPopup$.subscribe((data:any) => {
      this.title = this.confirmService.title;
      this.message = this.confirmService.message;
      this.type = this.confirmService.type;
    });
  }


  onYes(){
    this.confirmService.selectYes(true);
  }

  onCancel(){
    this.confirmService.cancelPopup()
  }
}
