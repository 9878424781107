<!-- =======================
Main banner START -->
<section>
    <div class="container">
        <div class="row mb-5">
            <div class="col-xl-10 mx-auto text-center">
                <!-- Title -->
                <h2>We would be delighted to provide our services to help enhance your productivity.</h2>
                <p class="lead">At <strong class="text-primary">KRL INC</strong>, we take pride in our commitment to customer satisfaction. We are dedicated
                    to providing
                    our customers with the information they need to make informed decisions about their roofing needs.
                </p>
                <!-- Meta -->
                <div class="hstack gap-3 flex-wrap justify-content-center">
                    <!-- Item -->
                    <h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
                        <img src="https://ik.imagekit.io/krlinc/assets/images/element/06.svg" class="h-20px me-2" alt="50K+ Reports Processed">
                        50K+ Reports Processed
                    </h6>

                    <!-- Item -->
                    <h6 class="bg-mode shadow rounded-2 fw-normal d-inline-block py-2 px-4">
                        <img src="https://ik.imagekit.io/krlinc/assets/images/element/07.svg" class="h-20px me-2" alt="1K+ Happy Customers">
                        1K+ Happy Customers
                    </h6>

                  
                </div>
            </div>
        </div> <!-- Row END -->

        <!-- Image START -->
        <div class="row g-4 align-items-center">
            <!-- Image -->
            <div class="col-md-6">
                <img src="https://ik.imagekit.io/krlinc/assets/images/about/about1.png" class="rounded-3" alt="About US">
            </div>

            <div class="col-md-6">
                <div class="row g-4">
                    <!-- Image -->
                    <div class="col-md-8">
                        <img src="https://ik.imagekit.io/krlinc/assets/images/about/about3.png" class="rounded-3" alt="About US">
                    </div>

                    <!-- Image -->
                    <div class="col-12">
                        <img src="https://ik.imagekit.io/krlinc/assets/images/about/about2.png" class="rounded-3" alt="About US">
                    </div>
                </div>
            </div>
        </div>
        <!-- Image END -->
    </div>
</section>
<!-- =======================
Main banner START -->

<!-- =======================
About content START -->
<section class="pt-0 pt-lg-5">
    <div class="container">
        <!-- Content START -->
        <div class="row mb-4 mb-md-5">
            <div class="col-md-10 mx-auto">
                <h3 class="mb-4">Our Story</h3>
                <p class="fw-bold"><strong class="text-primary">KRL INC</strong> is a leading provider of satellite measured Roof reporting services. We have
                    been in the
                    business for 7 years and have built a reputation for providing accurate and reliable roof reports at
                    an
                    affordable cost.</p>
                <p class="mb-10 text-justify">Our team of experienced and certified professionals is dedicated to providing our
                    customers with the
                    highest level of service. We use the latest technology and equipment to ensure that our drafted roof
                    reports are comprehensive and accurate.</p>

                <p class="mb-10 text-justify">We provide the best and most accurate aerial Roof-Measurement reports. Our CAD service
                    uses aerial /
                    satellite imagery from different independent sources, to produce these reports. You can even provide
                    your field measurements, architectural PDFs/drawings, site/property photographs to produce the
                    custom roof reports as per your requirement.</p>

                <p class="mb-10 text-justify">Our services are designed to help property owners, real estate agents, and insurance
                    companies assess
                    the measurements of their roofs and make informed decisions about repairs, maintenance, and
                    insurance claims without climbing the ladder.</p>

                <p class="mb-10 text-justify">If you&#39;re looking for a professional, reliable, and affordable roof reporting
                    service, look no further than
                    <strong class="text-primary">KRL INC</strong> Contact us today to see for yourself why we are the trusted choice for all roof
                    inspections,
                    claim adjusters, etc.</p>
            </div>
        </div>
        <!-- Content END -->

       
    </div>
</section>
<!-- =======================
About content END -->