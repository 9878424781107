<!-- Update Password START -->
<div class=""  [ngClass]="{'card border': !token}">
    <!-- Card header -->
    <div class="card-header border-bottom" *ngIf=" !token">
        <h4 class="card-header-title">Update Password</h4>
    </div>

    <!-- Card body START -->

    <form class="card-body row g-2" role="form" [formGroup]="updatePasswordForm">

        <!-- Current password -->
        <div class="mb-3" [ngClass]="{'col-md-4': !token, 'd-none': token}">
            <label class="form-label">Current password</label>
            <input type="password" class="form-control form-control-alternative" formControlName="currentPassword"
                placeholder="Enter current password">
        </div>
        <!-- New password -->
        <div class="mb-3 " [ngClass]="{'col-md-4': !token, 'col-md-12': token}">
           
            <label class="form-label"> Enter new password 
                <span class="alert-reason">
                    <a tooltip="Password must contain At least 6 characters, at least 1 upper and lower case letter, Numbers, special characters" [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')"
                         class="fs-6 "  >
                        <i class="bi bi-info-circle"></i>
                    </a>
                </span>
            </label>
            
            <div class="input-group">

                <input type="password" id="newPassword" placeholder="Enter new password"
                    class="form-control form-control-alternative fakepassword" formControlName="newPassword" type="{{isviewed?'text':'password'}}">

                <span class="input-group-text p-0 bg-transparent">
                    <i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" (click)="isviewed = !isviewed"></i>
                </span>

            </div>
            <div>
                <small> <password-strength [passwordToCheck]="updatePasswordForm.value.newPassword"
                        (passwordStrength)="passwordValid($event)"></password-strength></small>
            </div>
        </div>
        <!-- Confirm -->
        <div class="" [ngClass]="{'col-md-4': !token, 'col-md-12': token}">
            <label class="form-label">Confirm new password</label>
            <input type="password" id="check" class="form-control form-control-alternative"
                formControlName="check" placeholder="Re-enter new password">
                <div class="font-italic">
                    <label class="form-check-label">Password:&nbsp;&nbsp;</label>
                    <small>
                        <span *ngIf="updatePasswordForm.errors && updatePasswordForm.errors['match_error']"> <span
                                class="text-danger font-weight-700">Not Matched</span></span>
                        <span *ngIf="!(updatePasswordForm.errors && updatePasswordForm.errors['match_error'])"> <span
                                class="text-success font-weight-700">matched</span></span>
                    </small>
                </div>
        </div>
        
        <div class="contianer">
            <div class="row  d-flex justify-content-end">
                <div class=" col text-end">
                    <ul class="list-unstyled" *ngIf="error">
                        <li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> {{error}}
                        </li>
                    </ul>
                    <ul class="list-unstyled" *ngIf="successMsg">
                        <li class="mb-1"><i class="bi bi-check-circle text-success me-2"></i>
                            {{successMsg}}</li>
                    </ul>
                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <div class="col text-end">
                    <button type="submit" class="btn btn-primary  mb-0"
                        [disabled]="!updatePasswordForm.valid || !passwordStrengthValid ||  loading"
                        (click)="onSubmitReset()">
                        <span *ngIf="!loading">Change Password</span>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                    </button>
                </div>
            </div>

        </div>
    </form>
    <!-- Card body END -->
</div>
<!-- Update Password END -->