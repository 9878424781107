import { Component } from '@angular/core';

@Component({
  selector: 'app-into-video',
  templateUrl: './app-into-video.component.html',
  styleUrls: ['./app-into-video.component.scss']
})
export class AppIntoVideoComponent {

}
