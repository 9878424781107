	<div *ngIf="mode === 'long'">
        <!-- Divider -->
		<hr class="mt-4 mb-0">

		<!-- Bottom footer -->
		<div class="row">
			<div class="container">
				<div class="d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start">
					<!-- copyright text -->
					<div class="text-muted text-primary-hover"> Copyrights ©2016-2024 <strong class="text-primary">KRL INC.</strong> All rights reserved. </div>
					
				</div>
			</div>
		</div>
    </div>

    <div *ngIf="mode === 'mini'">
        <p class="mb-0 mt-3 text-center">©2016-2024 <strong class="text-primary">KRL INC.</strong> All rights reserved</p>
    </div>
        