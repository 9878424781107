import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle("FAQ - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "Thousands of roofing contractors are using KRL INC. to speed up their sales process and bring fast, affordable measurements to their company. Are you ready to join them?" })
    this.meta.updateTag({ name: 'keywords', content: "Roof reports USA and Canada,Roof Reporting Services USA and Canada,Roof Reporting Companies USA and Canada" })

  }
}
