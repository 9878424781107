import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productsAndOptions:any;
  constructor(private http: HttpClient) {
    
   }

  public getProductsAndOptions(): Observable<any>
  {
    const BASE_URL = environment.apiUrl;
    return this.http.get(BASE_URL+"/product");
  }
 
}
