

<ng-container *ngIf="!isLoading">
  <app-browser-not-support *ngIf="!isBrowserSupport"></app-browser-not-support>
  <app-header *ngIf="showHeadFoot && isBrowserSupport"></app-header>
  <main *ngIf="showHeadFoot && isBrowserSupport">
    <app-alert class="d-flex justify-content-center align-items-center pb-2" [message]="alertMessage" [mode]="alertMode" [type]="alertType"></app-alert>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </main>
  <app-footer *ngIf="showHeadFoot && isBrowserSupport"></app-footer>
  <app-mobile-bottom-navigation *ngIf="isUserLoggedIn"></app-mobile-bottom-navigation>
</ng-container>
<app-confirmation-popup [ngClass]="{'d-none':showPopUp}"></app-confirmation-popup>
<app-loader class="loading-content" *ngIf="isLoading"></app-loader>