// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'https://devapi.miraklabs.com/api/v1/store',
     apiUrl: 'http://localhost:8080/api/v1/store',    
    // apiUrl: 'https://api.krlinc.com/api/v1/store',
    returnUrl: 'https://test.krlinc.com/checkout;success=true',
    cancelUrl: 'https://test.krlinc.com/checkout;failed=true',
    emailVerifyUrl: 'https://test.krlinc.com/verify-email',
    residential_pdfPath: 'assets/downloads/residential.pdf',
    commercial_pdfPath: 'assets/downloads/commercial.pdf',
    paypal_client_id: 'AXNr2VOsJl_TCz0aBUH_ceR3hxq2IXJSss-uriN0ZCeS3-_U-5QcMoV6D-CoPHxisqN-xanDBII9ytle', //sandbox
    // paypal_client_id: 'AeFuSXjdy2T2q-hI_0ly_MNYSY_Y93P0QuavVy_qI4Oy6slNM5b6W-dnbFsHu8VevjoG46YXFf0rSI7L' //prod
    three_d_file_base_url : 'https://krl-order-3d.s3.amazonaws.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
