import { Component } from '@angular/core';

@Component({
  selector: 'app-who-uses-our-reports',
  templateUrl: './who-uses-our-reports.component.html',
  styleUrls: ['./who-uses-our-reports.component.scss']
})
export class WhoUsesOurReportsComponent {

}
