import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Enquiry } from '@app/_models/enquiry.model';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  constructor(private http: HttpClient) { }

  public sendEnquiry(enquiry: Enquiry)
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(
      `${environment.apiUrl}/enquiry`,
      enquiry,
      httpOptions
    );
   
  }
}
