import { Component } from '@angular/core';

@Component({
  selector: 'app-client-testimonials',
  templateUrl: './client-testimonials.component.html',
  styleUrls: ['./client-testimonials.component.scss']
})
export class ClientTestimonialsComponent {

}
