<section class="pt-0 pt-lg-5 pb-8 pdt-unset">
        <app-loader *ngIf="isLoading"></app-loader>
        <div [ngClass]="{'expanded-view': isExpanded}" #COLLAPSED_VIEW *ngIf="!isLoading">
            <div class="col-lg-10 order-now-container d-flex flex justify-content-center flex-column align-item-center">
                <google-map #map class="map-container container  rounded-3" height="100%" width="100%"
                    [options]="options" [center]="center" (mapClick)="addMarker($event, marker);"
                    [ngClass]="{'map-not-allowed': isExpanded}" >
                    <map-marker #marker="mapMarker" [position]="markerPosition" [options]="markerOptions"
                        (click)="openInfoWindow(marker)" (mapDragend)="openInfoWindow(marker, $event)"></map-marker>
                    <map-info-window>
                        <div>
                            <div class="card bg-light rounded-2" style="width: 100%;">
                                <div class="border-bottom bg-light" >
                                    <h6 class="card-title mb-0" style="color: #198754;">Confirm Location?</h6>
                                </div>
                                <div class="" >
                                    <ul class="list-group list-group-borderless">
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <span class="h6 fw-normal mb-0"
                                                style="font-size: 11px;">{{address}}</span>
                                        </li>
                                        <li class="mb-0">
                                            <i class="bi bi-geo-alt"></i>
                                            &nbsp;LatLong: {{(cartForm.controls['latitude'].value | number )+", "+(cartForm.controls['longitude'].value | number)}}
                                        </li>
                                    </ul>
                                </div>
                                <div class=" border-top bg-light text-right pt-1" style="text-align: center;">
                                    <a class="btn btn-sm btn-primary-soft mb-0"style=" padding: 5px 30px 5px 30px;"
                                        (click)="confirmLocationSelection(EXPANED_ITEM)">Yes</a>
                                </div>
                            </div>
                        </div>
                    </map-info-window>
                </google-map>
                <div class="col-11  col-sm-10 col-lg-8 col-xl-6  address-container  ms-lg-8 ms-xl-7 mt-4  bg-mode rounded-3">
                    <div class="card shadow pb-0 mt-n7 mt-sm-n8 mt-lg-0">
                        <div class="bg-mode card-padding shadow p-2 rounded-1">
                            <form class="form-control-bg-transparent" name="form" (ngSubmit)="onSubmit()"
                                [formGroup]="cartForm">
                                <div class="row g-4 align-items-center" *ngIf="!isExpanded">
                                    <!-- Location -->
                                    <div class="col-lg-12">
                                        <div
                                            class="form-control-border form-control-transparent form-fs-md d-flex pt-1">
                                            <!-- Icon -->
                                            <i class="bi bi-house fs-3 me-2 mt-2"></i>
                                            <!-- Select input -->
                                            <div class="flex-grow-1">
                                                <label class="form-label">Search your Address</label>
                                                <div class="d-flex">
                                                    <input class="form-select js-choice pd-r-2"
                                                        data-search-enabled="true" type="text"
                                                        class="form-guest-selector-custome form-control selection-result"
                                                        ngx-google-places-autocomplete 
                                                        placeholder="Enter Property Address" 
                                                        (onAddressChange)="AddressChange($event, marker)" 
                                                        (keyup.enter)="onOrderEnterClicked()"
                                                        #AUTOCOMPLETE_INPUT
                                                        [(ngModel)]="address"
                                                        [ngModelOptions]="{standalone: true}">
                                                  
                                                    <i class="fa-solid fa-location-crosshairs relocate-marker default-color"
                                                        *ngIf="cartForm.controls['latitude'].value&& cartForm.controls['longitude'].value"
                                                        (click)="relocateMap()"></i>
                                                    <div (click)="onOrderEnterClicked()" class="btn-position-md-middle b-s-none rounded"  *ngIf="!isMobileView" >
                                                        <a class="icon-lg btn btn-round btn-primary mb-0">
                                                            <i class="bi bi-search fa-fw"></i>
                                                            <!-- <i class="fa-solid fa-check" *ngIf="(cartForm.controls['latitude'].value&& cartForm.controls['longitude'].value)"></i> -->
                                                        </a>
                                                    </div>
                                                    
                                                </div>
                                                <ul class="list-unstyled hint-error" style="margin-bottom: unset;" *ngIf="f['address'].touched && f['address'].errors">
                                                    <li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> Please enter valid property address.</li>
                                                </ul>
                                                <ul class="list-unstyled mb-0"
                                                    *ngIf="cartForm.controls['latitude'].value&& cartForm.controls['longitude'].value">
                                                    <li class="mb-0"><i class="bi bi-info-square default-color"></i>
                                                        &nbsp; Click on map and place/drag the marker to set most accurate
                                                        location.</li>
                                                </ul>

                                                <a class="btn btn-sm btn-dark mb-0 search-button-mobile"  (click)="onOrderEnterClicked()" *ngIf="isMobileView" >
                                                    <i class="bi bi-search fa-fw"></i> &nbsp; Search
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="collapse" id="collapseExample" [ngClass]="{'show': isExpanded}">
                                    <span class="close-big-popup default-color" (click)="editLocation(marker)"><i class="bi bi-x-lg"></i></span>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-12 col-lg-12">
                                            <label class="h6 fw-normal mb-0">Address</label>
                                            <div class="form-border-bottom form-control-transparent mt-3">
                                                <input type="text" class="form-control bg-light padress-expaned-input"
                                                    placeholder="Lattitude" formControlName="address"
                                                    [readonly]="!isLatLongChecked">
                                                <i class="pencil-edit-location default-color" (click)="editLocation(marker)"> Edit Address </i>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-6 col-lg-6">
                                            <label class="h6 fw-normal mb-0">Latitude</label>
                                            <div class="form-border-bottom form-control-transparent mt-3">
                                                <input type="text" class="form-control bg-light" placeholder="Lattitude"
                                                    formControlName="latitude" [readonly]="!isLatLongChecked">
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-6">
                                            <label class="h6 fw-normal mb-0">Longitude</label>
                                            <div class="form-border-bottom form-control-transparent mt-3">
                                                <input type="text" class="form-control bg-light" placeholder="Longitude"
                                                    formControlName="longitude" [readonly]="!isLatLongChecked">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-12 col-lg-12" #EXPANED_ITEM id="EXPANED_ITEM">
                                            <div class="">
                                                <label class="h6 fw-normal mb-0">Report Type</label>
                                                <div class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="display: flex;
                                                flex-direction: column;">
                                                    <ng-container *ngFor="let p of products; let i = index;">
                                                        <div class="form-check form-check-inline active"
                                                            id="cab2-one-way-tab">
                                                            <input class="form-check-input" type="radio"
                                                                [id]="'type_'+p.id" [value]="p.id"
                                                                formControlName="reportType" [readOnly]="false" (change)="onProductTypeChange(p.id)">
                                                            <label class="form-check-label"
                                                                [for]="'type_'+p.id">{{p.name.split(' ')[0]}}&nbsp;<strong>${{p.price}}</strong></label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-1 mt-1" *ngIf="productAddons!== undefined && productAddons !== null">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="" >
                                                <label class="h6 fw-normal mb-0">Addons</label>
                                                <ng-container   *ngFor="let pa of  productAddons; let i = index;" >
                                                 
                                                    <div class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                        <input class="form-check-input" type="checkbox"  [value]="pa.value"                                                     
                                                                                                      
                                                        [readOnly]="false"  [checked]="pa.selected" (change)="onAddonChange($event)">
                                                    <label class="form-check-label"
                                                        >{{pa.name}} &nbsp; <strong>${{pa.price}}</strong></label>
                                                    </div>
                                                </ng-container>                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="form-control-borderless">
                                                <label class="h6 fw-normal mb-0"> Customer Name</label>
                                                <div class="form-border-bottom form-control-transparent mt-3">
                                                    <input type="text" class="form-control  bg-light"
                                                        placeholder="Enter Customer Name"
                                                        formControlName="customerName">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-12 col-lg-12">
                                            <label class="h6 fw-normal mb-0"> Pitch</label>
                                            <div class="form-border-bottom form-control-transparent mt-3 select-box">
                                                <select class="form-control bg-light " data-search-enabled="true"
                                                    name="reportType" formControlName="pitch" style="border: none;">
                                                    <option value="">Please select Pitch</option>
                                                    <option *ngFor="let op of pitchValues" [value]="op">
                                                        {{op}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="">
                                                <label class="h6 fw-normal mb-0">Expect Delivery Within</label>
                                                <div class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                    <ng-container
                                                        *ngFor="let op of expectedDeliveryWithinValues; let i = index;">
                                                        <div class="form-check form-check-inline active"
                                                            id="cab2-one-way-tab">
                                                            <input class="form-check-input" type="radio"
                                                                [id]="'type_'+op" [value]="op"
                                                                formControlName="expectedDeliveryWithin"
                                                                [readOnly]="false">
                                                            <label class="form-check-label"
                                                                [for]="'type_'+op">{{op}}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1" *ngIf="showIncludeSecondaryStructure">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="">
                                                <label class="h6 fw-normal mb-0"> Include Secondary Structure</label>
                                                <div class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                    <ng-container *ngFor="let op of yesNo; let i = index;">
                                                        <div class="form-check form-check-inline active"
                                                            id="cab2-one-way-tab">
                                                            <input class="form-check-input" type="radio"
                                                                [id]="'Itype_'+op" [value]="op"
                                                                formControlName='includeSecondaryStructure'
                                                                [readOnly]="false">
                                                            <label class="form-check-label"
                                                                [for]="'Itype_'+op">{{op}}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1" *ngIf="showIncludeObstructions">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="">
                                                <label class="h6 fw-normal mb-0">Include Obstructions</label>

                                                <div class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                    <ng-container *ngFor="let op of yesNo; let i = index;">
                                                        <div class="form-check form-check-inline active"
                                                            id="cab2-one-way-tab">
                                                            <input class="form-check-input" type="radio"
                                                                [id]="'IOBtype_'+op" [value]="op"
                                                                formControlName='includeObstructions'
                                                                [readOnly]="false">
                                                            <label class="form-check-label"
                                                                [for]="'IOBtype_'+op">{{op}}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-4 mt-1">
                                        <div class="col-md-6 col-lg-6">
                                            <label class="h6 fw-normal mb-0"> Notes</label>
                                            <div class="form-border-bottom form-control-transparent mt-3">
                                                <textarea class="form-control 	text-area" name="notes" id="notes"
                                                    formControlName="notes"
                                                    placeholder="Please enter your Notes here..."></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-6">
                                            <label class="h6 fw-normal mb-0"> Modeling Instructions</label>
                                            <div class="form-border-bottom form-control-transparent mt-3">
                                                <textarea class="form-control 	text-area" name="notes" id="notes"
                                                    formControlName="modelingInstructions"
                                                    placeholder="Please enter your Modeling Instructions here..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                
                                    
                                </div>
                            </form>
                            <div class="d-grid mt-4" *ngIf="isExpanded">
                                <button class="btn btn-primary-soft btn-primary-check mb-0" (click)="onSubmit()"
                                    [disabled]="!cartForm.valid && loading">
                                    <span *ngIf="!loading"><i class="bi bi-bag-check me-2"></i>{{(indexID>0)?'Update Cart Details' :
                                        'Add To Cart'}}</span>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="loading"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>