import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService, CART, USER } from '.';
import { CartItemRequest, CartItemUI, User } from '../_models';
import { StorageService } from './storage.service';



@Injectable({
  providedIn: 'root'
})
export class CartService {
  
  AUTH_API = environment.apiUrl+'/cart';
  
  deleteCartItem(i: any) {
    throw new Error('Method not implemented.');
  }

  $cartItemCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor(private storageService: StorageService, private http: HttpClient, private authService: AuthenticationService) { }

  getCartFromLocal() {
    let cartStorage = this.storageService.getLocalSt(CART);
    if(cartStorage){
      return cartStorage;
    }
    return [];
  }

  clearCart(){
    this.storageService.removeItemFromLocalSt(CART);
    this.refreshCount();
  }

  getCartFromServer(){
    const user = this.storageService.getLocalSt(USER) as User;
    return this.http.get(
      this.AUTH_API
    );
  }

  addCartItem(cartItem: CartItemRequest): Observable<any> {
    const user = this.storageService.getLocalSt(USER) as User;
    return this.http.post(
      this.AUTH_API,
      cartItem
    );
  }

  updateCartItem(cartItem: CartItemRequest): Observable<any> {
    const user = this.storageService.getLocalSt(USER) as User;
    const API_URL = this.AUTH_API + '?cartItemId=' + cartItem.cartItemId;
    return this.http.put(
      API_URL,
      cartItem
    );
  }

  deleteCartItemFromServer(cartItem: CartItemRequest): Observable<any> {
    const user = this.storageService.getLocalSt(USER) as User;
    const API_URL = this.AUTH_API + '?cartItemId=' + cartItem.cartItemId;
    const httpOptions = {
      headers: new HttpHeaders({        
        'Authorization': 'Bearer ' + user.data
      })
    };
    return this.http.delete(
      API_URL,
      httpOptions
    );
  }

  getCartOnIndex(i: any) {
    let cartStorage = this.getCartFromLocal()[i] as unknown as CartItemRequest;
    return cartStorage;
  }

  addItem(cartItem: CartItemUI) {
    this.saveCartItem(cartItem);
    if(this.authService.isUserLoggedIn()){
      let cartCount = this.getCartCount();
      this.addToCartServer(new CartItemRequest(cartItem), cartCount-1)
    }
  }
  
  
  updateItemOnIndex(cartItem: any, index: any,updateToServer:boolean, callback?: (() => void) | undefined) {
    let cartStorage = this.getCartFromLocal() as unknown as any[];
    cartStorage[index] = cartItem;
   
    if(this.authService.isUserLoggedIn() && updateToServer){
      this.updateToCartServer(new CartItemRequest(cartItem), index).then(() => {
        setTimeout(()=>{
          if(callback)callback();
        }, 500);
      });
    }
  }
  
  async addToCartServer(cartItemRequest: CartItemRequest, index:number) {
    new Promise((resolve)=>{
      this.addCartItem(cartItemRequest).subscribe(data=>{
        if(data.status){
          const cart = new CartItemUI(data.data)
          this.updateItemOnIndex(cart, index,false)
          this.refreshCount();
        }
        resolve(data)
      });
    })
  }

  async updateToCartServer(cartItemRequest: CartItemRequest, index:number) {
    new Promise((resolve)=>{
      this.updateCartItem(cartItemRequest).subscribe(data=>{
        if(data.status){
          const cart = new CartItemUI(data.data)
          this.updateItemOnIndex(cart, index,false);
          this.refreshCount();
        }
        resolve(data)
      });
    })
  }


  updateCartItemsToLocal(cartItems: CartItemUI[]) {
    this.updateCartItems(cartItems);
    this.refreshCount();
  }

  deleteItemFromCart(index: number) {
    let cartStorage = this.getCartFromLocal()as unknown as any[];
    let cardOnIndex = this.getCartOnIndex(index);
    cartStorage.splice(index, 1)
    this.updateCartItems(cartStorage);
    this.refreshCount();
    if(this.authService.isUserLoggedIn())
    {
      this.deleteCartItemFromServer(cardOnIndex).subscribe(data=>{
        if(data.status){
       
        }else{
        }
      });
    }
  }

  addItemApi(cartItem: any): Observable<any> {
    return this.addCartItem(cartItem);
  }

  getCartCount$(): Observable<number> {
    this.refreshCount();
    return this.$cartItemCount;
  }
  
  getCartCount() {
    const cart = this.getCartFromLocal();
    if(cart){
      return cart.length;
    }
    return 0;
  }

  refreshCount() {
    if(!this.authService.isUserLoggedIn()){
      this.$cartItemCount.next(0);
    }else{
      this.getCartFromServer().subscribe((res : any)=>{
        if( res?.data.cartItems && res?.data.cartItems){
          this.$cartItemCount.next(res?.data.cartItems.length);
        }
      });  
    }
  }


  public saveCartItem(cartItem: any): void {
    let cart= this.getCartFromLocal() as unknown as any[];
    if(!cart){
      cart = [];
    }
    cart.push(cartItem)
    this.storageService.setLocalSt(CART,cart);
   }
 
   updateCartItems(cartItems:any[]){
     this.storageService.setLocalSt(CART,cartItems);
   }


}
