
<div class="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0">
    <div class="card bg-transparent">
        <!-- Card header -->
        <div class="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
            <h6 class="m-0">Notifications <span class="badge bg-danger bg-opacity-10 text-danger ms-2" *ngIf="newNotification.length>0">{{newNotification.length}} new</span></h6>
            <a class="small" href="#"  *ngIf="notifications.length>0">Clear all</a>
        </div>

        <!-- Card body START -->
        <div class="card-body p-0">
            <ul class="list-group list-group-flush list-unstyled p-2"  *ngFor="let notif of notifications">
                <!-- Notification item -->
                <li>
                    <a href="#" class="list-group-item list-group-item-action rounded border-0 mb-1 p-3" [ngClass]="{'notif-unread': notif.isNew}" *ngIf="notif.isNew">
                        <h6 class="mb-2">New! Booking flights from New York ✈️</h6>
                        <p class="mb-0 small">Find the flexible ticket on flights around the world. Start searching today</p>
                        <span>Wednesday</span>
                    </a>
                </li>
                <!-- Notification item -->
                
            </ul>

            <div class="flex d-flex empty-card">
                <small> No new Notifications!</small>
            </div>
        </div>
        <!-- Card body END -->

        <!-- Card footer -->
        <div class="card-footer bg-transparent text-center border-top" *ngIf="notifications.length>0">
            <a href="#" class="btn btn-sm btn-link mb-0 p-0">See all incoming activity</a>
        </div>
    </div>
</div>