import { Component, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from '@app/_services/user.service';

@Component({
  selector: 'app-blue-print-report',
  templateUrl: './blue-print-report.component.html',
  styleUrls: ['./blue-print-report.component.scss']
})
export class BluePrintReportComponent {
  constructor(public userService: UserService,private title: Title, private meta: Meta, private router: Router) {
    this.title.setTitle("Blueprints Roof Reports USA, Canada - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "These are roof reports of under construction/yet-to-be-constructed properties drafted using architectural Blueprints provided by the client." })
    this.meta.updateTag({ name: 'keywords', content: "Blueprint Roof Report,Blueprint Roof Report USA,Blueprint Roof Report Canada" })

  }

  public innerWidth: any;
public isMobile=false
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth<769)
    {
      this.isMobile=true
    }else{
      this.isMobile=false
    }
}
@HostListener('window:resize', ['$event'])
onResize(event:any) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth<769)
    {
      this.isMobile=true
    }else{
      this.isMobile=false
    }
}
orderNow(type: string) {
  this.router.navigateByUrl('/order-now', { state: { search: '', reportType: type } });

}

downlaodPDF(){
  let link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.href ='assets/downloads/blueprint-sample-report.pdf'
  link.download = 'blueprint-sample-report';
  link.click();
  link.remove();
}
}
