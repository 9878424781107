import { Component } from '@angular/core';

@Component({
  selector: 'app-update-logo',
  templateUrl: './update-logo.component.html',
  styleUrls: ['./update-logo.component.scss']
})
export class UpdateLogoComponent {

}
