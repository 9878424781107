<div class="container">
    <div class="row align-items-center">
        <div class="col-md-10 text-center mx-auto">
           
            <!-- Image -->
            <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/no-cart.svg" class="no-data-img mb-4" alt="No data found">

            <!-- Title -->
            <h1 class="display-1 text-primary mb-0" *ngIf="show_404">404</h1>
            <!-- Subtitle -->
            <h4 class="mt-4 heading-message">{{message}}</h4>
            <!-- info -->
            <p class="mb-4">{{subText}}</p>
            <!-- Button -->
            <a href="index-2.html" *ngIf="showNavigationButton" class="btn btn-light mb-0" [routerLink]="['/'+buttonActionURL]">{{buttonText}}</a>
        </div>
    </div>
</div>