<!-- Pagination -->
<nav class="d-flex justify-content-center" aria-label="navigation">
    <ul class="pagination pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
        <li class="page-item mb-0 "  [ngClass]="{'block-click': current === 1}">
            <button class="page-link"
                (click)="current !== 1 && onPrevious()" tabindex="-1"><i class="fa-solid fa-angle-left"></i></button>
        </li>
        <li *ngFor="let page of pages;" class="page-item mb-0 " [ngClass]="{'active': page === this.current}">
            <a class="page-link" [attr.aria-current]="page === current ? 'page' : null"
                [attr.aria-label]="page === current ? 'Current Page, Page ' + page : 'Go to Page ' + page"
                [class.current]="page === current" tabindex="0" (click)="onGoTo(page)" (keyup.enter)="onGoTo(page)">
                <span *ngIf="page!=-1">
                    {{ page }}
                </span>
                <span *ngIf="page===-1">
                    ..
                </span>
            </a>
        </li>
        <li class="page-item mb-0 "   [ngClass]="{'block-click': current === total}">
            <button class="page-link"
               (click)="current != total && onNext()">
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </li>
    </ul>
</nav>