import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AccuracyComponent } from './accuracy/accuracy.component';
import { BluePrintReportComponent } from './blue-print-report/blue-print-report.component';
import { CartComponent } from './cart/cart.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
// import { OrderNowV2Component } from './order-now-v2/order-now-v2.component';
import { OrderNowComponent } from './order-now/order-now.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ViewPropertyMapComponent } from './view-property-map/view-property-map.component';
import { AuthGuard } from './_helpers';
import { ViewPropertyThreedMapComponent } from './view-property-threed-map/view-property-threed-map.component';
import { CommercialReportComponent } from './commercial-report/commercial-report.component';
import { GutterReportComponent } from './gutter-report/gutter-report.component';
import { ResidentialReportComponent } from './residential-report/residential-report.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'home/services', component: HomeComponent },
    { path: 'home/pricing', component: HomeComponent },
    { path: 'my-orders', component: MyOrdersComponent , canActivate: [AuthGuard] },
    { path: 'order-now', component: OrderNowComponent , canActivate: [AuthGuard] },
    { path: 'order-now/:cartItemId', component: OrderNowComponent, canActivate: [AuthGuard] },
    { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
    { path: 'checkout', component: CartComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'verify-email', component: LoginComponent },
    { path: 'verify-email/:param1', component: LoginComponent },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'about-us', component: AboutUsComponent},
    { path: 'privacy-policy', component: PrivacyPolicyComponent},
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent},
    { path: 'accuracy', component: AccuracyComponent},
    { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'my-profile', component: MyProfileComponent},
    { path: 'contact-us', component: ContactUsComponent},
    { path: 'view-property-map', component: ViewPropertyMapComponent},
    { path: 'blue-print-report', component: BluePrintReportComponent},
    { path: 'commercial-report', component: CommercialReportComponent},
    { path: 'gutter-report', component: GutterReportComponent},
    { path: 'residential-report', component: ResidentialReportComponent},
    { path: 'map.php', component: ViewPropertyThreedMapComponent},
    { path: 'map', component: ViewPropertyThreedMapComponent},
    { path: 'guaranteed-accuracy.php', component: AccuracyComponent},
    { path: '404', redirectTo: 'home'},
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor() { 
        //   console.log("Routing...")
    
        }
 }
