<header class="navbar-light sticky-top ">
	<nav class="navbar navbar-expand-xl">
		<div class="container">
			<a class="navbar-brand" [routerLink]="['/home']">
				<img class="light-mode-item navbar-brand-item" src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/logo/KRLLogoaBlack_Red-2.png" alt="logo">
				<img class="dark-mode-item navbar-brand-item" src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/logo/KRLLogoawhite__Red-2.png" alt="logo">
			</a>

			

			<button class="navbar-toggler ms-sm-auto mx-3 me-md-0 p-0 p-sm-2" type="button" data-bs-toggle="collapse"
				data-bs-target="#navbarCategoryCollapse" aria-controls="navbarCategoryCollapse" aria-expanded="false"
				aria-label="Toggle navigation" *ngIf="(!isMobileDevice || !(user$ | async))">
				<i class="bi bi-grid-3x3-gap-fill fa-fw"></i><span
					class="d-none d-sm-inline-block small"></span>
			</button>

			<div class="navbar-collapse collapse" id="navbarCollapse">
				<ul class="navbar-nav navbar-nav-scroll me-auto">
					<li class="nav-item ">
						<a class="nav-link " id="listingMenu"  aria-haspopup="true"
							aria-expanded="false" [routerLink]="['/home', {scrollItem: 'services'}]"
							>Services</a>
					</li>

					<li class="nav-item ">
						<a class="nav-link" id="pagesMenu"  aria-haspopup="true"
							aria-expanded="false" [routerLink]="['/home', {scrollItem: 'pricing'}]">Pricing</a>
					</li>

					<li class="nav-item ">
						<a class="nav-link" id="pagesMenu" aria-haspopup="true"
							aria-expanded="false" routerLinkActive="active" [routerLink]="['/accuracy']">Accuracy</a>
					</li>

					<li class="nav-item ">
						<a class="nav-link" id="accounntMenu"  aria-haspopup="true"
							aria-expanded="false" routerLinkActive="active" [routerLink]="['/contact-us']" >
							Contact Us</a>
					</li>
					<li class="nav-item ">
						<a class="nav-link" id="accounntMenu"  aria-haspopup="true"
							aria-expanded="false" routerLinkActive="active" [routerLink]="['/faq']" >
							FAQ's</a>
					</li>
				</ul>
			</div>

			<div class="navbar-collapse collapse" id="navbarCategoryCollapse"
				*ngIf="(!isMobileDevice || !(user$ | async)) ">
				<ul class="navbar-nav navbar-nav-scroll nav-pills-primary-soft text-center ms-auto p-2 p-xl-0">
					<li class="nav-item"> <a class="nav-link " routerLinkActive="active" [routerLink]="['/order-now']">
							<i class="{{app_icons.ORDER_NOW}}"></i>Order Now</a> </li>

					<li class="nav-item"> <a class="nav-link" routerLinkActive="active" [routerLink]="['/cart']">
							<i class="{{app_icons.CART}}"></i>
							Cart <span *ngIf="cartCount>0">({{cartCount}})</span>
						</a>
					</li>



					<!--  -->

					<li class="nav-item mode-switch">
						<div class="modeswitch-wrap" id="darkModeSwitch" (click)="toggleDarkMode()">
							<div class="modeswitch-item">
								<div class="modeswitch-icon"></div>
							</div>
						</div>
					</li>

					<li class="nav-item" *ngIf="!(user$ | async)"> <a class="nav-link" href="index-tour.html"
						routerLinkActive="active" [routerLink]="['/login']"><i class="{{app_icons.LOGIN_SIGNUP}}"></i>Login / Signup </a>
					</li>
				</ul>
			</div>

			<ul class="nav flex-row align-items-center list-unstyled ms-xl-auto" *ngIf="user$ | async">

				<li class="nav-item dropdown ms-0 ms-md-3" *ngIf="feature.NOTIFICATION">
					<a class="nav-notification btn btn-light p-0 mb-0" href="#" role="button" data-bs-toggle="dropdown"
						aria-expanded="false" data-bs-auto-close="outside">
						<i class="{{app_icons.NOTIFICATION_BELL}}"></i>
					</a>
					<span class="notif-badge animation-blink" *ngIf="hasNewNotification"></span>
					<app-notification></app-notification>
				</li>

				<li class="nav-item ms-3 dropdown">
					<a class="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button"
						data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown"
						aria-expanded="false">
						<div class="avatar-img rounded-2 name-initial" alt="avatar">
							{{nameInitials}}
						</div>
					</a>

					<ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
						id="dropdown-menu" aria-labelledby="profileDropdown">
						<li class="px-3 mb-3">
							<div class="d-flex align-items-center">
								<div class="avatar me-3">
									<div class="avatar-img rounded-circle shadow name-initial" alt="avatar">
										{{nameInitials}}
									</div>
								</div>
								<div>
									<a class="h6 mt-2 mt-sm-0" href="#">{{userName}}</a>
									<p class="small m-0">{{userEmail}}</p>
								</div>
							</div>
						</li>

						<li>
							<hr class="dropdown-divider">
						</li>

						<li><a class="dropdown-item" routerLinkActive="active" [routerLink]="['/my-profile']"><i
									class="bi bi-person fa-fw me-2"></i>My Profile</a></li>
						<li><a class="dropdown-item" routerLinkActive="active" [routerLink]="['/my-orders']"><i
									class="bi bi-bookmark-check fa-fw me-2"></i>My Orders</a></li>
						<li><a class="dropdown-item" routerLinkActive="active" [routerLink]="['/cart']"><i
									class="{{app_icons.CART_CHECK}}"></i>Cart</a></li>
						
						<li><a class="dropdown-item bg-danger-soft-hover" (click)="logout()"><i
									class="bi bi-power fa-fw me-2"></i>Sign Out</a></li>
						<li>
							<hr class="dropdown-divider">
						</li>
						<li>
							<div class="modeswitch-wrap" id="darkModeSwitch" (click)="toggleDarkMode()">
								<div class="modeswitch-item">
									<div class="modeswitch-icon"></div>
								</div>
								<span>Dark mode</span>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</nav>
</header>