import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private localSt:LocalStorageService, private sessionSt: SessionStorageService) { }

  getLocalSt(key: string): string {  
    return this.localSt.retrieve(key);
  }

  setLocalSt(key: string, value: any): void {
    this.localSt.store(key, value);
  }

  getSessionSt(key: string): string {
    return this.sessionSt.retrieve(key);
  }

  setSessionSt(key: string, value: string): void {
    this.sessionSt.store(key, value);
  }

  removeItemFromSessionSt(key: string): void {
    this.sessionSt.clear(key);
  }

  removeItemFromLocalSt(key: string): void {
    this.localSt.clear(key);
  }
}
