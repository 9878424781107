import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, ForgotPasswordService } from '@app/_services';
import { first } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordForm!: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  successMsg = '';
  hasToken:boolean = false;
  token = '';
  
  constructor(private formBuilder: FormBuilder, 
    private forgotPasswordService: ForgotPasswordService, 
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { 
    // redirect to home if already logged in
    if (this.authenticationService.userValue) { 
      this.router.navigate(['/']);
    }
    if(this.route.snapshot.queryParams['token']){
      this.hasToken = true;
      this.token = this.route.snapshot.queryParams['token'];
    }else{
      this.hasToken = false;
    }
  }
  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.forgotPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.error = '';
    this.loading = true;
    this.successMsg = '';
    this.forgotPasswordService.resetPassword(this.f.email.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.successMsg = data;
          this.loading = false;
        },
        error: error => {
          this.error = error?.extras?.errorObject?.error[0];
          this.loading = false;
        }
      });
  }
  
  verify(token:string){

  }
}
