import { Component, Input } from '@angular/core';
import { CartItemUI } from '@app/_models';
import { APP_ICONS, COLOR_CLASS, CONFIG_MESSAGES, ConfirmationPopupService, MESSAGE_TYPE, OrderService, PAYMENT_TAGS, STATUS, TABS, TAGS, UserService } from '@app/_services';
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { TooltipPosition } from '@app/shared/directive/tooltip/tooltip.enums';
const DEFAULT_DURATION = 100;

@Component({
  selector: 'app-product-card-list',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', display: 'none' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class ProductCardListComponent {
  public app_icons = APP_ICONS;
  @Input() cart:CartItemUI[] = []
  @Input() products:Map<number,any>=new Map(); 
  @Input() fromComponent:any = null;
  @Input() isNew?:boolean = false;
  public tabs = TABS;
  public tags = TAGS;
  public paymentStatusTags = PAYMENT_TAGS;
  public colorClasses:any = COLOR_CLASS;
  public orderStatus = STATUS;
  public actionItem=true;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  constructor(private confirmationPopupService: ConfirmationPopupService, public userService: UserService,private ordersService: OrderService){
    
  }

  ngOnInit() {
    this.cart.forEach(element=>{
      // element.paymentStatus = 'Completed'
      if(this.tabs.CART === this.fromComponent || this.tabs.MY_ORDERS === this.fromComponent){
        element.isCollapsed = false;
      }else{
        element.isCollapsed = true;
      }
    })
  }

  onDelete(cartItem: CartItemUI){
    this.confirmationPopupService.message=CONFIG_MESSAGES.cart.delete_message;
    this.confirmationPopupService.title=CONFIG_MESSAGES.cart.delete_title;
    this.confirmationPopupService.item=cartItem;
    this.confirmationPopupService.type = MESSAGE_TYPE.danger;
    this.confirmationPopupService.isFromProductCard = this.fromComponent
    this.confirmationPopupService.open(cartItem);
  }

  viewMap(lat:any,lng:any,orderName:any)
  {
    console.log("Clicked on view property omn map.")    
    window.open('https://krlinc.com/map.php?lat='+lat+'&lng='+lng+"&orderId="+orderName,'_blank'); 
  }

  downloadOrderZipFile(orderProductId:any,invoiceId:any)
  {    
    (document.getElementById('download-'+orderProductId )as HTMLButtonElement).disabled=true;
      this.ordersService.downloadOrder(orderProductId,invoiceId).subscribe({
        next: (data: any) => {          
          if (data.status == true) {          
              window.open(data['data']['url'],'_blank'); 
          }else{
            (document.getElementById('download-error-'+orderProductId )as HTMLDivElement).style.display='flex';
           (document.getElementById('download-error-'+orderProductId )as HTMLDivElement).innerHTML=data['data']['message']; 
           setTimeout(() => {
              (document.getElementById('download-error-'+orderProductId )as HTMLDivElement).innerHTML='';
            }, 5000);
          }
          (document.getElementById('download-'+orderProductId )as HTMLButtonElement).disabled=false;
        }, error: err => {
          (document.getElementById('download-'+orderProductId )as HTMLButtonElement).disabled=false;
          console.log(err);
        }
      });
    
  }
  isOrderStatusPresent(orderStatus:string)
  {
    let orderStatusAry=['Pending' ,'Processing']
    if(orderStatusAry.indexOf(orderStatus) !== -1) {
      return true
    }
    return false
  }
}
