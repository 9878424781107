import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, REMEMBER, StorageService, SyncService } from '@app/_services';
import { CartService } from '@app/_services/cart.service';
import { environment } from '@environments/environment';
import { TYPE } from '@app/alert/alert.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({ 
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit {
    loginForm!: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    success = '';
    warning = '';
    isviewed:boolean=false;
    isLoading:boolean = false;
    sendVerification:boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private syncService: SyncService,
        private cartService: CartService,
        private storageService: StorageService,
        private alertService: AlertService,
        private authService: AuthenticationService,private title: Title, private meta: Meta
    ) {
        this.title.setTitle("Login - KRL INC.");
        this.meta.updateTag({ name: 'description', content: "" })
        // redirect to home if already logged in
        if (this.authenticationService.userValue) { 
            this.router.navigate(['/']);
        }

        if(this.route.snapshot.queryParams['code']){
            this.isLoading = true;
            this.verify(this.route.snapshot.queryParams['code'])
        }
    }

    ngOnInit() {
        let email = this.storageService.getLocalSt(REMEMBER);
        if(email){
            this.loginForm = this.formBuilder.group({
                username: [email, Validators.required],
                password: ['', Validators.required],
                rememberMe: [true]
            });
        }else{
            this.loginForm = this.formBuilder.group({
                username: ['', Validators.required],
                password: ['', Validators.required],
                rememberMe: [false]
            });
        }
        
    }

    // convenience getter for easy access to form fields
    get f():any { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.success = '';
        this.error = '';
        this.warning = '';
        this.sendVerification = false;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.error = '';
        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value, this.f.rememberMe.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.syncService.syncDataFromServer().then((isSuccess)=>{
                        //this.syncService.syncDataToServer().then((isSuccess)=>{
                          this.cartService.refreshCount();
                        //})
                      })
                    // get return url from route parameters or default to '/'
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    this.router.navigate([returnUrl]);
                },
                error: error => {
                    if(error.extras && error.extras.data && error.extras.data.emailNotVerified){
                        this.sendVerification = true;
                        this.warning = error.message;
                    }else{
                        this.error = error.message;
                    }
                    
                    this.loading = false;
                }
            });
    }

    verify(code:string){
        this.storageService.removeItemFromLocalSt(REMEMBER);
        this.loading=true;
        this.authService.logout();
        this.authenticationService.verifyCode({code:code})
        .pipe(first())
        .subscribe({
            next: (data:any) => {
                this.isLoading = false;
                this.alertService.showAlert({
                    message: data.message,
                    type: TYPE.success,
                    mode: 'fixed-header',
                  });
                this.error = '';
                this.warning = '';
                this.loading=false;
                this.sendVerification = false; 
            },
            error: error => {
                // this.error = error.message;
                this.alertService.showAlert({
                    message: error.message,
                    type: TYPE.danger,
                    mode: 'fixed-header',
                  });
                this.loading=false;
            }
        });
    }

    sendVerificationLink(){
        this.loading=true;
        this.authenticationService.resendVerificationUrl({email:this.f.username.value, emailVerificationUrl: environment.emailVerifyUrl })
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.showAlert({
                    message: 'Verification Link sent successfully, Kindly check your email and try login again.',
                    type: TYPE.success,
                    mode: 'fixed-header',
                  });
                this.error = '';
                this.warning = '';
                this.loading=false;
                this.sendVerification = false; 
            },
            error: error => {
                // this.error = error.message;
                this.alertService.showAlert({
                    message: error.message,
                    type: TYPE.danger,
                    mode: 'fixed-header',
                  });
                this.loading=false;
            }
        });
    }
}
