<section class="pt-3">
  <div class="container">
    <div class="row g-1">
      <!-- Main content START -->
      <div class="col-lg-12 col-xl-12">
        <div class="vstack gap-1">
          <!-- Personal info START -->
          <div class="card border">
            <!-- Card header -->
            <div class="card-header border-bottom">
              <h4 class="card-header-title">Personal Information</h4>
            </div>

            <!-- Card body START -->
            <div class="card-body">
              <!-- Form START -->
              <form class="row g-2" [formGroup]="updateProfileForm">
                <!-- First Name -->
                <div class="col-md-4">
                  <label class="form-label">First Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control form-control-alternative" placeholder="First name"
                    formControlName="firstName" [ngClass]="{ 'is-invalid': (f.firstName.errors ) }"
                    [tooltip]="(f.firstName.errors ) ? 'First name is required' : ''" [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')"
                    >
                   
                </div>
                <!-- Last Name -->
                <div class="col-md-4">
                  <label class="form-label">Last Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control form-control-alternative" placeholder="Last name"
                    formControlName="lastName" [ngClass]="{ 'is-invalid': (f.lastName.errors )}"
                    [tooltip]="(f.lastName.errors ) ? 'Last name is required' : ''" [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')">
                </div>

                <!--  Email Address -->
                <div class="col-md-4">
                  <label class="form-label">Email Address</label>
                  <input type="email" class="form-control form-control-alternative" readonly [value]="profile.email"
                    placeholder="Email Address" >
                </div>

                <!-- Phone Number -->
                <div class="col-md-4">
                  <label class="form-label">Phone Number</label>
                  <input type="text" class="form-control form-control-alternative" placeholder="Phone Number"
                    formControlName="phoneNumber" [maxlength]="15">
                    
                    <div class="font-italic" *ngIf="updateProfileForm.controls['phoneNumber'].touched && updateProfileForm.controls['phoneNumber'].errors && updateProfileForm.controls['phoneNumber'].errors['pattern']">
                      <small class="text-danger font-weight-700">
                            Invalid phone number.
                      </small>
                    </div>

                </div>


                <div class="col-md-4">
                  <label class="form-label">Company Name</label>
                  <input type="text" class="form-control form-control-alternative " placeholder="Company Name"
                    formControlName="company">
                </div>

 <!-- Secondary Email Address -->
                <div class="col-md-4">
                  <label class="form-label">Secondary Email Address</label>
                  <input type="email" class="form-control form-control-alternative"
                    placeholder="Secondary Email Address" formControlName="secondaryEmail">
                </div>
                <!-- Address -->
                <div class="col-md-8">
                  <label class="form-label">Address<span class="text-danger">*</span></label>
                  <input class="form-control form-control-alternative" placeholder="Address" value="" type="text"
                    formControlName="address" spellcheck="false" 
                    [ngClass]="{ 'is-invalid': (f.address.errors )}"
                    [tooltip]="(f.address.errors ) ? 'Address is required' : ''" [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')"/>
                </div>
                <!-- Country -->
                <div class="col-md-4">
                  <label class="form-label">Country<span class="text-danger">*</span></label>
                  <select class="form-control form-control-alternative" name="selectAction"
                    (change)="onCountryChange($event)" formControlName="countyId">
                    <option *ngFor="let op of country" [value]="op.id">
                      {{op.name}}
                    </option>
                  </select>
                </div>

                <!-- State -->
                <div class="col-md-4">
                  <label class="form-label">State<span class="text-danger">*</span></label>
                  <select class="form-control form-control-alternative" name="selectAction" formControlName="stateId">
                    <option *ngFor="let op of state" [value]="op.id">
                      {{op.name}}
                    </option>
                  </select>
                </div>

                <!-- City -->
                <div class="col-md-4">
                  <label class="form-label">City<span class="text-danger">*</span></label>
                  <input type="text" class="form-control form-control-alternative" placeholder="City" value="New York"
                    formControlName="city" 
                    [ngClass]="{ 'is-invalid': (f.city.errors )}"
                    [tooltip]="(f.city.errors ) ? 'City is required' : ''" [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')"/>
                </div>

                <!-- Postal -->
                <div class="col-md-4">
                  <label class="form-label">Postal Code<span class="text-danger">*</span></label>
                  <input type="text" id="input-postal-code" class="form-control form-control-alternative"
                    placeholder="Postal code" formControlName="postCode"  [maxlength]="12"/>
                    <div class="font-italic" *ngIf="updateProfileForm.controls['postCode'].touched && updateProfileForm.controls['postCode'].errors && updateProfileForm.controls['postCode'].errors['pattern']">
                      <small class="text-danger font-weight-700">
                        Invalid Postal code.
                      </small>
                    </div>
                </div>
                <!-- Button -->
                <div class="row  d-flex justify-content-end mt-4">
                  <div class=" col text-end">
                      <ul class="list-unstyled" *ngIf="errorMsg">
                          <li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> {{errorMsg}}
                          </li>
                      </ul>
                      <ul class="list-unstyled" *ngIf="successMsg">
                          <li class="mb-1"><i class="bi bi-check-circle text-success me-2"></i>
                              {{successMsg}}</li>
                      </ul>
                  </div>
              </div>
              <div class="col-md-12">
                <div class=" text-end">
                  <button type="submit" class="btn btn-primary"
                      [disabled]="!updateProfileForm.valid ||  loading" (click)="onSubmit()">
                      <span *ngIf="!loading">Save Changes</span>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                  </button>
              </div>
            </div>
            
              </form>
              <!-- Form END -->
            </div>
            <!-- Card body END -->
          </div>
          <!-- Personal info END -->


        </div>
      </div>
      <!-- Main content END -->
    </div>
    <div class="row g-2 pt-4">
      <!-- <div class="vstack gap-4"> -->
      <!-- <app-update-logo></app-update-logo> -->
      <!-- <div class="col-md-3 col-lg-3">
        <app-update-logo></app-update-logo>
      </div> -->
      <div class="col-md-12 col-lg-12">
        <app-change-password></app-change-password>
      </div>
      <!-- </div> -->
    </div>
    <!-- Main content END -->


  </div>
</section>