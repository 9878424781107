<app-services-page style="display: none;"></app-services-page>

<section class="pt-4 pt-md-5">
    <div class="container">
        <div class="row g-4 align-items-center">
            <div class="col-lg-7">
                <h1 class="mb-4 display-5">Looking For <span class="text-primary">Blueprint Roof Reports</span></h1>
                <p class="mb-4">These are roof reports of under construction/yet-to-be-constructed properties drafted
                    using architectural Blueprints provided by the client.</p>
                <a (click)="orderNow('blueprint')" class="btn btn-primary mb-4">Order Now <i
                        class="fa-solid fa-arrow-right-long fa-fw"></i></a>
                <h6 class="fw-normal mb-1">What we offer nobody else is offering:</h6>
                <ul class="list-group list-group-borderless mb-0 small">
                    <li class="list-group-item d-flex mb-0">
                        <i class="fa-solid fa-check-circle text-success me-2 mt-1"></i>Detailed roof reports with
                        unmatched accuracy.
                    </li>
                    <li class="list-group-item d-flex mb-0">
                        <i class="fa-solid fa-check-circle text-success me-2 mt-1"></i>3D file which run directly on any
                        web browser and shows your in interactive 3D environment with measurements.
                    </li>
                    <li class="list-group-item d-flex mb-0">
                        <i class="fa-solid fa-check-circle text-success me-2 mt-1"></i>An HTML file with all facet
                        details for clear understanding of the roof.
                    </li>
                </ul>
            </div>
            <div class="col-lg-5 text-center">
                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/report_web/Blue.webp" alt="">
            </div>
        </div>
    </div>
</section>
<section class="pt-0">
    <div class="container">
        <div class="row g-4 align-items-center">
            <div class="col-sm-6 col-md-3">
                <h4 class="mb-0">Numbers Of Reports Delivered</h4>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="card card-body text-center bg-light">
                    <div class="d-flex justify-content-center">
                        <h3 class="purecounter text-orange mb-0" data-purecounter-start="0" data-purecounter-end="28"
                            data-purecounter-delay="300">50</h3>
                        <span class="h3 text-orange mb-0">K+</span>
                    </div>
                    <h6 class="fw-normal mb-0">Residential Roof Reports</h6>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="card card-body text-center bg-light">
                    <div class="d-flex justify-content-center">
                        <h3 class="purecounter text-info mb-0" data-purecounter-start="0" data-purecounter-end="51"
                            data-purecounter-delay="300">27</h3>
                        <span class="h3 text-info mb-0">K+</span>
                    </div>
                    <h6 class="fw-normal mb-0">Commercial Roof Reports</h6>
                </div>
            </div>

            <div class="col-sm-6 col-md-3">
                <div class="card card-body text-center bg-light">
                    <div class="d-flex justify-content-center">
                        <h3 class="purecounter text-primary mb-0" data-purecounter-start="0" data-purecounter-end="41"
                            data-purecounter-delay="300">3</h3>
                        <span class="h3 text-primary mb-0">K+</span>
                    </div>
                    <h6 class="fw-normal mb-0">Counties Covered Through US & Canda</h6>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-0 pt-md-5">
    <div class="container">
        <div class="row">
            <div class="col-12"  style="text-align: justify;">
                <strong>The client needs to <span class="text-primary">provide architectural Blueprints</span> of the roof along <span class="text-primary">with exact measurements of the
                    longest eave in meters or ft</span>. Once this information is gathered the process of drafting the roof report
                    begins and the completed roof report is emailed back in promised time frame of 24 hours.</strong>
                
            </div>
        </div>
    </div>
</section>
<section class="pt-0 pt-md-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="bg-light p-4 p-sm-5 rounded-3">
                    <h2 class="fs-3 text-center mb-4 mb-sm-5">Just follow these simple steps</h2>

                    <div class="row g-4 align-items-center">
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 step1"><h5>
                                    Place an order  via our website by selecting report type as blueprint.</h5>
                                </div>                   
            
                                <div class="col-12  ">
                                    
                                    <img class="gap-2"
                                        src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/blueprint/1.webp"
                                        class="rounded-3 img-border" alt="Blueprint report step 1">
                                
                                </div>
                                <div class="col-12 step3" *ngIf="!isMobile"><h5>
                                    The blueprints must have all elevation diagrams provided by the Architect. </h5>
                                    <strong>
                                        Currently we only accept Blueprint Orders for residential properties only.
                                    </strong>
                                </div>
            
                                <div *ngIf="isMobile"> <h5>Mark the measurement of longest eave (in ft as shown in below image) and
                                    email us those blueprints at Corporate@krlinc.com</h5></div>
            
            
            
                            </div>
            
                        </div>
            
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 ">
                                    <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/blueprint/2.webp"
                                        class="rounded-3 img-border" alt="Blueprint report step 2">
                                   
                                </div>
                                <div *ngIf="!isMobile"><h5>Mark the measurement of longest eave (in ft as shown in above image) and
                                    email us those blueprints at Corporate@krlinc.com</h5></div>
                                <div *ngIf="isMobile">
                                    <h5>The blueprints must have all elevation diagrams provided by the Architect.</h5>
                                    <strong>
                                        Currently we only accept Blueprint Orders for residential properties only.
                                    </strong>
                                </div>
            
                                <div class="col-12">
                                    <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/blueprint/3.webp"
                                        class="rounded-3 img-border" alt="Blueprint report step 3">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="common-sections-pb">
	<div class="container px-lg-2">

		<div class="row mb-4">
			<div class="col-12 text-center">
				<h2>Explore <span class="text-primary">Our Reports</span></h2>
			</div>
		</div>
		<div class="row justify-content-center" style="border-width: 1px;">
            <div class="tiny-slider arrow-round arrow-blur arrow-hover">
                <div class="tiny-slider-inner" data-autoplay="true" data-arrow="true" data-dots="false" data-items="1">
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/reports/blue1.webp" class="rounded" alt="Blueprint report 1">
                            </div>
                            <div class="col-lg-6 mt-2">
                                <a class="btn btn-primary w-100 mb-0"  (click)="downlaodPDF()" >Download Sample</a>
                            </div>
                        </div>
                    </div>	
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/reports/blue2.webp" class="rounded" alt="Blueprint report 2">
                            </div>
                            <div class="col-lg-6 mt-1">
                                <a class="btn btn-primary w-100 mb-0" (click)="downlaodPDF()"  >Download Sample</a>
                            </div>
                        </div>
                    </div>	
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/reports/blue3.webp" class="rounded" alt="Blueprint report 3">
                            </div>
                            <div class="col-lg-6 mt-2">
                                <a class="btn btn-primary w-100 mb-0" (click)="downlaodPDF()"  >Download Sample</a>
                            </div>
                        </div>
                    </div>	                   
                </div>
            </div>
		</div>
	</div>
</section>



<section>
    <div class="container">
        <div class="row g-4">
            <div class="col-12">
                <!-- Action box START -->
                <div class="card card-body shadow p-4">
                    <div class="row g-4 justify-content-between align-items-center">
                        <!-- Image -->
                        <div class="col-sm-3 col-lg-2">
                            <img src="https://ik.imagekit.io/krlinc/assets/images/element/22.svg" alt="contact-us">
                        </div>

                        <!-- Title and content -->
                        <div class="col-sm-9 col-lg-6 col-xl-7">
                            <h4>Have More Questions?</h4>
                            <p class="mb-0">If you have any questions/query or need any assistance please contact us on corporate@krlinc.com</p>
                        </div>

                        <!-- Button -->
                        <div class="col-lg-3 col-xxl-2 d-grid">
                            <a [routerLink]="['/contact-us']" class="btn btn-primary mb-0">Contact Us</a>
                        </div>
                    </div>
                </div>
                <!-- Action box END -->
            </div>
        </div>
    </div>
</section>
