<section class="vh-xxl-100 mb-8" *ngIf="!signUpCompleted">
  <div class="container h-100 d-flex px-0 px-sm-4" >
    <div class="row justify-content-center align-items-center ">
      <div class="col-12">
        <div class="bg-mode shadow rounded-3 overflow-hidden">
          <div class="p-4 " >
            <h1 class="mb-2 h3">Create new account</h1>
            <p class="mb-0">Already a member?<a [routerLink]="['/login']"> Log in</a></p>


            <form role="form" [formGroup]="registerForm">

              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 card ">
                    <div class="card-header border-bottom">
                      <h5 class="mb-0">User information</h5>
                    </div>

                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-first-name">First name
                              <span class="text-danger font-weight-700">*</span>
                            </label>
                            <input type="text" id="input-first-name" class="form-control form-control-alternative"
                              placeholder="First name" value="" formControlName="firstName">
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-last-name">Last name
                              <span class="text-danger font-weight-500">*</span></label>
                            <input type="text" id="input-last-name" class="form-control form-control-alternative"
                              placeholder="Last name" value="" formControlName="lastName">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-email1">Email address
                              <span class="text-danger font-weight-500">*</span></label>
                            <input type="email" id="input-email1" class="form-control form-control-alternative"
                              placeholder="Email Address" formControlName="email">
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-email2">Secondary Email address</label>
                            <input type="email" id="input-email2" class="form-control form-control-alternative"
                              placeholder="Secondary Email Address" formControlName="secondaryEmail">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-address">Company Name</label>
                            <input id="input-address" class="form-control form-control-alternative "
                              placeholder="Company Name" value="" type="text" formControlName="company">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-address">Phone Number</label>
                            <input id="input-address" class="form-control form-control-alternative"
                              placeholder="Phone Number" value="" type="text" formControlName="phoneNumber" [maxlength]="15">
                              <div class="font-italic" *ngIf="registerForm.controls['phoneNumber'].touched && registerForm.controls['phoneNumber'].errors && registerForm.controls['phoneNumber'].errors['pattern']">
                                  <small class="text-danger font-weight-700">
                                        Invalid phone number.
                                  </small>
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="col-md-6 card ">
                    <div class="card-header border-bottom">
                      <h5 class="mb-0">Contact information</h5>
                    </div>

                    <div class="card-body">
                      
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-control-label" for="input-address">Address
                              <span class="text-danger font-weight-500">*</span>
                            </label>
                            <input id="input-address" class="form-control form-control-alternative"
                              placeholder="Address" value="" type="text" formControlName="address">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-country">Country
                              <span class="text-danger font-weight-500">*</span>
                            </label>
                            <select class="form-control form-control-alternative" name="selectAction"
                              (change)="onCountryChange($event)" formControlName="countyId">
                              <option *ngFor="let op of country" [value]="op.id">
                                {{op.name}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-city">State
                              <span class="text-danger font-weight-500">*</span>
                            </label>
                            <select class="form-control form-control-alternative" name="selectAction"
                              formControlName="stateId">
                              <option *ngFor="let op of state" [value]="op.id">
                                {{op.name}}
                              </option>
                            </select>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-country">City
                              <span class="text-danger font-weight-500">*</span>
                            </label>
                            <input type="text" id="input-city" class="form-control form-control-alternative"
                              placeholder="City" value="New York" formControlName="city">
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-country">Postal code
                              <span class="text-danger font-weight-500">*</span>
                            </label>
                            <input type="text" id="input-postal-code" class="form-control form-control-alternative"
                              placeholder="Postal code" formControlName="postCode" [maxlength]="12">
                              <div class="font-italic" *ngIf="registerForm.controls['postCode'].touched && registerForm.controls['postCode'].errors && registerForm.controls['postCode'].errors['pattern']">
                                <small class="text-danger font-weight-700"> 
                                      Invalid Postal code.
                                </small>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-12">
                <div class="card ">
                  <div class="border-bottom">
                    <!-- <h5 class="mb-0">Credentials</h5> -->
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label" for="input-username4">Password
                            <span class="text-danger font-weight-500">*</span>
                          </label>
                          <input type="password" id="input-username4" class="form-control form-control-alternative"
                            formControlName="password">
                        </div>
                        <div class="font-italic d-flex">
                         
                          <small> <password-strength [passwordToCheck]="registerForm.value.password"
                              (passwordStrength)="passwordValid($event)"></password-strength></small>
                        </div>

                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label" for="input-email5">Confirm Password
                            <span class="text-danger font-weight-500">*</span>
                          </label>
                          <input type="password" id="input-email5" class="form-control form-control-alternative"
                            formControlName="check">
                        </div>
                        <div class="font-italic">
                          <label class="form-check-label">Password:&nbsp;&nbsp;</label>
                          <small>
                            <span *ngIf="registerForm.errors && registerForm.errors['match_error']"> <span
                                class="text-danger font-weight-700">Not Matched</span></span>
                            <span *ngIf="!(registerForm.errors && registerForm.errors['match_error'])"> <span
                                class="text-success font-weight-700">matched</span></span>
                          </small>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <!-- <div class="mb-3">
                  <input type="checkbox" class="form-check-input" id="rememberCheck">
                  <label class="form-check-label" for="rememberCheck">Keep me signed in</label>
                </div> -->
                <div class="mb-3">
                  <input type="checkbox" class="form-check-input" id="iAgree" formControlName="iAgree">
                  <label class="form-check-label" for="iAgree">I agree with the <a  [routerLink]="['/privacy-policy']">Privacy Policy</a></label>
                </div>
                <div>
                  <button type="submit" class="btn btn-primary w-100 mb-0"
                    [disabled]="!registerForm.valid || !passwordStrengthValid || !registerForm.value.iAgree || loading"
                    (click)="onSubmit()">
                    <span *ngIf="!loading"> Sign up</span>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                      *ngIf="loading"></span>
                  </button>
                </div>
              </div>
              <!-- <div class="position-relative my-4">
                <hr>
                <p class="small position-absolute top-50 start-50 translate-middle bg-mode px-1 px-sm-2">Or sign in with
                </p>
              </div>

              <div class="vstack gap-3">
                <a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-google text-google-icon me-2"></i>Sign in
                  with Google</a>
              </div> -->

            </form>
          </div>         
        </div>
      </div>
    </div>
  </div>
  </section>
  <section class=" mt-5"  *ngIf="signUpCompleted">
  <div class="container">
		<div class="row">
			<div class="col-md-8 text-center mx-auto">
        <img class="pt-5" src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/signup-success.png" class="" alt="Signup Success"  style="height:12rem">
				<h5 class="pt-3">Congratulations your account has been created successfully.</h5>
         <h6 style="color: green;">Please verify your email and enjoy our services.</h6>

				<a class="btn btn-primary-soft mb-5 me-1" [routerLink]="['/login']">Login</a>
			</div>
		</div>
	</div>
</section>