import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gutter-report',
  templateUrl: './gutter-report.component.html',
  styleUrls: ['./gutter-report.component.scss']
})
export class GutterReportComponent {
  constructor(private title: Title, private meta: Meta, private router: Router) {
    this.title.setTitle("Gutter Length Reports USA, Canada - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "These are for the installation process of gutter systems on residential properties only." })
    this.meta.updateTag({ name: 'keywords', content: "Gutter Length Report,Gutter Length Report USA,Gutter Length Report Canada" })

  }

  orderNow(type: string) {
    this.router.navigateByUrl('/order-now', { state: { search: '', reportType: type } });
  
  }
  
  downlaodPDF(){
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href ='assets/downloads/gutter-sample-report.pdf'
    link.download = 'gutter-sample-report';
    link.click();
    link.remove();
  }
}
