

<!-- =======================
Faqs START -->
<section class="pt-0 pt-lg-5">
	<div class="container">
		<!-- Title -->
		<div class="row">
			<div class="col-12 text-center mb-4">
				<h2 class="mb-0">Frequently Asked Questions</h2>
			</div>
		</div>

		<!-- Accordion START -->
		<div class="row">
			<div class="col-10 mx-auto">
				<div class="accordion accordion-icon accordion-bg-light" id="accordionExample2">
					<!-- Item -->
					<div class="accordion-item mb-3">
						<h6 class="accordion-header font-base" id="heading-1">
							<button class="accordion-button fw-bold rounded d-inline-block collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
								Why choose us (our reports) over others?
							</button>
						</h6>
						<!-- Body -->
						<div id="collapse-1" class="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionExample2">
							<div class="accordion-body mt-3">
								<p>You should consider us because:</p>
								<ul class="list-block">
									<li><strong>Premium quality reports: </strong>All our reports are of premium quality which can be presented on any platform to impress  your clients and close the deal in your favour.</li>
									<li><strong>Most accurate roof reporting solution: </strong>We provide the most accurate (95% and above accuracy) roof reporting. We don’t make any errors but if the client is not satisfied with our reports we can redo those reports as per their requests.</li>
									<li><strong>Affordable roof reporting: </strong>Our reports are budget-friendly, affordable and easy to use. We believe in giving our clients the best value for money for the ordered reports.</li>
								</ul>	
							</div>
						</div>
					</div>

					<!-- Item -->
					<div class="accordion-item mb-3">
						<h6 class="accordion-header font-base" id="heading-2">
							<button class="accordion-button fw-bold rounded d-inline-block collapsed d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
								What is included in my report?
                          
							</button>
						</h6>
						<!-- Body -->
						<div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionExample2">
							<div class="accordion-body mt-3">
								<p>Your 11 page report contains the following items: </p>
								<ul class="list-block">
									<li>Unique order ID number</li>
									<li>Your company information including, company name, address, phone number and email contact. Your company logo can also be included if you upload the graphic to your account.</li>
									<li>Your customer information including the customer name and property information.</li>
									<li>The claim no., date of loss, and/or reference no. if applicable (provided)</li>
									<li>The latitude and longitude of the property</li>
									<li>Above image of the property/ roof(s)</li>
									<li>3D view drawings (all four sides)</li>
									<li>Star rating (showing the level of accuracy of the report)</li>
									<li>Measurement summary including total area of the roof, pitched area, flat area, primary pitch of the roof, total ridge length, total hip length, total valley length, total rake length, total eave length, total apron flashing length, total step flashing length, Waste factor table with 1%,2%, 5%, 8%, 10%, 12% 15%, 18%, 20%</li>
									<li>Length measurements diagram with measurements rounded to the nearest foot, Area measurements diagram with the area of each roof plane shown Pitch measurements diagram with the pitch of each roof plane shown Labels Diagram along with color for showing different pitch (please download sample report for further reference) </li>
									<li>Page showing obstructions on the roof (dish antenna, skylights etc.)(Optional).</li>
									<li>Link to Online map of the property.</li>
								</ul>	
							</div>
						</div>
					</div>

					<!-- Item -->
					<div class="accordion-item mb-3">
						<h6 class="accordion-header font-base" id="heading-3">
							<button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
								What is the turnaround time on my report?
							</button>
						</h6>
						<!-- Body -->
						<div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordionExample2">
							<div class="accordion-body mt-3">
								<p>The turnaround time for a commercial or residential report is 12 to 48 hours depends on complexity of the roof. </p>
							</div>
						</div>
					</div>

					<!-- Item -->
					<div class="accordion-item mb-3">
						<h6 class="accordion-header font-base" id="heading-4">
							<button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
								What is the format of my report?
							</button>
						</h6>
						<!-- Body -->
						<div id="collapse-4" class="accordion-collapse collapse" aria-labelledby="heading-4" data-bs-parent="#accordionExample2">
							<div class="accordion-body mt-3">
								<p>PDF, 3D CAD File, XML and HTML.</p>
							</div>
						</div>
					</div>

					<!-- Item -->
					<div class="accordion-item mb-3">
						<h6 class="accordion-header font-base" id="heading-5">
							<button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
								What does an oblique image mean?
							</button>
						</h6>
						<!-- Body -->
						<div id="collapse-5" class="accordion-collapse collapse" aria-labelledby="heading-5" data-bs-parent="#accordionExample2">
							<div class="accordion-body mt-3">
								<p>Oblique images are simply the directional (north, east, south, and west) views of the house taken at a 45 angle. These images play an integral part in determining the roof pitch /pitches and constructing the 3 dimensional model. </p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<!-- Accordion END -->
	</div>
</section>
<!-- =======================
Faqs END -->

