<section class="pt-0" #pricing id="pricing">
	<div class="container">
		<!-- Title -->
		<div class="row mb-4 mb-sm-5">
			<div class="col-12 text-center">
				<!-- <h2 class="mb-0">Pricing</h2> -->
				<h2>Per <span class="text-primary">Report Pricing</span></h2>
			</div>
		</div>

		<div class="row g-4">
			<!-- Card item -->
			<div class="col-sm-6 col-lg-4 col-xl-3">
				<div class="card shadow h-100">
					<div class="position-relative position-relative overlay-container">
						<!-- Image -->
						<!-- <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/pricing/residential.webp"
							class="card-img-top" alt="Card image"> -->

							<a [routerLink]="['/residential-report']" target="_blank">
								<img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/pricing/residential.webp"
								class="card-img-top" alt="Card image">
								<div class="overlay">More info.....</div>
	
							</a>

					</div>

					<!-- Card body START -->
					<div class="card-body">
						<!-- Title -->
						<!-- <h5 class="card-title me-2" style="text-align: center;">Residential Roof <br>Report</h5> -->
						<h5 class="card-title me-2" style="text-align: center;">
							<a class="residential-link"
							target="_blank" [routerLink]="['/residential-report']">Residential Roof <br>Report</a> </h5>
						<div class="report-price">
							<h6 class="mb-0">Starts</h6>
							<div class="hstack gap-1">
								<span class="plan-price-cancel mb-0 strikediag withpadding">$25</span>
								<span class="h5 plan-price mb-0">$20</span>
								<span class="h6 fw-light mb-0">/Report</span>
							</div>
						</div>

					</div>
					<!-- Card body END -->

					<!-- Card footer START-->
					<div class="card-footer border-top">
						<!-- Buttons -->
						<a (click)="orderNow('residential')" class="btn btn-dark w-100 mb-0">Order Now</a>
						<a [routerLink]="['/residential-report']" target="_blank" class="btn btn-dark w-100 mb-0 mt-2"
						*ngIf="isMobile">More Info</a>
					</div>
				</div>
			</div>

			<!-- Card item -->
			<div class="col-sm-6 col-lg-4 col-xl-3">
				<div class="card shadow h-100">
					<div class="card-img-scale position-relative overlay-container">
						<!-- Image -->
						
						<a [routerLink]="['/commercial-report']" target="_blank">
							<img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/pricing/commercial.webp"
							class="card-img-top" alt="Card image">
							<div class="overlay">More info.....</div>

						</a>

					</div>

					<!-- Card body START -->
					<div class="card-body">
						<!-- Title -->
						<h5 class="card-title me-2" style="text-align: center;">
						<a class="commercial-link"
						target="_blank" [routerLink]="['/commercial-report']">Commercial Roof <br>Report</a> </h5>
						<div class="report-price">
							<h6 class="mb-0">Starts</h6>
							<div class="hstack gap-1">
								<span class="plan-price-cancel mb-0 strikediag withpadding">$50</span>
								<span class="h5 plan-price mb-0">$45</span>
								<span class="h6 fw-light mb-0">/Report</span>
							</div>
						</div>

					</div>
					<!-- Card body END -->

					<!-- Card footer START-->
					<div class="card-footer border-top">
						<!-- Buttons -->
						<a (click)="orderNow('Commercial')" class="btn btn-dark w-100 mb-0">Order Now</a>
						<a [routerLink]="['/commercial-report']" target="_blank" class="btn btn-dark w-100 mb-0 mt-2"
						*ngIf="isMobile">More Info</a>
					</div>
				</div>
			</div>

			<!-- Card item -->
			<div class="col-sm-6 col-lg-4 col-xl-3">
				<div class="card shadow h-100">
					<div class="position-relative card-img-scale overlay-container">
						<!-- Image -->
						<a [routerLink]="['/blue-print-report']" target="_blank">
							<img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/pricing/blueprint.webp"
								class="card-img-top image" alt="Card image">
							<div class="overlay">More info.....</div>

						</a>
					</div>

					<!-- Card body START -->
					<div class="card-body">
						<!-- Title -->
						<h5 class="card-title me-2" style="text-align: center;"><a class="blueprint-link"
								target="_blank" [routerLink]="['/blue-print-report']">Blueprint Roof <br>Report</a></h5>

						<div class="report-price">
							<h6 class="mb-0">Starts</h6>
							<div class="hstack gap-1">
								<span class="plan-price-cancel mb-0 strikediag withpadding">$40</span>
								<span class="h5 plan-price mb-0">$30</span>
								<span class="h6 fw-light mb-0">/Report</span>
							</div>
						</div>

					</div>
					<!-- Card body END -->

					<!-- Card footer START-->
					<div class="card-footer border-top">
						<!-- Buttons -->
						<a (click)="orderNow('blueprint')" class="btn btn-dark w-100 mb-0">Order Now</a>
						<a [routerLink]="['/blue-print-report']" target="_blank" class="btn btn-dark w-100 mb-0 mt-2"
							*ngIf="isMobile">More Info</a>

					</div>
				</div>
			</div>


			<!-- Card item -->
			<div class="col-sm-6 col-lg-4 col-xl-3">
				<div class="card shadow h-100">
					<div class="position-relative position-relative overlay-container">
						<!-- Image -->
					
							<a [routerLink]="['/gutter-report']" target="_blank">
								<img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/pricing/gutter.webp"
									class="card-img-top image" alt="Card image">
								<div class="overlay">More info.....</div>
</a>
					</div>

					<!-- Card body START -->
					<div class="card-body">
						<!-- Title -->
						<!-- <h5 class="card-title me-2" style="text-align: center;">Gutter Length <br>Report</h5> -->
						<h5 class="card-title me-2" style="text-align: center;"><a class="gutter-link"
							target="_blank" [routerLink]="['/gutter-report']">Gutter Roof <br>Report</a></h5>

						<div class="report-price">
							<h6 class="mb-0">Starts</h6>
							<div class="hstack gap-1">
								<span class="plan-price-cancel mb-0 strikediag withpadding">$20</span>
								
								<span class="h5 plan-price mb-0">$15</span>
								<span class="h6 fw-light mb-0">/Report</span>
							</div>
						</div>

					</div>
					<!-- Card body END -->

					<!-- Card footer START-->
					<div class="card-footer border-top">
						<!-- Buttons -->
						<a (click)="orderNow('gutter')" class="btn btn-dark w-100 mb-0">Order Now</a>
						<a [routerLink]="['/gutter-report']" target="_blank" class="btn btn-dark w-100 mb-0 mt-2"
						*ngIf="isMobile">More Info</a>
					</div>
				</div>
			</div>



		</div>
	</div>
</section>