
	<div class="container">
		<div class="row">
			<div class="col-md-8 text-center mx-auto">
				<!-- SVG decoration START -->
				<img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/payment-success.svg" class="" alt="Payment Success" *ngIf="isSuccess" style="height:12rem">
                <img src="https://ik.imagekit.io/krlinc/assets/images/finalized-images/payment-failed.svg" class="" alt="Payment Failed" *ngIf="!isSuccess" style="height:12rem">
				
				<!-- SVG decoration END -->
				
				<!-- Title -->
				<h5 *ngIf="isSuccess">Congratulations your order has been placed. 🎉</h5>
				<h5 *ngIf="paymentErrorTitle">{{paymentErrorTitle}}</h5>
				<!-- Info -->
				<p *ngIf="isSuccess">{{paymentSuccessMessage}}</p>
                <p *ngIf="!isSuccess">{{paymentErrorMessage}}</p>

				<a class="btn btn-primary-soft mb-5 me-1" [routerLink]="['/my-orders']">My Orders</a>
				<a *ngIf="!isSuccess" class="btn btn-primary-soft mb-5 me-1" [routerLink]="['/cart']">Cart</a>
				<a class="btn btn-light mb-5" [routerLink]="['/']">Homepage</a>
			</div>
		</div>
	</div>