import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { User } from '@app/_models/user';
import { ProfileService, StorageService, USER, UserService } from '@app/_services';
import { CountryService } from '@app/_services/country.service';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent {
  public country: any[] = [];
  public state: any = [];
  public updateProfileForm: FormGroup;
  public passwordStrengthValid: boolean = false;
  public loading: boolean = false;
  public profile: any;
  public errorMsg='';
  public successMsg='';
  constructor(private countryService: CountryService, fb: FormBuilder, private storageService: StorageService, private profileService: ProfileService,
    public userService: UserService,private title: Title, private meta: Meta) {
      this.title.setTitle("My Profile - KRL INC.");
        this.meta.updateTag({ name: 'description', content: "" })
    this.updateProfileForm = fb.group({
      secondaryEmail: fb.control(''),
      phoneNumber: fb.control('',[Validators.pattern('[- +()0-9]*$')]),
      address: fb.control('', [Validators.required]),
      city: fb.control('', [Validators.required]),
      countyId: fb.control('', [Validators.required]),
      postCode: fb.control('',  [Validators.required, Validators.pattern('^[0-9]*$')]),
      stateId: fb.control('', [Validators.required]),
      company: fb.control(''),
      firstName: fb.control('', [Validators.required]),
      lastName: fb.control('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.countryService.getCountryAndState().subscribe((data: any) => {
      this.country = data;
      this.state = data[0].states;
      
      this.populateProfileData();
    });
    const user = this.storageService.getLocalSt(USER) as User;
    this.profile = user.data?.user;

  }

  private populateProfileData() {
    this.updateProfileForm.controls['firstName'].setValue(this.profile.firstName);
    this.updateProfileForm.controls['lastName'].setValue(this.profile.lastName);
    this.updateProfileForm.controls['address'].setValue(this.profile.address);
    this.updateProfileForm.controls['city'].setValue(this.profile.city);
    this.updateProfileForm.controls['company'].setValue(this.profile.company);
    this.updateProfileForm.controls['secondaryEmail'].setValue(this.profile.secondaryEmail);
    this.updateProfileForm.controls['phoneNumber'].setValue(this.profile.phoneNumber);
    this.updateProfileForm.controls['address'].setValue(this.profile.address);
    this.updateProfileForm.controls['countyId'].setValue(this.profile.countyId);
    this.updateProfileForm.controls['stateId'].setValue(this.profile.stateId);
    this.updateProfileForm.controls['postCode'].setValue(this.profile.postCode);
    this.state = this.country.find(data => data.id === parseInt(this.profile.countyId)).states;
    this.updateProfileForm.controls['stateId'].setValue(this.profile.stateId);
  }
  onCountryChange(event: any) {
    this.state = [];
    if (event?.target?.value) {
      this.state = this.country.find(data => data.id === parseInt(event.target.value)).states;
      this.updateProfileForm.controls['stateId'].setValue(this.state[0].id);
    }
  }

  get f(): any { return this.updateProfileForm.controls; }

  onSubmit() {
    this.updateProfileForm.controls['firstName'].setValue(this.updateProfileForm.value.firstName.trim())
    this.updateProfileForm.controls['lastName'].setValue(this.updateProfileForm.value.lastName.trim())
    this.updateProfileForm.controls['address'].setValue(this.updateProfileForm.value.address.trim())
    this.updateProfileForm.controls['city'].setValue(this.updateProfileForm.value.city.trim())
    if (this.updateProfileForm.invalid) return
    this.loading = true;
    this.errorMsg='';
    this.successMsg='';
    this.profileService.updateProfile(this.updateProfileForm.value).subscribe({
      next: data => {
        if (data.status) {
          let _user = this.storageService.getLocalSt(USER) as User;
          const profileData = {
            ...this.updateProfileForm.value,
            email: this.profile.email
          };
          let userData = _user.data;
          if (userData) {
            userData['user'] = profileData
          }
          _user.data = userData
          this.storageService.setLocalSt(USER, _user)
          this.profile=profileData;
          this.populateProfileData()
          this.successMsg=data.message;
        }else{
          this.errorMsg=data.message;
        }
        this.loading = false;
      },
      error: err => {
        console.log(err);
        this.errorMsg=err;
        this.loading = false;
      }
    })


  }
}
