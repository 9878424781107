import { Component, ElementRef, ViewChild } from '@angular/core';

import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MapGeocoder } from '@angular/google-maps';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utill } from '@app/_helpers/utill';
import { Title, Meta } from '@angular/platform-browser';
declare function scrollToHomePage(element: any): any;

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    loading = false;
    users?: User[];
    latLong: string = '';
    locationForm: FormGroup;
    submitted: boolean = false;
    isLoading: boolean = false;
    @ViewChild('coordinates')
    coordinates!: ElementRef;

    constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router, private aRouter: ActivatedRoute, private geocoder: MapGeocoder, private utill: Utill,private title: Title, private meta: Meta) {
        this.title.setTitle("KRL INC - Accurate Aerial Roof Measurement Reports USA, Canada");
        this.meta.updateTag({ name: 'description', content: "KRL INC. provides the best most accurate and most affordable aerial roof reports in USA and Canada. Our expert team uses satellite imagery, state-of-the-art technology, and industry-leading practices to provide detailed and reliable reports." })
        this.meta.updateTag({ name: 'keywords', content: "Satellite measured roof reports,Best aerial roof reports,Accurate roof reports, roof report service in US" })

        const obj: any = this.aRouter.snapshot.params;
        this.locationForm = this.formBuilder.group({
            address: ['', Validators.required],
            latLong: [''],
            marker: ['']
        });

        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                let scrollIntoView = this.aRouter.snapshot.paramMap.get('scrollItem');
                scrollToHomePage(scrollIntoView);
            }
        });

        // this.locationForm?.get("address")?.valueChanges.subscribe(x => {
        //     this.processEnteredAddress(x);
        //     this.resetUpdate();
        // });

        // this.locationForm?.get("latLong")?.valueChanges.subscribe((latlong:string) => {
        //    this.processEnteredLatlong(latlong);
        //    this.resetUpdate();
        // })
    }

    resetUpdate(){
        setTimeout(()=> {
            this.isLoading = false;
        }, 1000)
    }

    processEnteredAddress(address: string) {
        if(this.isLoading){
            return;
        }
        this.isLoading = true;
        this.geocoder.geocode({ address }).subscribe((data: any) => {
            if (data?.results.length > 0) {
                this.coordinates?.nativeElement?.focus();
                this.locationForm.controls['marker'].setValue(data?.results[0].geometry.location.toJSON());
                this.locationForm.controls['latLong'].setValue(data?.results[0].geometry.location.toJSON().lat + ', ' + data?.results[0].geometry.location.toJSON().lng);
            }else{
                this.locationForm.controls['address'].setErrors({unknownError: 'Entered address is invalid/unavailable'});
                this.isLoading =false
            }
        });
    }
    public AddressChange(address: any) {
       
        this.locationForm.patchValue({ address: address.formatted_address });
      
      }
    processEnteredLatlong(latlong:string){
        if(this.isLoading){
            return;
        }
        this.isLoading = true;
       
        var latlng = this.utill.getLatlongFromString(latlong);
        
        this.geocoder.geocode({ location: latlng }).subscribe((data: any) => {
            if (data?.results.length > 0) {
                this.locationForm.controls['marker'].setValue(latlng);
                this.locationForm.controls['address'].setValue(data?.results[0].formatted_address);
            }else{
                this.locationForm.controls['latLong'].setErrors({unknownError: 'Entered latlong is invalid/unavailable'});
                this.isLoading =false
            }
        })
    }
    
    get f(): any { return this.locationForm.controls; }

    ngOnInit() {

    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    public addressChange(event: any) {
        this.isLoading = false;
        this.locationForm.controls['address'].setValue(event.formatted_address)
    }

    onSubmit() {
        this.submitted = true;
        if(this.locationForm.value.address || this.locationForm.value.latLong ){
            this.router.navigateByUrl('/order-now', { state: {search:this.locationForm.value, reportType:'' }});
        }else{
            this.locationForm.markAllAsTouched();
        }
    }
}