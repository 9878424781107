<main>
    <section class="pt-3">
        <div class="container">
            <div class="row g-2 g-lg-4">
                <div class="col-lg-12 col-xl-12 ps-xl-5">
                    <div class="card border bg-transparent">

                        <div class="card-body p-0">
                            <ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
                                <li class="nav-item" *ngIf="activeTab!='payment_status'">
                                    <a class="nav-link mb-0 " [ngClass]="{'active': activeTab === 'cart'}"
                                        [routerLink]="['/cart']" id="tab1-link"><i
                                            class="{{app_icons.CART}}"></i>Cart</a>
                                </li>
                                <li class="nav-item" *ngIf="activeTab!='payment_status' && cart.length>0">
                                    <a class="nav-link mb-0" id="tab2-link"
                                        [ngClass]="{'active':  activeTab === 'checkout'}"
                                        [routerLink]="['/checkout']"><i
                                            class="{{app_icons.BILLING}}"></i>&nbsp;&nbsp;Checkout</a>
                                </li>
                                <li class="nav-item" *ngIf="activeTab ==='payment_status'">
                                    <a class="nav-link mb-0" id="tab3-link"
                                        [ngClass]="{'active': activeTab === 'payment_status'}"><i
                                            class="{{app_icons.BILLING}}"></i>&nbsp;&nbsp;Payment Status</a>
                                </li>
                            </ul>

                            <div class="tab-content p-2 p-sm-4" id="nav-tabContent">

                                <!-- Tab1 [Cart] -->
                                <div class="tab-pane fade" [ngClass]="{'show active': (activeTab === 'cart')}"
                                    id="tab-1">
                                    <app-loader *ngIf="isLoading" [message]="'Please wait, while we are loading your items from your cart.'"></app-loader>
                                    <div *ngIf="!isLoading && cart.length>0">
                                        <app-product-card-list [cart]="cart" [products]="products"
                                            [fromComponent]="activeTab"></app-product-card-list>
                                    </div>


                                    <div *ngIf="!isLoading && cart.length===0">
                                        <app-no-data-found-placeholder [show_404]="false"
                                            [message]="cart_messages.cart_empty"
                                            [subText]="cart_messages.cart_empty_submessage"
                                            [buttonActionURL]="'/order-now'"
                                            [buttonText]="'Order Now'"></app-no-data-found-placeholder>
                                    </div>

                                    <div class=" card-footer" *ngIf="!isLoading && cart.length>0">
                                        <div class=" action-block ">
                                            <div class="mt-2">
                                                <a class="btn btn-primary" [routerLink]="['/order-now']">
                                                    <li class="fa-hotel fa-solid"></li>&nbsp;&nbsp;Add Another Item
                                                </a>
                                                &nbsp;
                                                <a class="btn btn-primary"
                                                    [routerLink]="['/checkout']"><i
                                                        class="{{app_icons.BILLING}}"></i>&nbsp;&nbsp;Checkout</a>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>

                                <!-- Tab2 [Checkout]-->
                                <div class="tab-pane fade" [ngClass]="{'show active': (activeTab === 'checkout')}"
                                    id="tab-2">
                                    <app-loader *ngIf="isLoading" [message]="loadingMessage"></app-loader>

                                    <ng-container *ngIf="!isLoading && (activeTab === 'checkout')">
                                        <div *ngIf="checkoutCart.length>0">
                                            <app-product-card-list [cart]="checkoutCart" [products]="products"
                                                [fromComponent]="activeTab"></app-product-card-list>
                                        </div>
                                        <div *ngIf="checkoutCart.length===0">
                                            <app-no-data-found-placeholder [show_404]="false"
                                                [message]="cart_messages.cart_empty"
                                                [subText]="cart_messages.cart_empty_submessage"
                                                [buttonActionURL]="'/order-now'"
                                                [buttonText]="'Order Now'"></app-no-data-found-placeholder>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="checkoutCart.length>0">
                                        <!-- Fair & Amount -->
                                        <div class="col-md-12 col-xl-12">
                                            <div class="card bg-light rounded-2">
                                                <!-- card header -->
                                                <div class="card-header border-bottom bg-light">
                                                    <h5 class="card-title mb-0">Fare Summary</h5>
                                                </div>

                                                <!-- Card body -->
                                                <div class="card-body">
                                                    <ul class="list-group list-group-borderless">
                                                        <li
                                                            class="list-group-item d-flex justify-content-between align-items-center">
                                                            <span class="h6 fw-normal mb-0">Base Fare
                                                                <!-- <a href="#" tabindex="0" data-bs-toggle="popover" data-bs-trigger="focus" data-bs-placement="bottom" data-bs-content="COVID-19 test required Vaccinated travelers can visit">
                                                                    <i class="bi bi-info-circle"></i>
                                                                </a> -->
                                                            </span>
                                                            <span class="fs-5">${{total}}</span>
                                                        </li>
                                                        <!-- <li class="list-group-item d-flex justify-content-between align-items-center">
                                                            <span class="h6 fw-normal mb-0">Discount</span>
                                                            <span class="fs-6 text-success">+$ 0</span>
                                                        </li>
                                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                                            <span class="h6 fw-normal mb-0">Other Services</span>
                                                            <span class="fs-5">$0</span>
                                                        </li> -->
                                                    </ul>
                                                </div>

                                                <!-- Card footer -->
                                                <div class="card-footer border-top bg-light">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="h5 fw-normal mb-0">Total Fare</span>
                                                        <span class="h5 fw-normal mb-0">${{total}}</span>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <!-- Fare summary END -->

                                        <!-- Coupon START -->
                                        <!-- <div class="col-md-6 col-xl-12">
                                            <div class="card card-body bg-light">
                                                <div class="cardt-title">
                                                    <h5>Offer & Discount</h5>
                                                </div>
                                                <div class="input-group mt-2">
                                                    <input class="form-control form-control" placeholder="Coupon code">
                                                    <button type="button" class="btn btn-primary">Apply</button>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="payment-columns mt-4">
                                            <div id="test2"></div>
                                            <div class="payment-btn-sec"><ngx-paypal  class="mb-0 paypal_config" [config]="payPalConfig" name="test1" id="test1"></ngx-paypal></div>
                                            <div class=""></div>
                                        </div>
                                     
                                        
                                    </ng-container>
                                </div>

                                <!-- Tab3 [Payment Status] -->
                                <div class="tab-pane fade"
                                    [ngClass]="{'show active':  (activeTab === 'payment_status')}" id="tab-3">
                                    <app-loader *ngIf="isLoading"  [message]="'Please wait, your payment is under process!'"></app-loader>
                                    <ng-container *ngIf="!isLoading && showPaymentStatus">
                                        <app-payment-status-page [isSuccess]="paymentStatus === PAYMENT_STATUS.SUCCESS"
                                            [paymentErrorTitle]="paymentErrorTitle"
                                            [paymentErrorMessage]="paymentErrorMeessage"></app-payment-status-page>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- Card body END -->
                    </div>

                </div>
            </div>
        </div>
    </section>
</main>