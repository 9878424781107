import { Component } from '@angular/core';
import { APP_ICONS } from '@app/_services';

@Component({
  selector: 'app-mobile-bottom-navigation',
  templateUrl: './mobile-bottom-navigation.component.html',
  styleUrls: ['./mobile-bottom-navigation.component.scss']
})
export class MobileBottomNavigationComponent {
  public app_icons = APP_ICONS;
}