<!-- =======================
Privacy Policy content START -->
<section class="pt-0 pt-lg-5">
    <div class="container">
        <!-- Title -->
        <div class="row mb-3 mb-sm-4">
            <div class="col-12 text-center">
                <h1>Terms and Conditions</h1>
            </div>
        </div>
        <!-- Content START -->
        <div class="row mb-4 mb-md-5">
            <div class="col-md-10 mx-auto">
                 <h5 class="mt-4">Introduction:</h5>
                <p class="mb-10 text-justify">These terms and conditions govern your use of the <a href="https://krlinc.com"><strong class="text-primary">KRL INC</strong></a> and the
                    services provided by <strong class="text-primary">KRL INC</strong> (the &quot;ROOF REPORTS&quot;). By accessing or using the website and Services,
                    you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions,
                    you should not use the website or Services.</p>
                    <h5 class="mt-4">Services:</h5>
                <p class="mb-10 text-justify">KRL INC provides roofing inspection and reporting services. We will inspect your roof from
                    available satellite images and provide you with a detailed report of all measurements, such as roof area
                    segment lengths, Roof pitch, etc.</p>
                    <h5 class="mt-4">Payment:</h5>
                <p class="mb-10 text-justify">Payment for our services must be made in advance. We accept payment by credit card, or
                    debit card via the secured payment partner PayPal.</p>


                <h5 class="mt-4">Cancellation and Refunds:</h5>
                <p class="mb-10 text-justify">If you need to cancel an Ordered Report, please contact us as soon as
                    possible. We will refund the full amount if the report is not processed and emailed back to you.</p>
                    <h5 class="mt-4">Inspection and Reporting:</h5>
                <p class="mb-10 text-justify">We will make every effort to provide a comprehensive and accurate
                    inspection and report. However, we cannot guarantee that every issue or problem with your roof will be
                    identified. We completely depend on available satellite images and have partnered with major satellite
                    imagery providers from USA and Canada. But sometimes due to the unavailability of satellite imagery
                    accuracy of reports can be compromised and that will be clearly informed to you in the report delivery
                    email. Our inspection and report are intended to provide a general overview of the measurements of
                    your roof, and should not be relied upon as a substitute for a professional opinion.</p>


                <h5 class="mt-4">Limitation of Liability:</h5>
                <p class="mb-10 text-justify"><strong class="text-primary">KRL INC</strong> shall not be liable for any damages arising from the use of our website or
                    services. This includes, but is not limited to, direct, indirect, special, incidental, or consequential
                    damages.</p>
                    <h5 class="mt-4">Changes to Terms and Conditions:</h5>
                <p class="mb-10 text-justify">We reserve the right to make changes to these terms and conditions
                    at any time. Your continued use of the website and services following any changes indicates your
                    acceptance of the new terms and conditions.</p>


                <h5 class="mt-4">Governing Law:</h5>
                <p class="mb-10 text-justify">These terms and conditions shall be governed and construed in accordance with the
                    laws of [Country/State], without giving effect to any principles of conflicts of law.</p>

                <p class="mb-10 text-justify">The roof reports produced by <strong class="text-primary">KRL INC</strong> are proprietary and confidential. They are intended for the use of
                    the customer who commissioned the inspection and report, and are not to be shared or distributed to
                    any third party without the express written consent of <strong class="text-primary">KRL INC</strong></p>

                    <p class="mb-10 text-justify">Redistributing, sharing, or otherwise disclosing the contents of a roof report without our permission is
                        strictly prohibited and in violation of our terms and conditions. This includes, but is not limited to,
                        sharing the report outside your organization or to 3 rd party vendors.</p>
                        <p class="mb-10 text-justify">Any unauthorized distribution/redistribution of a roof report may result in legal action and may also
                            result in the revocation of the customer&#39;s right to use the report.</p>
                            <p class="mb-10 text-justify"><strong class="text-primary">KRL INC</strong> reserves the right to take any necessary action to protect its intellectual property, including
                                legal action against any party found to be in violation of this prohibition.</p>
                                <p class="mb-10 text-justify">By using the services of <strong class="text-primary">KRL INC</strong>, you agree to abide by this prohibition of redistribution of roof reports
                                    and the terms and conditions outlined in our agreement.</p>

                <h5 class="mt-4">Account Termination Policy:</h5>
                <p class="mb-10 text-justify">
                    <strong class="text-primary">KRL INC</strong> reserves the right to terminate a customer&#39;s account at any time and for any reason. Reasons
                    for account termination may include, but are not limited to, the following:
                
                </p>
                <ul>
                    <li>Violation of our terms and conditions, such as unauthorized redistribution of roof reports.</li>
                <li>Fraudulent or illegal activity.</li>
                <li>Abusive or offensive behavior towards our employees or other customers.</li>
                <li>Failure to pay for services rendered.</li>
                <li>Any other reason deemed appropriate by <strong class="text-primary">KRL INC</strong></li>
                </ul>
                <p>
                    If an account is terminated, all outstanding services will be immediately terminated and no refund will
                    be given. The customer will also lose access to any reports or other information related to their account.  
                </p>
                <p>
                    In the event that an account is terminated, <a href="https://krlinc.com"><strong class="text-primary">KRL INC</strong></a> reserves the right to take any necessary
                    legal action to recover damages incurred as a result of the customer&#39;s actions.  
                </p>
                <p>
                    We encourage customers to contact us if they have any concerns or issues with their account, so that
                    we can address them and prevent account termination.  
                </p>
                <h5 class="mt-4">Contact Us</h5>
                <p class="mb-10 text-justify">If you have any questions or concerns about these terms and conditions, please
                    <a [routerLink]="['/contact-us']" >contact us</a>   or email us at <a href="mailto:corporate@krlinc.com">corporate@krlinc.com</a></p>

                <p class="mb-10 text-justify">These terms and conditions were last updated on 28<sup>th</sup> August 2016 and are effective as of that date.</p>

            </div>
        </div>
        <!-- Content END -->


    </div>
</section>
<!-- =======================
Privacy Policy content END -->