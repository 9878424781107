<div class="navbar navbar-mobile">
	<ul class="navbar-nav">
		<!-- Nav item Home -->
		<li class="nav-item">
			<a class="nav-link" routerLinkActive="active"  [routerLink]="['/home']">
				<i class="fa-solid fa-house"></i>
				<span class="mb-0 nav-text">Home</span>
			</a>	
		</li>

		<!-- Nav item My Trips -->
		<li class="nav-item"> 
			<a class="nav-link" routerLinkActive="active"  [routerLink]="['/order-now']"><i class="{{app_icons.ORDER_NOW}}"></i>
				<span class="mb-0 nav-text">Order-Now</span>
			</a>	
		</li>

		<!-- Nav item Offer -->
		<li class="nav-item"> 
			<a class="nav-link" routerLinkActive="active"  [routerLink]="['/cart']" >
				<i class="{{app_icons.CART}}"></i>
				<span class="mb-0 nav-text">Cart</span> 
			</a>
		</li>

		<!-- Nav item Account -->
		<li class="nav-item"> 
			<a class="nav-link" routerLinkActive="active"  [routerLink]="['/my-profile']">
				<i class="fa-solid fa-user"></i>
				<span class="mb-0 nav-text">My Profile</span>
			</a>
		</li>
	</ul>
</div>