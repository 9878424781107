<!-- Alert box START -->
<!-- alert-warning -->
<!-- alert-info -->
<!-- alert-light -->
<div *ngIf="mode !== 'fixed-header' && message" class="alert alert-danger alert-dismissible d-flex justify-content-between align-items-center rounded-3 fade show mb-4 mb-0 pe-2"
    role="alert">
    <div class="d-flex">
        <span class="alert-heading h5 mb-0 me-2"><i class="bi bi-exclamation-octagon-fill"></i></span>
        <span><strong class="alert-heading me-2">Covid Policy:</strong>You may require to present an RT-PCR negative
            test report at the hotel</span>
    </div>
    <button type="button" class="btn btn-link pb-0 text-end" data-bs-dismiss="alert" aria-label="Close"><i
            class="bi bi-x-lg text-dark"></i></button>
</div>
<!-- Alert box END -->



<div *ngIf="mode === 'fixed-header' && message"  class="alert alert-warning py-2 m-0 w-80vh {{type}} border-0 rounded-4 alert-dismissible fade show text-center overflow-hidden" role="alert">
	<p class="text-white m-0">{{message}}</p>
	<!-- Close button -->
	<button type="button" class="btn-close btn-close-white opacity-9 p-3" data-bs-dismiss="alert" aria-label="Close" (click)="close()"></button>
</div>

