import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
@Component({
  selector: 'date-time-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DateTimePickerComponent {

  // https://github.com/mattlewis92/angularx-flatpickr/blob/main/projects/demo/app/demo.component.ts
  @Input() mode: 'single' | 'range' | 'multiple' = 'single';
  public selectedDate: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() dateDisplayFormat: string | undefined;
  @Input() dateFormat: string | undefined;
  @Input() timeFormat: string | undefined;
  @Input() timeDisplayFormat: string | undefined;
  @Input() minTime: string | undefined;
  @Input() maxTime: string | undefined;
  @Input() timePlaceholder: string | undefined;
  @Output() dateChange  = new EventEmitter();

  @Input() clearDate:number = 0;
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if (changes['clearDate'] && !changes['clearDate'].firstChange && changes['clearDate'].currentValue !==changes['clearDate'].previousValue) {
      this.selectedDate = null;
    }
  }
  changeDate(event:any){
    this.dateChange.emit(this.selectedDate);
  }
}
