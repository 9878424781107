import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { StorageService } from './storage.service';
import { CART, REMEMBER, USER } from '.';

const httpOptions = {
    headers: new HttpHeaders({
        //   'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Credentials': 'true',
        // 'Access-Control-Allow-Headers': 'Content-Type',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE' 
    })
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User | any>;
    public user: Observable<User | null>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private storageService: StorageService
    ) {
        this.userSubject = new BehaviorSubject((this.storageService.getLocalSt(USER) as User)!);
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(username: string, password: string, rememberMe?: boolean) {
        return this.http.post<any>(`${environment.apiUrl}/customer/signin`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('user', JSON.stringify(user));
                this.storageService.setLocalSt(USER, user);
                if(rememberMe){ 
                    this.storageService.setLocalSt(REMEMBER, username);
                }else{
                    this.storageService.removeItemFromLocalSt(REMEMBER);
                }
                this.userSubject.next(user);
                return user;
            }));
    }

    register(data: any): Observable<any> {
        return this.http.post(
            environment.apiUrl + '/customer/signup',
            data,
            httpOptions
        );
    }

    logout() {
        this.http.get(environment.apiUrl + '/customer/logout',httpOptions).subscribe((res:any)=>{
            console.log('logged out');
        },(error)=>{
            console.log('user unable to logged out',error);
        })
        this.storageService.removeItemFromLocalSt(USER);
        this.storageService.removeItemFromLocalSt(CART);
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    isUserLoggedIn(): boolean {
        return this.storageService.getLocalSt(USER) != null;
    }

    resendVerificationUrl(data:any){
        return this.http.get(
            environment.apiUrl + '/customer/resendverifyemail'+'?email='+data.email+'&emailVerificationUrl='+data.emailVerificationUrl 
        );
    }

    verifyCode(data:any){
        return this.http.get(
            environment.apiUrl + '/customer/verify'+'?code='+data.code
        );
    }
}