import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/_models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { USER } from '.';
import { StorageService } from './storage.service';

export const PAYMENT_STATUS = { SUCCESS: 'success', ERROR: 'cancel' }

@Injectable({
  providedIn: 'root'
})


export class CheckoutService {

  CHECKOUT_API = environment.apiUrl + '/checkout';
  VERIFY_PAYMENT_API = environment.apiUrl + '/checkout/capture';
  CANCELED_PAYMENT_API = environment.apiUrl + '/checkout/cancel';

  constructor(private storageService: StorageService, private http: HttpClient) { }

  checkout(): Observable<any> {
    const user = this.storageService.getLocalSt(USER) as User;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user?.data?.accessToken
      })
    };
    return this.http.post(
      this.CHECKOUT_API,{
        "returnUrl": environment.returnUrl,
        "cancelUrl": environment.cancelUrl,
      }
    );
  }

  checkoutV2(): Observable<any> {
    const user = this.storageService.getLocalSt(USER) as User;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user?.data?.accessToken
      })
    };
    return this.http.post(
      this.CHECKOUT_API+"/checkout",{}
    );
  }
  checkoutPay(url: string) {
    const user = this.storageService.getLocalSt(USER) as User;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + user?.data?.accessToken
      })
    };
    return this.http.get(
      url,
      httpOptions
    );
  }

  verifyPayment(token: String, payerId: string) {
    return this.http.get(
      this.VERIFY_PAYMENT_API + '?token=' + token + '&PayerID=' + payerId,
    );
  }

  verifyPaymentV2(token: String, status: string,amount:string,invoiceId?:string) {
    const user = this.storageService.getLocalSt(USER) as User;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user?.data?.accessToken
      })
    };
    return this.http.post(
      this.VERIFY_PAYMENT_API,{"token":token,"amount":amount,"status":status,"invoiceId":invoiceId}
    );
    
  }

  canceledPayment(token: String, payerId: string) {
    return this.http.get(
      this.CANCELED_PAYMENT_API + '?token=' + token + '&PayerID=' + payerId,
    );
  }
  delete(){
    
  }
}
