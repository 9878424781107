import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TYPE } from '@app/alert/alert.component';
import { AlertService, ProfileService, UserService } from '@app/_services';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public updatePasswordForm!: FormGroup;
  public passwordStrengthValid: boolean = false;
  public loading: boolean = false;
  error = '';
  successMsg = '';
  @Input() token?: any = '';
  isviewed:boolean = false;

  constructor(private fb: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    private alertService: AlertService,public userService: UserService,private title: Title, private meta: Meta) {
      this.title.setTitle("Change Password - KRL INC.");
      this.meta.updateTag({ name: 'description', content: "" })
    }

  passwordValid(event: any) {
    this.passwordStrengthValid = event;
  }

  ngOnInit() {
    if (this.token) {
      this.updatePasswordForm = this.fb.group({
        currentPassword: this.fb.control(null),
        newPassword: this.fb.control('', [Validators.required]),
        check: this.fb.control('', [Validators.required]),
        token: this.fb.control(''),
      });
    } else {
      this.updatePasswordForm = this.fb.group({
        currentPassword: this.fb.control('', [Validators.required]),
        newPassword: this.fb.control('', [Validators.required]),
        check: this.fb.control('', [Validators.required]),
        token: this.fb.control(''),
      });
    }
    this.updatePasswordForm.addValidators(this.createCompareValidator(this.updatePasswordForm.controls['newPassword'], this.updatePasswordForm.controls['check']));
  }

  createCompareValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if ((controlTwo.value == '' && controlOne.value == '') || controlOne.value !== controlTwo.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }

  onSubmitReset() {
    this.error = ''
    this.successMsg = ''
    if (this.updatePasswordForm.invalid) return
    this.loading = true;
    if (this.token) {
      let payload = {
        "password": this.updatePasswordForm.controls['newPassword'].value,
        "token": this.token
      }
      this.profileService.resetPassword(payload).subscribe({
        next: data => {
          this.successMsg = data.message;
          this.loading = false;
          this.alertService.showAlert({
            message: 'Password saved successfully, Please login!',
            type: TYPE.success,
            mode: 'fixed-header',
          });
          this.router.navigate(['/login']);
        },
        error: err => {
          this.error = err.message;
          this.loading = false;
        }
      })
    } else {
      this.profileService.updatePassword(this.updatePasswordForm.value).subscribe({
        next: data => {
          this.successMsg = data.message;
          this.loading = false;
          this.updatePasswordForm.reset();
        },
        error: err => {
          this.error = err;
          this.loading = false;
        }
      })
    }
  }
}
