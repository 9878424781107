import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationPopupService {
    title?: string;
    message?: string;
    type?: any;
    item?: any;
    isFromProductCard?: any;
    private onYes: Subject<any> = new BehaviorSubject<any>(null);
    public onYes$= this.onYes.asObservable();

    private onCancel: Subject<any> = new BehaviorSubject<any>(null);
    public onCancel$= this.onCancel.asObservable();
    
    private triggerPopupSource = new BehaviorSubject<any>(null);
    public triggerPopup$ = this.triggerPopupSource.asObservable();

    open(data: any) {
        this.triggerPopupSource.next(data);
    }

    selectYes(flag:any) {
        this.onYes.next(flag);
    }

    cancelPopup() {
        this.onCancel.next(this.item);
    }
}