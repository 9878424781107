<section class="common-sections-pb">
	<div class="container px-lg-2">

		<!-- Title -->
		<div class="row mb-4">
			<div class="col-12 text-center">
                <!-- <h2>Why Choose Us?</h2> -->
				<h2>Why <span class="text-primary">Choose Us</span>?</h2>
            </div>
        </div>
		
		<!-- Content END -->

		<!-- Services START -->
		<div class="row g-4 justify-content-center mt-5">
			<!-- Category item -->
			<div class="col-sm-6 col-lg-4">
				<!-- Card START -->
				<div class="card card-body shadow p-4 h-100">
					<!-- Icon -->
					<div class="icon-lg bg-primary bg-opacity-10 text-primary rounded-circle mb-4"><i class="bi bi bi-cash-coin fs-4"></i></div>
					<h5>Premium quality reports</h5>
					<p class="mb-0">All our reports are of premium quality which can be presented on any platform to impress  your clients and close the deal in your favour.</p>
				</div>
				<!-- Card END -->
			</div>

			<!-- Category item -->
			<div class="col-sm-6 col-lg-4">
				<!-- Card START -->
				<div class="card card-body shadow p-4 h-100">
					<!-- Icon -->
					<div class="icon-lg bg-success bg-opacity-10 text-success rounded-circle mb-4"><i class="fa-solid fa-rocket fs-5"></i></div>
					<h5>Most accurate roof reporting solution</h5>
					<p class="mb-0">We provide the most accurate (95% and above accuracy) roof reporting. We don’t make any errors but if the client is not satisfied with our reports we can redo those reports as per their requests.</p>
				</div>
				<!-- Card END -->
			</div>

			<!-- Category item -->
			<div class="col-sm-6 col-lg-4">
				<!-- Card START -->
				<div class="card card-body shadow p-4 h-100">
					<!-- Icon -->
					<div class="icon-lg bg-warning bg-opacity-15 text-warning rounded-circle mb-4"><i class="bi bi-coin fs-4"></i></div>
					<h5>Affordable roof reporting</h5>
					<p class="mb-0">Our reports are budget-friendly, affordable and easy to use. We believe in giving our clients the best value for money for the ordered reports.</p>
				</div>
				<!-- Card END -->
			</div>
			
		</div>
        
		<!-- Services END -->
	</div>
</section>