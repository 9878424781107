<!-- =======================
Rooms START -->
<section class="common-sections-pb">
	<div class="container px-lg-2">

		<!-- Title -->
		<div class="row mb-4">
			<div class="col-12 text-center">
				<!-- <h2 class="mb-0">Who Use Our Reports?</h2> -->
				<h2>Who Use <span class="text-primary">Our Reports</span>?</h2>
			</div>
		</div>
		<div class="row g-4 justify-content-center mt-5">
		<!-- Slider START -->
		<div class="row tiny-slider arrow-round arrow-blur arrow-hover rounded-3 overflow-hidden">
			<div class="tiny-slider-inner" data-gutter="0" data-arrow="true" data-dots="false" data-items="1">
				<!-- Card item START -->
				<div>
					<div class="card overflow-hidden rounded h-600px" style="background-image:url(https://ik.imagekit.io/krlinc/assets/images/finalized-images/insurance-1.webp); background-position: center left; background-size: cover;">
						<!-- Background dark overlay -->
						<div class="bg-overlay bg-dark opacity-3"></div>

						<!-- Card body content -->
						<div class="row  justify-content-end z-index-9 white-block-center-right">
							<div class="col-md-8 col-xl-8 col-xxl-6">
								<div class="card-body bg-mode rounded p-3 p-md-5 m-2 m-lg-6">
									<!-- Title -->
									<h4>FOR INSURANCE PROFESSIONALS</h4>


									<p class="mb-3"><strong class="text-primary">KRL INC.</strong> roof reports are ideal for the insurance market. Our reports eliminate the need for adjusters or estimators to climb on the roof, providing a quick and simple method with accurate data needed for estimating the cost of a roof claim.</p>

									<!-- List -->
									<ul class="list-inline hstack flex-wrap gap-2 mb-3">
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Claims (Carriers)
										</li>
										<li class="list-inline-item h6 fw-normal mb-0">
											<i	class="bi bi-check-lg text-success me-1"></i>Reinsurance
										</li>
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Underwriting (Carriers)
										</li>
										
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Adjusters
										</li>

									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Card item END -->
				<!-- Card item START -->
				<div>
					<div class="card overflow-hidden rounded h-600px"
						style="background-image:url(https://ik.imagekit.io/krlinc/assets/images/finalized-images/insurance-2.webp); background-position: center left; background-size: cover;">
						<!-- Background dark overlay -->
						<div class="bg-overlay bg-dark opacity-3"></div>

						<!-- Card body content -->
						<div class="row justify-content-end z-index-9 white-block-center-right" >
							<div class="col-md-8 col-xl-8 col-xxl-6">
								<div class="card-body bg-mode rounded p-3 p-md-5 m-2 m-lg-6">
									<!-- Title -->
									<h4>FOR ROOFING PROFESSIONALS</h4>


									<p class="mb-3"><strong class="text-primary">KRL INC.</strong> roof reports make it easy to provide your customers with a quick and comprehensive overview of their roofing project. This can help in presenting the proposal in a way that is easy to understand, leading to increased success rates. Additionally, our aerial measured roof reports provide accurate data for material and labor calculations, making the estimation process more efficient and reliable.</p>

									<!-- List -->
									<ul class="list-inline hstack flex-wrap gap-2 mb-3">
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Roofing Contractors
										</li>
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Solar Installers
										</li>
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Distributors/Manufacturers
										</li>
										<li class="list-inline-item h6 fw-normal mb-0">
											<i class="bi bi-check-lg text-success me-1"></i>Remodelers
										</li>

									</ul>

								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Card item END -->

			</div>
		</div>
		<!-- Slider END -->
		</div>
	</div>
</section>
<!-- ======================= 
Rooms END -->