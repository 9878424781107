import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cart, CartItemRequest, CartItemUI, CheckoutResponce } from '@app/_models';
import { APP_ICONS, CONFIG_MESSAGES, ConfirmationPopupService, PAYMENT_STATUS, TABS } from '@app/_services';
import { CartService } from '@app/_services/cart.service';
import { CheckoutService } from '@app/_services/checkout.service';
import { ProductService } from '@app/_services/product.service';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { map } from 'rxjs';
import { environment } from '@environments/environment';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  @ViewChild('paypalE') paypalE!: ElementRef;
  public payPalConfig?: IPayPalConfig;
  public showSuccess!: any;
  public blockCheckout: boolean = true;
  public app_icons = APP_ICONS;
  public cartIsActive: boolean = false;
  public cart: CartItemUI[] = []
  public cart_messages: any = CONFIG_MESSAGES.cart;
  public _product: any = {};
  products: Map<number, any> = new Map();
  isLoading: boolean = false;
  public approvalLink: string | undefined = '';
  public invoiceId: string | undefined = '';

  public checkoutCart: CartItemUI[] = [];
  public items: any[] = [];
  public total: any;
  public itemTotal: any;
  public paymentStatus: any = null;
  public showPaymentStatus: boolean = false;
  public PAYMENT_STATUS = PAYMENT_STATUS;
  public activeTab: any = TABS.CART;
  paymentSuccessMeessage: string = '';
  paymentErrorMeessage: string = '';
  paymentErrorTitle: string = '';
  confirmationToDelete$: any;
  refreshCountSubject$: any;
  productAddons: any;
  addonsErrorMessage: string = '';
  loadingMessage='Please wait, while we are loading the checkout details.'
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private productService: ProductService,
    private checkoutService: CheckoutService,
    private confirmationPopupService: ConfirmationPopupService,private title: Title, private meta: Meta) {
      this.title.setTitle("My Cart - KRL INC.");
      this.meta.updateTag({ name: 'description', content: "" })
      this.cartIsActive = (router.url.indexOf(TABS.CART) > -1)

    this.confirmationToDelete$ = this.confirmationPopupService.onYes$.subscribe(data => {

      if (data && this.confirmationPopupService.isFromProductCard === TABS.CART) {
        if (this.confirmationPopupService.item.cartItemId) {
          let localCartItemIndex = this.cart.find(c => c.cartItemId === this.confirmationPopupService.item.cartItemId);
          if (localCartItemIndex) {
            this.isLoading = true;
            this.cartService.deleteCartItemFromServer(localCartItemIndex as unknown as CartItemRequest).subscribe(data => {
              this.getCartData();
              this.cartService.refreshCount();
            });
          }
        }
      }

    })
  }

  ngOnInit() {
    let isSuccess = (this.activatedRoute.snapshot.paramMap.get(PAYMENT_STATUS.SUCCESS) == 'true');
    let isFailed = (this.activatedRoute.snapshot.paramMap.get(PAYMENT_STATUS.FAILED) == 'true');

    if (isSuccess) {
      this.isLoading = true;
      this.activeTab = TABS.PAYMENT_STATUS;;
      this.activatedRoute.queryParamMap.subscribe((params: any) => {

      });
    } else if (isFailed) {

    } else if (this.cartIsActive) {
      this.getCartData();
      this.activeTab = TABS.CART;
    } else {
      this.activeTab = TABS.CHECKOUT;
      this.getCartData();
    }
  }

  getCartData() {
    this.isLoading = true;
    this.products = new Map();
    this.cart = [];
    this.cartService.getCartFromServer().subscribe((res: any) => {
      if (res?.data.cartItems && res?.data.cartItems.length > 0) {
        let productsAndOptions = res['data']['productsAndOptions']
        productsAndOptions['products'].forEach((product: any) => {
          this._product[product.id] = product
          this.products.set(product.id, product);
        })

        let localCart: CartItemUI[] = [];
        res?.data.cartItems.reverse()?.forEach((sCart: any) => {
          let productPrice = 0
          if (this.products.has(sCart.productId)) {
            let product = this.products.get(sCart.productId);
            this.parseProductAddons(product['addons'])
            productPrice = product.price
          }
          if (this.productAddons == null) {
            this.productAddons = []
          }
          if (sCart.addedAddons == null) {
            sCart.addedAddons = []
            if (this.productAddons.length > 0) {
              sCart.addonsErrorMessage = 'No Addons added for this order.'
            }
          }
          let addonsTotal = 0
          for (let i = 0; i < sCart.addedAddons.length; i++) {

            for (let productAddon of this.productAddons) {
              if (productAddon.value == sCart.addedAddons[i]) {
                productAddon.selected = true
                sCart.addedAddons[i] = productAddon.name
                addonsTotal = addonsTotal + productAddon.price
              }
            }
          }
          sCart.price = productPrice + addonsTotal
          this.cart.push(new CartItemUI(sCart));
        });
        //this.cart = localCart;
      }
      if (this.activeTab === TABS.CHECKOUT) {
        this.getCheckoutData();
      } else {
        this.isLoading = false;
      }
    })
  }
  private parseProductAddons(productAddons: any) {
    // let i=1

    if (productAddons != null) {
      this.productAddons = []
      productAddons.forEach((productAddon: any) => {
        let pA = {
          'value': productAddon.name.split("(")[0].toLowerCase() + "_" + productAddon.product_id + "_" + productAddon.addon_id,
          'name': productAddon.name + " $" + productAddon.price,
          'selected': false,
          'price': productAddon.price
        }
        this.productAddons.push(pA)
      });
    } else {
      this.productAddons = null
    }
  }
  private getProductsAndOptions() {
    this.products = new Map();
    this.productService.getProductsAndOptions().subscribe({
      next: (data: any) => {
        if (data['status'] == true) {
          data['data']['products'].forEach((product: any) => {
            this._product[product.id] = product
            this.products.set(product.id, product);
          })

        }
      }, error: err => {
        err.error.message;
        console.log(err);
      }
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }
  getCheckoutData() {

    this.isLoading = true;
    this.loadingMessage='Please wait, while we are loading the checkout details.'
    this.products = new Map();
    // this.productService.getProductsAndOptions().subscribe({
    //   next: (data: any) => {
    //     if (data['status'] == true) {
    //       data['data']['products'].forEach((product: any) => {
    //         this._product[product.id] = product
    //         this.products.set(product.id, product);
    //       })

          this.checkoutService.checkoutV2().subscribe({
            next: (data: CheckoutResponce) => {
              this.isLoading = false;
              let productPrice = 0
              if (data.status == true && data?.data?.cart) {
                let productsAndOptions = data?.data?.productsAndOptions
                productsAndOptions['products'].forEach((product: any) => {
                  this._product[product.id] = product
                  this.products.set(product.id, product);
                })
                this.approvalLink = data?.data?.approvalLink;
                this.invoiceId = data?.data?.invoiceId;
                data.data.cart.cartItems?.forEach(data => {
                  if (this.products.has(data.productId)) {
                    let product = this.products.get(data.productId);
                    this.parseProductAddons(product['addons'])
                    productPrice = product.price
                  }
                  if (this.productAddons == null) {
                    this.productAddons = []
                  }
                  if (data.addedAddons == null) {
                    data.addedAddons = []

                  }
                  let addonsTotal = 0
                  for (let i = 0; i < data.addedAddons.length; i++) {

                    for (let productAddon of this.productAddons) {
                      if (productAddon.value == data.addedAddons[i]) {
                        productAddon.selected = true
                        data.addedAddons[i] = productAddon.name
                        addonsTotal = addonsTotal + productAddon.price
                      }
                    }
                  }
                  // data.price=productPrice+addonsTotal
                  let cartItemUI = new CartItemUI(data);
                  if (data.addedAddons == null || data.addedAddons.length == 0) {
                    if (this.productAddons.length > 0) {
                      cartItemUI.addonsErrorMessage = 'No Addons added for this order.'
                    }
                  }
                  cartItemUI.price = productPrice + addonsTotal
                  this.checkoutCart.push(cartItemUI)
                  let item = {
                    name: cartItemUI.address, 
                    quantity: '1',
                    category: cartItemUI.model,
                    unit_amount: {
                      currency_code: 'USD',
                      value: cartItemUI.price,
                    },
                  }
                  this.items.push(item);

                });
               // this.total = data?.data?.cart?.total;
                this.itemTotal=data?.data?.cart?.total
               
                this.total=this.itemTotal
                
                this.initConfig();
              }
            }, error: err => {
              this.isLoading = false;
              console.log(err);
            }
          });

      //   }
      // }, error: err => {
      //   err.error.message;
      //   console.log(err);
    //   }
    // });

  }
  private initConfig(): void {
    this.isLoading = true;
    this.payPalConfig = {
      currency: 'USD',
      clientId: environment.paypal_client_id,

      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            
            amount: {
              currency_code: 'USD',
              value: this.total,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.itemTotal
                }
              }
            },
            items: this.items,
            invoice_id: this.invoiceId
          }
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        }
      },
      advanced: { commit: 'true', extraQueryParams: [{ name: "disable-funding", value: "paylater" }] },

      style: {
        label: 'paypal',
        layout: 'vertical',
        color: 'blue',
        height: 40,
        tagline: false


      },
      onApprove: (data, actions) => {
        actions.order.get().then((details: any) => {
          
        });
      },
      onClientAuthorization: (data) => {
        let status = data.status;
        let token = data.id;
        if (status === "COMPLETED") {
          let amount = data.purchase_units[0].amount;
          let invoiceId = data.purchase_units[0]?.invoice_id;
          this.isLoading = true;
          this.activeTab = TABS.PAYMENT_STATUS;
          this.checkoutService.verifyPaymentV2(token, status, amount.value, invoiceId).subscribe({
            "next": (data: any) => {
              this.showPaymentStatus = true;
              this.isLoading = false;
              if (data.status) {
                this.paymentStatus = PAYMENT_STATUS.SUCCESS;
                this.paymentSuccessMeessage = data.message;
                this.cartService.clearCart()
              } else {
                this.showPaymentStatus = true;
                this.paymentStatus = PAYMENT_STATUS.ERROR;
                this.paymentErrorMeessage = data.message;
                this.paymentErrorTitle = "Uh-oh, We Are Unable To Process Your Payment";
              }
            },
            error: err => {
              this.showPaymentStatus = true;
              this.isLoading = false;
              this.paymentStatus = PAYMENT_STATUS.ERROR;
              this.paymentErrorMeessage = "Payment Failed, it seems that it is either canceled or failed, If the amount got deducted will be credited back to your account soon, If you require any further assistance please do not hesitate to contact us."
              this.paymentErrorTitle = "Uh-oh, We Are Unable To Process Your Payment";
              // this.paymentErrorMeessage = err;
            }
          });
        }
      },
      onCancel: (data, actions) => {
        this.isLoading = true;
        this.activeTab = TABS.PAYMENT_STATUS;
        this.showPaymentStatus = true;
        this.isLoading = false;
        this.paymentStatus = PAYMENT_STATUS.ERROR;
        this.paymentErrorMeessage = "Payment Failed, it seems that it is either canceled or failed, If the amount got deducted will be credited back to your account soon, If you require any further assistance please do not hesitate to contact us."
        this.paymentErrorTitle = "Uh-oh, We Are Unable To Process Your Payment";
      },
      onError: err => {
        this.isLoading = false;
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        this.isLoading = true;
        this.loadingMessage='Waiting for payment completion, please complete the payment process...!'
        console.log("Clicked on "+data.fundingSource +" button")
      },
      onInit: (data, actions) => {
        this.isLoading = false;
      }
    };


  }
  proceedToPayment() {
    window.open(this.approvalLink, '_self');
  }

  ngOnDestroy() {
    this.confirmationToDelete$?.unsubscribe();
    this.refreshCountSubject$?.unsubscribe();
  }
}

