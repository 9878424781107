

<section class="common-sections-pb">
	<div class="container px-lg-2">

		<!-- Title -->
		<div class="row mb-4">
			<div class="col-12 text-center">
				<h2 class="mb-0">The Process</h2>
			</div>
		</div>
		<div class="row g-4 justify-content-center mt-5">
			<div class="col-lg-12 mx-auto">
				<div class="ratio ratio-21x9 rounded">
                    <iframe class="rounded" src="https://www.youtube.com/embed/vlDzYIIOYmM" title="YouTube video" allowfullscreen></iframe>
                  </div>
			</div>
		</div>
	</div>
</section>