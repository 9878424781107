<div class="strength">
  <div>
    <label class="form-check-label mr-10">Password Strength:</label>&nbsp;
    <ul class="strengthBar">
      <li class="point" [style.background-color]="bar0"></li>
      <li class="point" [style.background-color]="bar1"></li>
      <li class="point" [style.background-color]="bar2"></li>
      <li class="point" [style.background-color]="bar3"></li>
    </ul>
  </div>
 
  <br>
  <!-- <p>{{msg}}</p> -->
  <p *ngIf="!passwordValid" class="password-invalid-msg">Password must contain At least 6 characters, at least 1 upper and lower case letter, numbers, special characters.</p>
</div>
