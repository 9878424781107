
<!-- =======================
Footer START -->

<footer class="bg-dark pt-5">
	<div class="container">
		<!-- Row START -->
		<div class="row g-4">

			<!-- Widget 1 START -->
			<div class="col-lg-3">
				<!-- logo -->
				<a [routerLink]="['/']">
					<div class="logo-text">
					<img class="log-img" src="assets/images/krl_inc_logo.png" alt="logo"> 
					<h2 class="text-logo">
					<span class="long-text">K</span>
					<span class="sort-text">R</span>
					<span class="sort-text">L</span>
					<span class="long-text">&nbsp;</span>
					<span class="long-text">I</span>
					<span class="sort-text">N</span>
					<span class="sort-text">C</span>
					<span class="sort-text">.</span>
					</h2>
				</div>
				</a>
				<p class="mt-2"><a href="mailto:corporate@krlinc.com" class="text-muted text-primary-hover"><i class="bi bi-envelope me-2"></i>corporate@krlinc.com</a></p>
			</div>
			<!-- Widget 1 END -->

			<!-- Widget 2 START -->
			<div class="col-lg-9">
				<div class="row g-4">
						<h5 class="text-white">Links</h5>
						
				</div>
				<div class="row g-4">
					
					<ul class="nav flex-row text-primary-hover">
						<li class="nav-item"><a class="nav-link text-muted" [routerLink]="['/about-us']">About Us</a></li>
						<li class="nav-item"><a class="nav-link text-muted" [routerLink]="['/faq']">FAQ's</a></li>
						<li class="nav-item"><a class="nav-link text-muted" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
						<li class="nav-item"><a class="nav-link text-muted" [routerLink]="['/terms-and-conditions']">Terms & Conditions</a></li>
						<li class="nav-item"><a class="nav-link text-muted" [routerLink]="['/order-now']">Order Now</a></li>
					</ul>
			</div>
			</div>
			<!-- Widget 2 END -->

		</div><!-- Row END -->

		<!-- Payment and card -->
		<div class="row g-4 justify-content-between mt-0 mt-md-2">

			<!-- Payment card -->
			<div class="col-sm-7 col-md-6 col-lg-4">
				<h5 class="text-white mb-2">Payment & Security</h5>
				<ul class="list-inline mb-0 mt-3">
					
					<li class="list-inline-item"><a href="https://www.paypal.com/in/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/in/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypal.com/en_US/i/icon/verification_seal.gif" border="0" alt="Official PayPal Seal" width="35px"></a><!-- PayPal Logo --></li>

					<li class="list-inline-item "><a href="https://www.paypal.com/in/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/in/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/en_IN/mktg/logos/AM_SbyPP_mc_vs_dc_ae.jpg" border="0" alt="PayPal Acceptance Mark" width="116px"></a><!-- PayPal Logo --></li>
	   
					<li class="list-inline-item"><span id="siteseal"> <a href="javascript:if(window.open('https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=W&amp;v_shortname=CL1&amp;v_search=https://krlinc.com/&amp;x=6&amp;y=5','tl_wnd_credentials'+(new Date()).getTime(),'toolbar=0,scrollbars=1,location=1,status=1,menubar=1,resizable=1,width=374,height=660,left=60,top=120')){};tLlB(tLTB);" onmouseover="tLeB(event,'https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=C&amp;v_shortname=CL1&amp;v_search=https://krlinc.com/&amp;x=6&amp;y=5','tl_popupCL1')" onmousemove="tLXB(event)" onmouseout="tLTC('tl_popupCL1')" ondragstart="return false;"><img src="https://ik.imagekit.io/krlinc/assets/images/seal/sectigo_trust_seal.png" alt="SSL Certificate" border="0" onmousedown="return tLKB(event,'https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=W&amp;v_shortname=CL1&amp;v_search=https://krlinc.com/&amp;x=6&amp;y=5');" oncontextmenu="return tLLB(event);"></a></span></li>
					
					   
				</ul>
			</div>

			<!-- Social media icon -->
			<div class="col-sm-5 col-md-6 col-lg-3 text-sm-end">
				<h5 class="text-white mb-2">Follow us on</h5>
				<ul class="list-inline mb-0 mt-3">
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-pinterest mb-0" href="https://pin.it/3TAGICn" target="_blank"><i  class="fab fa-fw fa-pinterest" style="color: white;"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm px-2 bg-facebook mb-0" href="#"><i class="fab fa-fw fa-facebook-f"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-instagram mb-0" href="#"><i class="fab fa-fw fa-instagram"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-twitter mb-0" href="#"><i class="fab fa-fw fa-twitter"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-linkedin mb-0" href="#"><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
				</ul>	
			</div>
		</div>

        <app-copyrights [mode]="'long'"></app-copyrights>
	</div>
</footer>
<!-- =======================
Footer END -->
