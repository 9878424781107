import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TYPE } from './alert/alert.component';
import { AlertService, AuthenticationService, ConfirmationPopupService } from './_services';
import LogRocket from 'logrocket';
import { environment } from '@environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

//external js function declaration
declare function laodFresh(): any;
declare function scrollToTop(): any;
const HIDE_HEADER_ROUTE = ['login', 'sign-up', 'forgot-password', 'reset-password', 'verify-email'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mirak-labs-aam';
  isUserLoggedIn = false;
  showHeadFoot = true;
  isLoading: boolean = false;
  showPopUp: boolean = false;
  alert_type = TYPE;
  alertMessage:any = '';
  alertMode:any = '';
  alertType!: TYPE;
  isBrowserSupport: boolean = true;
  constructor(
    private authenticationService: AuthenticationService, 
    private readonly router: Router, 
    private activeRoute: ActivatedRoute, 
    private confirmService: ConfirmationPopupService,
    private alertService: AlertService,private deviceService: DeviceDetectorService) {
      if(environment.production)
      {
        LogRocket.init('hzkmj5/krlinc-dev'); 
      }
    this.authenticationService.user.subscribe(x => {
      this.isUserLoggedIn = x ? true : false;
    });

    router.events.subscribe((route: any) => {
      if (route instanceof NavigationEnd) {
      
        if(!(route.url.includes('services') || route.url.includes('pricing'))){
          setTimeout(() => {
            scrollToTop()
          },200)
        }

        setTimeout(() => {
          laodFresh();
        },200)
      }
    });

    this.router.events.subscribe((value) => {
      // this.showHeadFoot = !HIDE_HEADER_ROUTE.some(r => (window.location.href).indexOf(r) >= 0);
    });

    this.alertService.triggerAlert$.subscribe((alertData) => {
      this.alertMessage = this.alertService?.message;
      this.alertMode = this.alertService.mode;
      this.alertType = this.alertService.type;
      scrollToTop()
    })
  }


  ngAfterViewInit()
{
  var firstTime = localStorage.getItem("first_time");
if(!firstTime) {
    // first time loaded!
    localStorage.setItem("first_time","1");
    window.location.reload();
}
}

  logout() {
    this.authenticationService.logout();
  }
  ngOnInit() {
    
    if((this.deviceService.browser.indexOf('Edge')>-1 && this.getBrowserMajorVersion()<85) || (this.deviceService.browser.indexOf('Firefox')>-1 && this.getBrowserMajorVersion()<75))
    {
      this.isBrowserSupport=false;
    }
}
getBrowserMajorVersion()
{
 var browserVersion= this.deviceService.browser_version.split(".");
 if(browserVersion.length>1)
 {
   try{
    return Number(browserVersion[0]); 
   }catch(e)
   {
return 0; 
   }
   return 0; 
 }else{
  try{
    return Number(browserVersion[0]); 
   }catch(e)
   {
return 0; 
   }
   return 0; 
 }
 return 0;
}

  // ngAfterContentInit() {
  //   setTimeout(() => {
  //     this.loadJS();
  //   }, 100)
  // }

  // loadJS() {
  //   setTimeout(() => {
  //     laodFresh();
  //   })
  // }

  onActivate(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    let profileDropDown = document.getElementById('profileDropdown')
    let profileDropDownMenu = document.getElementById('dropdown-menu')
    let menuLeftElement = document.getElementById('navbarCollapse')
    let menuLeftButtonElement = document.getElementById('menu-left-button')
    let menuRightElement = document.getElementById('navbarCategoryCollapse');
    profileDropDownMenu?.classList.remove("show");
    profileDropDown?.classList.remove("show");
    menuLeftElement?.classList.remove("show");
    menuRightElement?.classList.remove("show");
    menuLeftButtonElement?.setAttribute('aria-expanded', 'false')
    profileDropDown?.setAttribute('aria-expanded', 'false');
    profileDropDownMenu?.setAttribute('aria-expanded', 'false');
    menuLeftButtonElement?.setAttribute('class', 'navbar-toggler ms-auto ms-sm-0 p-0 p-sm-2');
  }


  popupConfig() {
    this.confirmService.triggerPopup$.subscribe((data: any) => {
      this.showPopUp = true
    });
    this.confirmService.onCancel$.subscribe((data: any) => {
      this.showPopUp = false
    });
  }


  
}
