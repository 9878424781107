import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    message?: string;
    type?: any;
    mode?:any;

    private triggerAlertSource = new BehaviorSubject<any>(null);
    public triggerAlert$ = this.triggerAlertSource.asObservable();

    showAlert(data: any) {
        this.message = data.message;
        this.type = data.type;
        this.mode = data.mode;
        this.triggerAlertSource.next(data);

        setTimeout(()=>{
            this.dismissAler();
        },10000)
    }

    dismissAler() {
        this.message = '';
        this.type = null;
        this.mode = null;
        this.triggerAlertSource.next({
            message: '',
            type: null,
            mode: null
        });
    }
}