import { Component, Input } from '@angular/core';
import { AlertService } from '@app/_services';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() message: string | undefined;
  @Input() messageTitle: string | undefined;
  @Input() type: TYPE = TYPE.primary;
  @Input() mode: string = 'fixed-header';

  constructor(private alertService: AlertService){

  }
  // 'heading' | 'link' | 'dismissible' | 'dismissible' | 'primary' | 'primary' | 'link' | 'white' | 'white' | 'warning' | 'link' | 'danger' | 'link' | 'light' | 'light' | 'link' | 'dark' | 'dark' | 'link' | 'orange' | 'orange' | 'link' | 'mode' | 'mode' | 'link' | 

  close() {
    this.alertService.dismissAler();
  }
}


export enum TYPE {
  primary = 'bg-primary',
  white = 'bg-white',
  secondary = 'bg-secondary',
  success = 'bg-success',
  info = 'bg-info',
  warning = 'bg-warning',
  danger = 'bg-danger',
  light = 'bg-light',
  dark = 'bg-dark',
}