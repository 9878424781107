import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-residential-report',
  templateUrl: './residential-report.component.html',
  styleUrls: ['./residential-report.component.scss']
})
export class ResidentialReportComponent {
  constructor(private title: Title, private meta: Meta, private router: Router) {
    this.title.setTitle("Residential Roof Reports USA, Canada - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "Single-Family house used for non commercial purpose." })
    this.meta.updateTag({ name: 'keywords', content: "Residential Roof Report,Residential Roof Report USA,Residential Roof Report Canada" })

  }

  orderNow(type: string) {
    this.router.navigateByUrl('/order-now', { state: { search: '', reportType: type } });
  
  }
  
  downlaodPDF(){
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href ='assets/downloads/residential-sample-report.pdf'
    link.download = 'residential-sample-report';
    link.click();
    link.remove();
  }
}
