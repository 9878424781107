<section class="pt-3 pt-lg-5">
	<div class="container">
		<!-- Content and Image START -->
		<div class="row g-4 g-lg-5">
			<!-- Content -->
			<div class="col-lg-6 position-relative mb-4 mb-md-0">
				<!-- Title -->
				<h2 class="mb-4 mt-md-5" style="font-size: 2.3rem
			!important;">Accurate Aerial Measured Roof Reports</h2>
				<!-- Info -->
				<p class="mb-4" style="font-size: 1.5rem;     text-align: justify;">At <strong class="text-primary">KRL INC</strong>, we provide professional and
					reliable roof reporting services. Our expert team uses satellite
					imagery, state-of-the-art technology, and industry-leading practices to provide detailed and
					reliable
					reports.</p>

				<!-- Buttons -->
				<div class="hstack gap-4 flex-wrap align-items-center">
					<!-- Button -->
					<!-- <a class="btn btn-primary-soft mb-0">Order Now</a> -->
					<!-- Story button -->
					<a data-glightbox="" data-gallery="office-tour" href="https://www.youtube.com/embed/tXHviS-4ygo"
						class="d-block">
						<!-- Avatar -->
						<!--<div class="avatar avatar-md z-index-1 position-relative me-2">
							<img class="avatar-img rounded-circle" src="assets/images/avatar/12.jpg" alt="avatar">
							<div class="btn btn-xs btn-round btn-white shadow-sm position-absolute top-50 start-50 translate-middle z-index-9 mb-0"> 
								<i class="fas fa-play"></i>
							</div>
						</div>
						 <div class="align-middle d-inline-block">
							<h6 class="fw-normal small mb-0">Watch our story</h6>
						</div> -->
					</a>
				</div>
			</div>

			<!-- Image -->
			<div class="col-lg-6 position-relative">

				<img src="https://ik.imagekit.io/krlinc/assets/images/bg/WEB_3.png" class="rounded" alt="Home">



				<div class="position-absolute top-0 end-0 z-index-1 mt-n4 pointer-cursor">
					<div class="bg-blur border border-light rounded-3 text-center shadow-lg p-3"
						[routerLink]="['/contact-us']">
						<!-- Title -->
						<i class="bi bi-envelope text-danger fs-3"></i>

						<h5 class="text-dark mb-1">Contact Us</h5>
					</div>
				</div>


			</div>
		</div>
		<!-- Content and Image END -->

		<!-- Search START -->
		<div class="row">
			<div class="col-xl-10 position-relative mt-n3 mt-xl-n9">
				<!-- Title -->
				<h6 class="d-none d-xl-block mb-3">Order Now</h6>

				<!-- Booking from START -->
				<form class="card shadow rounded-3 position-relative p-4 pe-md-5 pb-5 pb-md-4"
					[formGroup]="locationForm" (ngSubmit)="!isLoading && onSubmit()">
					<div class="row g-4 align-items-center">
						<!-- Location -->
						<div class="col-lg-12">
							<div class="form-control-border form-control-transparent form-fs-md d-flex">
								<!-- Icon -->
								<i class="bi bi-house-door-fill fs-3 me-2 mt-2 text-primary"></i>
								<!-- Select input -->
								<div class="flex-grow-1">
									<label class="form-label">Address</label>
									<input class="form-select js-choice" type="text"
										class="form-guest-selector-custome form-control "
										placeholder="Enter Property Address" data-search-enabled="true" type="text"
										class="form-guest-selector-custome form-control selection-result"
										ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)"
										formControlName="address"
										[ngClass]="{ 'is-invalid': (submitted && f.address.errors ) || f.address.unknownError }">
									<ul class="list-unstyled hint-error" *ngIf="submitted && f.address.errors">
										<li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> Please
											enter valid property address/latlong.</li>
									</ul>
								</div>
							</div>
						</div>
						<!-- Guest -->
						<div class="col-lg-12">
							<div class="form-control-border form-control-transparent form-fs-md d-flex">
								<i class="bi bi-geo-alt-fill fs-3 me-2 mt-2 text-primary" ></i>
								<div class="w-100">
									<label class="form-label">Coordinates</label>
									<div class="dropdown guest-selector me-2">
										<input type="text" class="form-guest-selector-custome form-control "
											data-bs-auto-close="outside" #coordinates
											placeholder="Enter Property Latitude,Longitude" formControlName="latLong"
											[ngClass]="{ 'is-invalid': (submitted && f.address.invalid) || f.address.unknownError }">
										<ul class="list-unstyled hint-error" *ngIf="submitted && f.address.errors">
											<li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> Please
												enter valid property address/latlong eg: 51.083773,3.73895</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Button -->
					<button class="btn-position-md-middle b-s-none" type="submit"
						[ngClass]="{'disabled':!locationForm.valid}">
						<a class="icon-lg btn btn-round btn-primary mb-0">
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
								*ngIf="isLoading"></span>
							<i class="bi bi-search fa-fw" *ngIf="!isLoading"></i></a>
					</button>
				</form>
				<!-- Booking from END -->
			</div>
		</div>
		<!-- Search END -->
	</div>
</section>

<app-services-page #services_1 id="services_1"></app-services-page>
<app-pricing-page #pricing_1 id="pricing_1"></app-pricing-page>
<app-our-features></app-our-features>
<app-who-uses-our-reports></app-who-uses-our-reports>
<app-why-choose-us></app-why-choose-us>
<!-- <app-into-video></app-into-video> -->
<app-client-testimonials></app-client-testimonials>
