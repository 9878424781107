

export class Cart {
    constructor(){}
    public cartItems?:CartItemRequest[];
    public total?:number;
}


export class CartItemUI {
    public cartItemId: number=0; 
    public address: string;
    public latitude: number;
    public longitude: number;
    public reportType: number;
    public customerName: string;
    public pitch: string;
    public expectedDeliveryWithin: string;
    public includeSecondaryStructure: string;
    public includeObstructions: string;
    public notes: string;
    public modelingInstructions: string;    
    public productName:string;
    public productPrice: string;
    public isCollapsed?:boolean;
    public orderZipFileStatus?:boolean;

    //  Order Items
    public email: any;
    public invoiceId: any;
    public model: any;
    public orderDate: any;
    public orderId: any;
    public orderItemStatus: any;
    public paymentMethod: any;
    public price: any;
    public serviceType: any;
    public status: any;
    public total: any;
    reason?:any;
    public paymentStatus : any;
    public addedAddons:any;
    public addonsErrorMessage:string=''
    constructor(data:any) {
            this.cartItemId = data.cartItemId ? data.cartItemId : 0;
            this.address = data.address
            this.latitude = data.latitude
            this.longitude = data.longitude
            this.reportType = data.productId ? data.productId : data.reportType;
            this.customerName = data.customerName
            this.pitch = data.pitch
            this.expectedDeliveryWithin = data.expectedDeliveryWithin
            this.includeSecondaryStructure = data.includeSecondaryStructure ? 'Yes':'No'
            this.includeObstructions =  data.includeObstructions ? 'Yes':'No'
            this.notes = data.notes
            this.modelingInstructions = data.modelingInstructions ;
            this.productName = data.productName;
            this.productPrice =  data.productPrice;

            this.email = data.email;
            this.invoiceId = data.invoiceId;
            this.model = data.model;
            this.orderDate = data.orderDate;
            this.orderId = data.orderId;
            this.orderItemStatus = data.orderItemStatus;
            this.paymentMethod = data.paymentMethod;
            this.price = data.price;
            this.serviceType = this.getServiceType(data.productName);
            this.status = data.status;
            this.total = data.total;
            this.reason = data.reason;
            // this.paymentStatus = data.paymentStatus;
            this.orderZipFileStatus = data.orderZipFileStatus;
            this.addedAddons= data.addedAddons
            this.addonsErrorMessage=data.addonsErrorMessage
    }
    protected  getServiceType(productName:String)
    {
        if(productName.toLowerCase().includes("residential"))
        {
            return "residential"
        }else if(productName.toLowerCase().includes("commercial"))
        {
            return "commercial"
        }else if(productName.toLowerCase().includes("gutter"))
        {
            return "gutter"
        }else if(productName.toLowerCase().includes("blueprint"))
        {
            return "blueprint"
        }
        return ""
    }
}


export class CartItemRequest {
    public cartItemId: number;
    public address: string;
    public latitude: number;
    public longitude: number;
    public productId: number;
    public customerName: string;
    public pitch: string;
    public expectedDeliveryWithin: string;
    public includeSecondaryStructure: boolean;
    public includeObstructions: boolean;
    public notes: string;
    public modelingInstructions: string;
    public productName:string;
    public productPrice: string;
    public addedAddons:any;
   
    constructor(data:any) {
            this.cartItemId = data.cartItemId;
            this.address = data.address
            this.latitude = data.latitude
            this.longitude = data.longitude
            this.productId = data.reportType
            this.customerName = data.customerName
            this.pitch = data.pitch
            this.expectedDeliveryWithin = data.expectedDeliveryWithin
            this.includeSecondaryStructure = (data.includeSecondaryStructure.toLocaleLowerCase()==='yes')
            this.includeObstructions = (data.includeObstructions.toLocaleLowerCase()==='yes')
            this.notes = data.notes
            this.modelingInstructions = data.modelingInstructions;
            this.productName = data.productName;
            this.productPrice =  data.productPrice;
            this.addedAddons= data.addedAddons
    }
}


export class GetCartItemResponse{
    status?:boolean;
    data? :{cartItems : CartItemRequest[], total:number};
    message?: any;
    errorObj?:any;
}

export class CheckoutResponce {
    data?: {approvalLink: string, cart: Cart,invoiceId:string,productsAndOptions:any};
    errorObject?: any;
    message?: any;
    status?: any;
}
