import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';

@Injectable({
  providedIn: 'root'
})
export class Utill {

  formateDate(params: Date, format: string) {
    return moment(params).format(format);
  }

  getLatlongFromString(latlong: string): LatLngLiteral {
    let _la = latlong.replace(/\s/g, '')
    var commaPos = _la.indexOf(',');
    var coordinatesLat = parseFloat(_la.substring(0, commaPos));
    var coordinatesLong = parseFloat(_la.substring(commaPos + 1, _la.length));

    return {
      lat: coordinatesLat,
      lng: coordinatesLong
    }
  }
}