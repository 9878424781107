<ng-container *ngFor="let cartItem of cart; let i = index;">
    <div class="card border rounded mb-4 mb-4">
        <div class="card-header d-md-flex justify-content-md-between align-items-center "
            [ngClass]="{'border-bottom': (fromComponent === tabs.CART || cartItem['isCollapsed'])}">
            <!-- <div class="ribbon-corner ribbon" *ngIf="fromComponent === tabs.MY_ORDERS"
                [ngClass]="{'ribbon-old': !isNew}">{{isNew? 'New' : 'Old'}}</div> -->
            <div class="d-flex align-items-center header-with-location">
                <div class="type-with-icon">
                    <div class="icon-lg bg-light rounded-5 flex-shrink-0 bg-color-purple-light  " [ngClass]="{'blueprint-icon-size':cartItem.serviceType === 'blueprint' ,'gutter-icon-size':cartItem.serviceType === 'gutter'}">
                        <i class="fa-solid fa-hotel default-color " *ngIf="cartItem.serviceType === 'commercial'"></i>
                        <i class="fa-solid fa-house default-color " *ngIf="cartItem.serviceType === 'residential'"></i>
                        <i class="icon-gutter-2 default-color " *ngIf="cartItem.serviceType === 'gutter'"></i>
                        <i class="icon-blueprint-3 default-color " *ngIf="cartItem.serviceType === 'blueprint'"></i>
                    </div>
                    <div class="blueprint-info">
                        <div>
                            <h6 class="card-title mb-0 ellipsis   ">#<span *ngIf="fromComponent != tabs.MY_ORDERS">
                                {{cartItem.cartItemId}} </span> {{cartItem.invoiceId}} | {{cartItem.productName}}
                            
                        </h6>
                        </div>
                        <div  style="color:blue" *ngIf="cartItem.serviceType == 'blueprint'">
                            <span class="alert-reason">
                                <a tooltip="Please email all the available blueprints to corporate@krlinc.com and make sure to mark the length of the longest eave in Feet." [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')"
                                     class="fs-6 "  >
                                     &nbsp; Email blueprints to corporate@krlinc.com &nbsp;<i class="bi bi-info-circle"></i>
                                </a>
                            </span>
                           

                        </div>
                        
                    </div>
                    
                    
                </div>
                <div class="location-below">
                    <i class="bi bi-geo-alt me-2 default-color"></i><strong>{{cartItem.address}}</strong>
                </div>
            
                <span class="location-below payment-status"
                    *ngIf="cartItem.paymentStatus && cartItem.paymentStatus==orderStatus.COMPLETED"
                    [innerHtml]="paymentStatusTags.Success.replace('$type',cartItem.paymentStatus)">
                </span>
                <span class="location-below payment-status"
                    *ngIf="cartItem.paymentStatus && cartItem.paymentStatus==orderStatus.PENDING"
                    [innerHtml]="paymentStatusTags.Danger.replace('$type',cartItem.paymentStatus)">
                </span>
            </div>

            <div class="flex d-flex">
                <a class="h5 fw-normal mb-0 d-flex justify-content-center align-item-center "
                    *ngIf="products && products.get(cartItem.reportType) && products.get(cartItem.reportType).price && fromComponent === tabs.CHECKOUT">&#36;&nbsp;{{cartItem.price}}&nbsp;&nbsp;</a>
                <a class="h5 fw-normal mb-0 d-flex justify-content-center align-item-center"
                    *ngIf="fromComponent === tabs.MY_ORDERS">&#36;&nbsp;{{cartItem.price | number :
                    '1.1-1'}}&nbsp;&nbsp;</a>
                <i class="fa-solid fa-angles-up d-flex justify-content-center align-item-center bg-color-purple-light expand-collapse"
                    *ngIf="cartItem['isCollapsed']" (click)="cartItem['isCollapsed'] = !cartItem['isCollapsed']"></i>
                <i class="fa-solid fa-angles-down d-flex justify-content-center align-item-center bg-color-purple-light expand-collapse"
                    *ngIf="!cartItem['isCollapsed']" (click)="cartItem['isCollapsed'] = !cartItem['isCollapsed']"></i>
            </div>

        </div>

        <div class="card-body" [@collapse]="!cartItem['isCollapsed']">
            <div class="row g-3">
                <div class="col-sm-6 col-md-4" title="Expected DeliveryWithin" *ngIf="cartItem.expectedDeliveryWithin">
                    <span><i class="fas fa-history text-info"></i>&nbsp; {{cartItem.expectedDeliveryWithin}}</span>
                </div>
                <!-- <div class="col-sm-6 col-md-4">
                    <span>
                        <i class="fa-solid fa-check-circle" style="color:green"
                            *ngIf="cartItem.includeObstructions == 'Yes'"></i>
                        <i class="fas fa-times-circle" style="color:red"
                            *ngIf="cartItem.includeObstructions == 'No'"></i>
                        &nbsp;Include Obstructions
                    </span>
                </div> -->
                <div class="col-sm-6 col-md-4 sf-center">
                    <span>
                        <i class="fa-solid fa-check-circle" style="color:green"
                            *ngIf="cartItem.includeSecondaryStructure == 'Yes'"></i>
                        <i class="fas fa-times-circle" style="color:red"
                            *ngIf="cartItem.includeSecondaryStructure == 'No'"></i>
                        &nbsp;Include Secondary Structure

                    </span>
                </div>
            </div>
            <div class="row g-3 mt-1" title="Modeling Instructions" *ngIf="cartItem.modelingInstructions">
                <span><i class="fa fa-thin fa-person-chalkboard text-info"></i>&nbsp; {{cartItem.modelingInstructions}}
                </span>
            </div>
            <div class="row g-3 mt-1" title="Notes" *ngIf="cartItem.notes">
                <span><i class="bi bi-chat-right-text-fill text-info"></i>&nbsp; {{cartItem.notes}} </span>
            </div>

            <div class="row g-3 mt-1">
                <div class="col-sm-6 col-md-4" title="Customer Name">
                    <span><i class="bi bi-person-fill text-info"></i>&nbsp; {{cartItem.customerName}}<span *ngIf="!cartItem.customerName">NA</span></span>
                </div>

                <div class="col-sm-6 col-md-4" title="Pitch" *ngIf="cartItem.pitch">
                    <span><i class="fas fa-drafting-compass text-info"></i>&nbsp; {{cartItem.pitch}}</span>
                </div>
            </div>

            <div class="g-3 mt-4 h6" *ngIf="cartItem.addedAddons !== undefined && cartItem.addedAddons !== null && cartItem.addedAddons.length>0 ">Addons</div>
            <div class="row g-3 mt-1" >
               
                <div class="col-sm-6 col-md-4" title="Addons" *ngFor="let addedAddon of cartItem.addedAddons">
                    <span><i class="fa-solid fa-check-circle" style="color:green"></i>&nbsp; {{addedAddon}}</span>
                </div>

            <div *ngIf="cartItem.addonsErrorMessage"><span style="color:red"><i class="fas fa-times-circle" style="color:red"></i>&nbsp;{{cartItem.addonsErrorMessage}}</span></div>
            </div>
            <div class="row g-3 mt-1" *ngIf="cartItem.reason">
                <span class="alert-reason">
                    <a 
                         class="fs-6 "  [class]="colorClasses[cartItem.orderItemStatus.toUpperCase()]">
                        <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                    </a> {{cartItem.reason}}
                </span>
            </div>
        </div>
        <div class="card-footer">
            <div class="row g-1 mt-1">
                <div class=" action-block " *ngIf="fromComponent === tabs.CART"
                    [ngClass]="{'border-top': cartItem['isCollapsed']}">
                    <div class="mt-2">
                        <a class="btn btn-sm btn-light mb-0 cart-amount"
                            *ngIf="products && products.get(cartItem.reportType) && products.get(cartItem.reportType).price">&#36;&nbsp;{{cartItem.price}}</a>
                        &nbsp;
                        <a class="btn btn-sm btn-primary-soft mb-0"
                            [routerLink]="['/order-now', {itemIndex: i, itemId: cartItem.cartItemId}]"><i
                                class="bi bi-pencil-square fa-fw me-1"></i>Edit</a>
                        &nbsp;
                        <a class="btn btn-sm btn-danger-soft mb-0" (click)="onDelete(cartItem)" data-bs-toggle="modal"
                            data-bs-target="#cancellation"><i class="bi bi-trash3 fa-fw me-1"></i>Delete</a>
                    </div>
                </div>
                <div class="d-flex justify-content-between border-top pt-1" *ngIf="fromComponent === tabs.MY_ORDERS">
                    <div class="product-status">
                        <span *ngIf="cartItem.orderItemStatus==orderStatus.PENDING"
                            [innerHtml]="tags.Primary.replace('$type',cartItem.orderItemStatus)">
                        </span>
        
                        <span *ngIf="cartItem.orderItemStatus==orderStatus.PROCESSING"
                            [innerHtml]="tags.Info.replace('$type',cartItem.orderItemStatus)">
                        </span>
        
                        <span *ngIf="cartItem.orderItemStatus==orderStatus.COMPLETED"
                            [innerHtml]="tags.Success.replace('$type',cartItem.orderItemStatus)">
                        </span>
                        <span *ngIf="cartItem.orderItemStatus==orderStatus.CANCELED"
                            [innerHtml]="tags.Danger.replace('$type',cartItem.orderItemStatus)">
                        </span>
                        <span *ngIf="cartItem.orderItemStatus==orderStatus.PAYMENT_PENDING"
                            [innerHtml]="tags.Danger.replace('$type',cartItem.orderItemStatus)">
                        </span>
                        <span *ngIf="cartItem.orderItemStatus==orderStatus.REFUNDED"
                            [innerHtml]="tags.Dark.replace('$type',cartItem.orderItemStatus)">
                        </span>
                        <span class="alert-reason">
                            <a *ngIf="cartItem.reason" [tooltip]="cartItem.reason"
                                [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')" class="fs-6 "
                                [class]="colorClasses[cartItem.orderItemStatus.toUpperCase().replace(' ','_')]">
                                <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                            </a>
                        </span>
                    </div>
        
                    <div class="order-Id">
                        <h6 class="card-title mb-0 ellipsis mx-3" *ngIf="cartItem.orderId">{{cartItem.orderId}}</h6>
                    </div>
                    <div>
                        <span class="">{{cartItem.orderDate | date}}</span>
                    </div>
                </div>
            </div>

            <div class="row g-1 mt-1 border-top" *ngIf="fromComponent === tabs.MY_ORDERS">
                
                    <span class="action-item-status" *ngIf="actionItem">
                        
                        <!-- <button type="button" class="btn btn-outline-primary item"><i class="bi bi-currency-dollar"></i>Invoice</button> -->
                       
                        <button type="button" id ="download-{{cartItem.orderId}}" class="btn btn-outline-primary item" (click)="downloadOrderZipFile(cartItem.orderId,cartItem.invoiceId)"  *ngIf="cartItem.orderZipFileStatus">
                            <i class="bi bi-cloud-download"></i>&nbsp;<span>Download Report</span>
                        </button>

                        <span class="alert-reason"  *ngIf="!cartItem.orderZipFileStatus && !isOrderStatusPresent(cartItem.orderItemStatus) ">
                            <a tooltip="Download report for this order is not available, please contact at corporate@krlinc.com." [theme]="(userService.triggerModeChange$ | async)?.replace('-mode','')"
                                 class="fs-6 " style="color: red;" >
                                 &nbsp; Download Report &nbsp;<i class="bi bi-info-circle"></i>&nbsp;&nbsp;
                            </a>
                        </span>
                        <!-- <button type="button" class="btn btn-outline-primary item"><i class="bi bi-badge-3d"></i>&nbsp;3D Model</button> -->
                        <button type="button" class="btn btn-outline-primary item" (click)="viewMap(cartItem.latitude,cartItem.longitude,cartItem.orderId)"><i class="bi bi-globe-americas" ></i>&nbsp;View Property</button>
                        
                    </span>
                    
                   
            </div>
            <div  id="download-error-{{cartItem.orderId}}" class="download-error">
                <!-- <i class="fa-sharp fa-solid fa-circle-exclamation"></i>&nbsp;
                <span ></span> -->
            </div>
        </div>
    </div>
</ng-container>