import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
const httpOptions = {
  headers: new HttpHeaders({
      //   'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Headers': 'Content-Type',
      // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE' 
  })
};
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient,) { }

  updatePassword(data: any): Observable<any>
  {
    return this.http.put(
      environment.apiUrl + '/customer/update/password',
      data,
      httpOptions
  );
  }

  resetPassword(data: any): Observable<any>
  {
    return this.http.put(
      environment.apiUrl + '/customer/resetpassword-verify',
      data,
      httpOptions
  );
  }

  updateProfile(data: any): Observable<any>
  {
    return this.http.put(
      environment.apiUrl + '/customer/update',
      data,
      httpOptions
  );
  }
}
