import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    //   'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*','Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Headers': 'Content-Type',
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE' 
  })
};
@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(  private router: Router,  private http: HttpClient) {  }
  resetPassword(email: string) {
    return this.http.get<any>(`${environment.apiUrl}/customer/resetpassword?email=`+email)
        .pipe(map(data => {
            return data.message;
        }));
}
}
