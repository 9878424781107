import { Component, Input } from '@angular/core';
export const COPYRIGHTS_VIEW_TYPE={
  SHORT:'short',
  LONG: 'long'
}

@Component({
  selector: 'app-copyrights',
  templateUrl: './copyrights.component.html',
  styleUrls: ['./copyrights.component.scss']
})
export class CopyrightsComponent {
  @Input() mode?:string='long';
}
