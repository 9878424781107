<!-- =======================
Accuracy content START -->
<section class="pt-0 pt-lg-5">
    <div class="container">
        <!-- Title -->
        <div class="row mb-3 mb-sm-4">
            <div class="col-12 text-center">
                <h1>Report Accuracy</h1>
            </div>
        </div>
        <!-- Content START -->
        <div class="row mb-4 mb-md-5">
            <div class="col-md-10 mx-auto">
                <p class="fw-bold">At <strong class="text-primary">KRL INC</strong>, we understand the importance of providing accurate and reliable roof reporting services. We
                    take great care to ensure that our drafted reports are as comprehensive and accurate as possible.</p>

                    <div class="col-md-12 col-xl-12">
                        <ul class="list-group list-group-borderless">
                            <li class="list-group-item py-3">
                                <h6 class="mb-0 fw-normal">
                                    <div class="ratings">
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i><span class="mx-3">This rating guarantee’s you 95% and above accuracy of your project.</span> 
                                    </div>
                                </h6>
                            </li>
                            <li class="list-group-item py-3">
                                <h6 class="mb-0 fw-normal">
                                    <div class="ratings">
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star "></i><span class="mx-3">This rating guarantee’s you 90-95% accuracy of your project.</span> 
                                    </div>
                                </h6>
                            </li>
                            <li class="list-group-item py-3 mb-4">
                                <h6 class="mb-0 fw-normal">
                                    <div class="ratings">
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star rating-color"></i>
                                        <i class="fa fa-star "></i>
                                        <i class="fa fa-star"></i><span class="mx-3">This rating guarantee’s you 85-90% accuracy of your project.</span> 
                                    </div>
                                </h6>
                            </li>
                            
                        </ul>
        
                    </div>

                <p>This star rating is dependent upon following factors.</p>    
                <span>
                    <ul>
                        <li>Accuracy varies due to poor quality (skewed, hidden due to obstructions) or unavailability of oblique images.</li>
                        <li>Sometimes the house(building/project) is covered with trees or a portion of the house is hidden because of trees due to which we have to guess and design best possible outcome for the hidden portion.</li>
                        <li>Some times in remote locations the oblique imagery/satellite imagery is un available or of poor quality because of which pitch calculation is next to impossible at that time we have to guess the most suitable given pitch given to the roof and design it accordingly.</li>
                    </ul>
                </span>
                <p>We always try and give our customers most accurate report of their ordered project and always try to maintain 95% and above accuracy, but sometimes due to imagery issue the accuracy may be compromised in some specific portion of the house.</p>
                <p>Your report contains star ratings which will indicate the level of accuracy achieved in designing that certain report. If some portion is designed via guess work, it will be mentioned in detail like pitch guessing or drawn portion guessing along with the specific reason that why we have to do the guess work and where actually the guess work is done.</p>
                <p class="mb-10 mt-2 text-justify">Our team of experienced and certified professionals uses the latest technology and equipment to
                    conduct thorough inspections of your roof(via satellite imagery). We pay attention to every detail and
                    leave no stone unturned to ensure that all issues and potential problem areas are identified.</p>

                <p class="mb-10 text-justify">We understand that the information provided in our reports is important for making decisions about
                    repairs, maintenance, and insurance claims, that&#39;s why we take great care to ensure that the
                    information provided is accurate.</p>

                <p class="mb-10 text-justify">We stand behind the accuracy of our reports and are committed to providing our customers with the
                    most accurate information possible. If you have any questions or concerns about the accuracy of your
                    report, please contact us and we will be happy to address them.</p>


                <p class="mb-10 text-justify">We are committed to providing our customers with the best service possible, and our goal is to always
                    exceed your expectations.</p>

               

            </div>
        </div>
        <!-- Content END -->


    </div>
</section>
<!-- =======================
Accuracy content END -->