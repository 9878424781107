import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, FEATURE, StorageService, UI_MODE, UserService } from '@app/_services';
import { CartService } from '@app/_services/cart.service';
import { APP_ICONS } from '@app/_services';
import { Observable } from 'rxjs';
import { User } from '@app/_models';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public isDarkMode: boolean = false;
  public isUserLoggedIn: boolean = false;
  public user$: Observable<User | null>;
  public cartCount: number = 0;
  public app_icons: any = APP_ICONS;
  public hasNewNotification: boolean = false;
  public feature = FEATURE
  public isMobileDevice: boolean = false;
  public userName: string = 'Dear User';
  public nameInitials: string = 'DU';
  public userEmail: string|undefined = 'example@gmail.com';

  constructor(private storageService: StorageService,
    private authService: AuthenticationService,
    private router: Router,
    private cartService: CartService,
    private deviceService: DeviceDetectorService,
    private userService: UserService) {

    this.epicFunction();

    let mode = this.storageService.getLocalSt(UI_MODE.KEY)
    if (mode == UI_MODE.DARK) {
      this.isDarkMode = true;
      this.setMode(mode, UI_MODE.LIGHT);
    } else if (mode == UI_MODE.LIGHT) {
      this.isDarkMode = false;
      this.setMode(mode, UI_MODE.DARK);
    } else {
      this.isDarkMode = false;
      this.setMode(mode, UI_MODE.DARK);
    }
    this.user$ = this.authService.user;
    this.authService.user.subscribe(x => {
      this.userName = x?.data?.user?.firstName + ' ' + x?.data?.user?.lastName
      this.userEmail = x?.data?.user?.email;
      this.nameInitials = this.getInitals(this.userName);
      this.isUserLoggedIn = this.authService.isUserLoggedIn();
      this.getCartCount();
    });
  }

  getInitals(fullName:string){
    let names = fullName.split(' ');
    let initials = names[0].charAt(0) + names[names.length - 1].charAt(0)
    return initials;
  }
  public toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) {
      this.storageService.setLocalSt(UI_MODE.KEY, UI_MODE.DARK);
      this.setMode(UI_MODE.DARK, UI_MODE.LIGHT)
    } else {
      this.storageService.setLocalSt(UI_MODE.KEY, UI_MODE.LIGHT);
      this.setMode(UI_MODE.LIGHT, UI_MODE.DARK)
    }

    
  }

  setMode(mode: any, removeAttribute: any) {
    var root = document.getElementsByTagName('html')[0];
    root.removeAttribute('class');
    root.setAttribute('class', mode);
    this.userService.triggerModeClick(mode)
  }

  logout() {
    this.authService.logout();
  }

  getCartCount() {
    this.cartService.getCartCount$().subscribe((count: number) => this.cartCount = count)
  }

  epicFunction() {
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();
    if(isMobile){
     this.isMobileDevice = true;
    }
  }
}
