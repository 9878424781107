<!-- Responsive offcanvas body START -->
    <div class="offcanvas-lg offcanvas-end" tabindex="-1" id="offcanvasSidebar" >
        <!-- Offcanvas header -->
        <div class="offcanvas-header justify-content-end pb-2">
            <button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
        </div>

        <!-- Offcanvas body -->
        <div class="offcanvas-body p-3 p-lg-0">
            <div class="card bg-light w-100">

                <!-- Edit profile button -->
                <div class="position-absolute top-0 end-0 p-3">
                    <a href="#" class="text-primary-hover" data-bs-toggle="tooltip" data-bs-title="Edit profile">
                        <i class="bi bi-pencil-square"></i>
                    </a>
                </div>

                <!-- Card body START -->
                <div class="card-body p-3">
                    <!-- Avatar and content -->
                    <div class="text-center mb-3">
                        <!-- Avatar -->
                        <div class="avatar avatar-xl mb-2">
                            <img class="avatar-img rounded-circle border border-2 border-white" src="https://ik.imagekit.io/krlinc/assets/images/avatar/01.jpg" alt="Profile Avatar">
                        </div>
                        <h6 class="mb-0">Jacqueline Miller</h6>
                        <a href="#" class="text-reset text-primary-hover small">hello@gmail.com</a>
                        <hr>
                    </div>

                    <!-- Sidebar menu item START -->
                    <ul class="nav nav-pills-primary-soft flex-column">
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="['/my-profile']"><i class="bi bi-person fa-fw me-2"></i>My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" routerLinkActive="active" [routerLink]="['/orders']"><i class="{{app_icon.MY_ORDERS}}"></i>My Orders</a>
                        </li>
                       
                    </ul>
                    <!-- Sidebar menu item END -->
                </div>
                <!-- Card body END -->
            </div>
        </div>
    </div>	
    <!-- Responsive offcanvas body END -->	
<!-- Sidebar END -->