import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/_services';
declare function scrollToHomePage(element: any): any;

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent {
  @ViewChild('pricing') pricing!: ElementRef;
  constructor(public aRouter: ActivatedRoute, public userService: UserService, private router: Router) { }
  public innerWidth: any;
  public isMobile = false
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 496) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
  ngAfterViewInit() {
    let scrollIntoView = this.aRouter.snapshot.paramMap.get('scrollItem');
    setTimeout(() => {
      if (scrollIntoView === 'pricing') {
        scrollToHomePage(scrollIntoView);
        this.pricing.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 1000)
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth<=496)
      {
        this.isMobile=true
      }else{
        this.isMobile=false
      }
  }
  navigateToChangeService(type: string) {
    this.userService.triggerClick({ serviceType: type, scrollIntoView: 'services' })
  }

  orderNow(type: string) {
    this.router.navigateByUrl('/order-now', { state: { search: '', reportType: type } });

  }
}
