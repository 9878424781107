import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/_models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { USER } from '.';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private storageService: StorageService, private http: HttpClient) { }

  
  getOrders(data:any,searchInOrder:boolean): Observable<any> {
    let ORDERS_API;
    if(searchInOrder){
       ORDERS_API = environment.apiUrl+'/order/new';
    }else{
       ORDERS_API = environment.apiUrl+'/order/old';
    }
    const user = this.storageService.getLocalSt(USER) as User;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '  + user?.data?.accessToken
      })
    };
    return this.http.post(
      ORDERS_API,
      data,
      httpOptions
    );
  }


  downloadOrder(orderId:any,invoiceId:any): Observable<any> {
    let ORDER_DOWNLOAD_API;
  
    ORDER_DOWNLOAD_API = environment.apiUrl+'/order/download?orderId='+orderId+"&invoiceId="+invoiceId;
  
    const user = this.storageService.getLocalSt(USER) as User;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '  + user?.data?.accessToken
      })
    };
    return this.http.get( ORDER_DOWNLOAD_API,);
  }
}
