<section class="vh-xxl-100 mb-4">
	<div class="container h-100 d-flex px-0 px-sm-4">
		<div class="row justify-content-center align-items-center m-auto">
			<div class="col-12">
				<div class="bg-mode shadow rounded-3 overflow-hidden" style="margin-top: -3rem;">
					<div class="row g-0">
						<!-- Vector Image -->
						<div class="col-lg-6 d-md-flex align-items-center order-2 order-lg-1">
							<div class="p-3 p-lg-5">
								<img src="https://ik.imagekit.io/krlinc/assets/images/element/forgot-pass.svg" alt="Forgot Password">
							</div>
							<!-- Divider -->
							<div class="vr opacity-1 d-none d-lg-block"></div>
						</div>

						<!-- Information -->
						<div class="col-lg-6 order-1">
							<div class="p-4 p-sm-7">
								<!-- Logo -->
								<!-- <a href="index-2.html">
									<img class="mb-4 h-50px" src="assets/images/logo-icon.svg" alt="logo">
								</a> -->
								<!-- Title -->
								<h1 class="mb-2 h3">Forgot password?</h1>
								<p class="mb-sm-0"  *ngIf="!hasToken" >Enter the email address associated with an account.</p>
								<p class="mb-sm-0 pb-4"  *ngIf="hasToken" >Please setup new password.</p>
								<!-- Form START -->
								<form class="mt-sm-4 text-start"  *ngIf="!hasToken"  [formGroup]="forgotPasswordForm"
									(ngSubmit)="onSubmit()">
									<!-- Email -->
									<div class="mb-3">
										<label class="form-label">Enter email id</label>
										<input type="email" class="form-control" formControlName="email"
											[ngClass]="{ 'is-invalid': submitted && f.email.errors }">
									</div>
									<!-- <ul class="list-unstyled" *ngIf="error">
										<li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> {{error}}
										</li>
									</ul> -->
									<ul class="list-unstyled" *ngIf="successMsg">
										<li class="mb-1"><i class="bi bi-check-circle text-success me-2"></i>
											{{successMsg}}</li>
									</ul>
									<div class="mt-3 mb-3 text-end">
										<p>Back to <a [routerLink]="['/login']">Sign in</a></p>
									</div>

									<!-- Button -->

									<div><button type="submit" class="btn btn-primary w-100 mb-0" [disabled]="loading">
											<span *ngIf="!loading">Reset Password</span>
											<span class="spinner-border spinner-border-sm" role="status"
												aria-hidden="true" *ngIf="loading"></span>
										</button></div>
									<!-- Divider -->
									<!-- <div class="position-relative my-4">
										<hr>
										<p
											class="small position-absolute top-50 start-50 translate-middle bg-mode px-2">
											Or sign in with</p>
									</div>

									
									<div class="vstack gap-3">
										<a href="#" class="btn btn-light mb-0"><i
												class="fab fa-fw fa-google text-google-icon me-2"></i>Sign in with
											Google</a>
									</div> -->

									<!-- Copyright -->
									<!-- <app-copyrights [mode]="'mini'"></app-copyrights> -->
								</form>

								<app-change-password *ngIf="hasToken" [token]="token"></app-change-password>
								<!-- Form END -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>