<input
      class="date-picker-input "
      type="text"
      mwlFlatpickr
      [(ngModel)]="selectedDate"
      [altInput]="true"
      [convertModelValue]="true"
      [mode]="mode"
      placeholder="Select date"
      (ngModelChange)="changeDate($event)"
    />