import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/_services';
import { environment } from '@environments/environment';
declare function loadTinySlider(): any;
declare function scrollToHomePage(element: any): any;
@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent {

  reportType:string = 'residential';
  residentialDisplay=false;
  commercialDisplay=true;
  blueprintDisplay=true;
  gutterDisplay=true;
  reportMessage:string = 'Residential Roof Report';
 
  reportMessages:any={"residential":"Residential Roof Report","commercial":"Commercial Roof Report","gutter":"Gutter Length Report","blueprint":"Blueprint Roof Report"}
  residentialImages:any[]=[
    
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/1.webp',
      title: 'Residential Roof Report',
      smallMsg:'Single-Family house used for non commercial purpose.'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/2.webp',
    //   title: 'Residential Roof Report',
    //   smallMsg:'Single-Family house used for non commercial purpose.'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/3.webp',
      title: 'Residential Roof Report',
      smallMsg:'Single-Family house used for non commercial purpose.'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/4.webp',
    //   title: 'Residential Roof Report',
    //   smallMsg:'Single-Family house used for non commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/5.webp',
    //   title: 'Residential Roof Report',
    //   smallMsg:'Single-Family house used for non commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/6.webp',
    //   title: 'Residential Roof Report',
    //   smallMsg:'Single-Family house used for non commercial purpose.'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/7.webp',
      title: 'Residential Roof Report',
      smallMsg:'Single-Family house used for non commercial purpose.'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/8.webp',
    //   title: 'Residential Roof Report',
    //   smallMsg:'Single-Family house used for non commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/9.webp',
    //   title: 'Residential Roof Report',
    //   smallMsg:'Single-Family house used for non commercial purpose.'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/residential/10.webp',
      title: 'Residential Roof Report',
      smallMsg:'Single-Family house used for non commercial purpose.'
    }
  ];
  commercialImages:any[]=[
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/1.webp',
      title: 'Commercial Roof Report',
      smallMsg:'Large commercial complex and used for commercial purpose.'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/2.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/3.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/4.webp',
      title: 'Commercial Roof Report',
      smallMsg:'Large commercial complex and used for commercial purpose.'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/5.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/6.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/7.webp',
      title: 'Commercial Roof Report',
      smallMsg:'Large commercial complex and used for commercial purpose.'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/8.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/9.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/10.webp',
    //   title: 'Commercial Roof Report',
    //   smallMsg:'Large commercial complex and used for commercial purpose.'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/commercial/11.webp',
      title: 'Commercial Roof Report',
      smallMsg:'Large commercial complex and used for commercial purpose.'
    }
  ];
  blueprintImages:any[]=[
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/1.webp',
      title: 'Blueprint Roof Report',
      smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/2.webp',
    //   title: 'Blueprint Roof Report',
    //   smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/3.webp',
      title: 'Blueprint Roof Report',
      smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/4.webp',
    //   title: 'Blueprint Roof Report',
    //   smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/5.webp',
    //   title: 'Blueprint Roof Report',
    //   smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/6.webp',
    //   title: 'Blueprint Roof Report',
    //   smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/7.webp',
      title: 'Blueprint Roof Report',
      smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/8.webp',
    //   title: 'Blueprint Roof Report',
    //   smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    // },
    // {
    //   src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/9.webp',
    //   title: 'Blueprint Roof Report',
    //   smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    // },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/blueprint/10.webp',
      title: 'Blueprint Roof Report',
      smallMsg:'Send us blueprints & we will convert them into detailed roof reports (residential only).'
    }
  ];

  gutterImages:any[]=[
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/gutter/1.webp',
      title: 'Gutter Length Report',
      smallMsg:'These are for the installation process of gutter systems on residential properties only.'
    },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/gutter/2.webp',
      title: 'Gutter Length Report',
      smallMsg:'These are for the installation process of gutter systems on residential properties only.'
    },
    {
      src: 'https://ik.imagekit.io/krlinc/assets/images/finalized-images/slider/services/gutter/3.webp',
      title: 'Gutter Length Report',
      smallMsg:'These are for the installation process of gutter systems on residential properties only.'
    },
    
  ];
  smallMessage:string =this.residentialImages[0]['smallMsg']

  protected otherFeatures:any=["11 pages of roof measurements PDF","Separate full length notes page (Diagram)",
                                "Detailed excel data file","CAD File"]
  protected blueprintFeatures:any=["10 pages of roof measurements PDF","Separate full length notes page (Diagram)",
                                "Detailed excel data file","3D model"]
  protected gutterFeatures:any=["3 pages of roof measurements PDF",
  "Floor-wise measurement summary","Gutter & downspout length","Separate Notes page"]
  features:any=this.otherFeatures

  protected otherFeaturesIcons=['fa-solid fa-file-pdf','fa-solid fa-note-sticky','fa-solid fa-file-csv','fa-solid fa-pen-ruler']
  protected gutterFeaturesIcons=['fa-solid fa-file-pdf','fa-solid fa-pen-ruler','fa-solid fa-pen-ruler','fa-solid fa-note-sticky']
  featuresIcons=this.otherFeaturesIcons
  @ViewChild('services') services!: ElementRef;
  clickSubscription$:any;

  constructor(public aRouter: ActivatedRoute, public userService: UserService,private router: Router) {

    this.clickSubscription$ = this.userService.triggerHomeClick$.subscribe((data: any) => {
      if(!data){
        return
      }
      
      if (data.serviceType === 'residential') {
        this.reportType = 'residential';
        scrollToHomePage(data.scrollIntoView);
       
      } else if (data.serviceType === 'commercial') {
        this.reportType = 'commercial';
        scrollToHomePage(data.scrollIntoView);
       
      }else if (data.serviceType === 'gutter') {
        this.reportType = 'gutter';
        scrollToHomePage(data.scrollIntoView);
       
      }else if (data.serviceType === 'blueprint') {
        this.reportType = 'blueprint';
        scrollToHomePage(data.scrollIntoView);
        
      }

    })
  }
public innerWidth: any;
public isMobile=false
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth<=496)
    {
      this.isMobile=true
    }else{
      this.isMobile=false
    }
}
@HostListener('window:resize', ['$event'])
onResize(event:any) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth<=496)
    {
      this.isMobile=true
    }else{
      this.isMobile=false
    }
}
  toggle(type:string){
    this.reportType = type;
    // this.reportMessage = this.reportType ==='residential'? 'Residential Roof Report' :'Commercial Roof Report'
    if(this.reportType.toLowerCase() in this.reportMessages)
    {
      this.reportMessage=this.reportMessages[this.reportType]
    }


    if ( this.reportType === 'residential') {
      this.reportType = 'residential';      
      this.residentialDisplay=false;
      this.commercialDisplay=true;
      this.blueprintDisplay=true;
      this.gutterDisplay=true;
      this.smallMessage=this.residentialImages[0]['smallMsg']
      this.features=this.otherFeatures
    } else if ( this.reportType === 'commercial') {
      this.reportType = 'commercial';     
      this.residentialDisplay=true;
      this.commercialDisplay=false;
      this.blueprintDisplay=true;
      this.gutterDisplay=true;
      this.smallMessage=this.commercialImages[0]['smallMsg']
      this.features=this.otherFeatures
      this.featuresIcons=this.otherFeaturesIcons
    }else if ( this.reportType === 'gutter') {
      this.reportType = 'gutter';     
      this.residentialDisplay=true;
      this.commercialDisplay=true;
      this.blueprintDisplay=true;
      this.gutterDisplay=false;
      this.smallMessage=this.gutterImages[0]['smallMsg']
      this.features=this.gutterFeatures
      this.featuresIcons=this.gutterFeaturesIcons
    }else if ( this.reportType === 'blueprint') {
      this.reportType = 'blueprint';
      this.residentialDisplay=true;
      this.commercialDisplay=true;
      this.blueprintDisplay=false;
      this.gutterDisplay=true;
      this.smallMessage=this.blueprintImages[0]['smallMsg']
      this.features=this.blueprintFeatures
      this.featuresIcons=this.otherFeaturesIcons
    }
  }

  ngAfterViewInit(){
   setTimeout(()=> loadTinySlider());
   let scrollIntoView = this.aRouter.snapshot.paramMap.get('scrollItem');
   let serviceType = this.aRouter.snapshot.paramMap.get('serviceType');
       setTimeout(()=>{
           if(scrollIntoView === 'services'){
               this.services.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
               scrollToHomePage(scrollIntoView);
           }
       },1000)
    }

  downlaodPDF(){
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href ='assets/downloads/'+this.reportType+'-sample-report.pdf'
    //link.download = this.reportType ==='residential' ? 'residential-sample-report': 'commercial-sample-report';
    link.download = this.reportType +'-sample-report';
    link.click();
    link.remove();
    // window.open(this.reportType ==='residential' ?environment.residential_pdfPath : environment.commercial_pdfPath, '_blank')
  }

  ngOnDestroy() {
    this.clickSubscription$.unsubscribe();
  }

  orderNow() {
    this.router.navigateByUrl('/order-now', { state: {search:'', reportType:this.reportType }});
  
  }
}


