import { Component, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-status-page',
  templateUrl: './payment-status-page.component.html',
  styleUrls: ['./payment-status-page.component.scss']
})
export class PaymentStatusPageComponent {
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle("Payment Status - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "" })
  }
  @Input() isSuccess: boolean = false;
  @Input() paymentSuccessMessage: string = 'Your payment is successful and order has been placed';
  @Input() paymentErrorMessage: string = '';
  @Input() paymentErrorTitle: string = '';
}
