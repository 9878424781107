import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { BehaviorSubject } from 'rxjs';
export const UI_MODE={
    DARK:'dark-mode',
    LIGHT: 'light-mode',
    KEY:'mode'
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) { }
    private triggerHomeClick = new BehaviorSubject<any>(null);
    public triggerHomeClick$ = this.triggerHomeClick.asObservable();

    private triggerModeChange = new BehaviorSubject<any>(null);
    public triggerModeChange$ = this.triggerModeChange.asObservable();

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    triggerClick(data:any){
        this.triggerHomeClick.next(data);
    }

    triggerModeClick(data:any){
        if(data){
        this.triggerModeChange.next(data);
        }
    }
}