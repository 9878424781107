import { Component } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-view-property-threed-map',
  templateUrl: './view-property-threed-map.component.html',
  styleUrls: ['./view-property-threed-map.component.scss']
})

export class ViewPropertyThreedMapComponent {
  constructor(private route: ActivatedRoute,private sanitizer: DomSanitizer,
    private title: Title, private meta: Meta) { 
      this.title.setTitle("Property Satellite View - KRL INC.");
      this.meta.updateTag({ name: 'description', content: "KRL INC. provides the best most accurate and most affordable aerial roof reports in USA and Canada. Our expert team uses satellite imagery, state-of-the-art technology, and industry-leading practices to provide detailed and reliable reports." })

    }
  center: google.maps.LatLngLiteral = { lat: 36.456440, lng: -116.869582 };
  markerPosition: any;
  threeDFileExist= false;
  threeDFileUrl="";
  options: google.maps.MapOptions = {
    tilt: 0,
    mapTypeId: 'satellite',
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
    // streetViewControlOptions: {
    //   position: google.maps.ControlPosition.RIGHT_TOP,
    // },
    scrollwheel: true,
    disableDoubleClickZoom: true,
    zoom: 19,
    disableDefaultUI: true,
    heading: 90,
    fullscreenControl: true,
    scaleControl: true,
    streetViewControl: false,
    
  };
  markerOptions: google.maps.MarkerOptions = { 
    draggable: false,  
    clickable: false
  };
  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
     
      try{
        
        let  lng: number = +params["lat"];
        let lat: number = +params["lng"];
        let orderId: any = params["orderId"]
        this.markerPosition = { lat: lng, lng: lat };
        this.center = this.markerPosition;
        this.existsFile(orderId)
      }catch{

      }
      
      
    });
   
  }
   existsFile(orderName:any) {
    orderName=orderName.toLowerCase()
    var url = environment.three_d_file_base_url+orderName+".gltf";
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if(http.status==200)
    {
      this.threeDFileExist=true;
      this.threeDFileUrl="assets/3dviewer/#model="+url
      this.sanitizer.bypassSecurityTrustResourceUrl( this.threeDFileUrl);
    }
   
 }
}


