<section class="pt-0 pt-lg-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12  d-flex flex justify-content-center flex-column">

                <google-map #map [options]="options" [center]="center" class="map-container container  rounded-3" height="90%" width="100%">
                    <map-marker  #marker="mapMarker" [position]="markerPosition" [options]="markerOptions"  ></map-marker>

                </google-map>

            </div>
        </div>
    </div>
</section>