export class OrderStatus {
}





export class OrdersGroupBy {
    invoiceId: string;
    status: string;
    orderDate: string;
    orders: Orders[];
    total: any;
    constructor(data: { invoiceId: string; status: string; orderDate: string; orders: Orders[]; total: any; }) {
      this.invoiceId = data.invoiceId
      this.status = data.status
      this.orderDate = data.orderDate
      this.orders = data.orders
      this.total = data.total
    }
  
  
  }
  
  export class Orders {
    address?: any;
    customerName?: any;
    expectedDeliveryWithin?: any;
    includeObstructions?: any;
    includeSecondaryStructure?: any;
    invoiceId?: any;
    latitude?: any;
    longitude?: any;
    model?: any;
    modelingInstructions?: any;
    notes?: any;
    orderDate?: any;
    orderId?: any;
    orderItemStatus?: any;
    paymentMethod?: any;
    pitch?: any;
    price?: any;
    productName?: any;
    serviceType?: any;
    status?: any;
    total?: any;
    reason?:any;
    constructor(data: any) {
      this.address = data.address;
      this.customerName = data.customerName;
      this.expectedDeliveryWithin = data.expectedDeliveryWithin;
      this.includeObstructions = data.includeObstructions;
      this.includeSecondaryStructure = data.includeSecondaryStructure;
      this.invoiceId = data.invoiceId;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.model = data.model;
      this.modelingInstructions = data.modelingInstructions;
      this.notes = data.notes;
      this.orderDate = data.orderDate;
      this.orderId = data.orderId;
      this.orderItemStatus = data.orderItemStatus;
      this.paymentMethod = data.paymentMethod;
      this.pitch = data.pitch;
      this.price = data.price;
      this.productName = data.productName;
      this.serviceType = data.serviceType;
      this.status = data.status;
      this.total = data.total;
      this.reason = data.reason;
    }
  }