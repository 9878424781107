import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle("About Us - KRL INC.");
    this.meta.updateTag({ name: 'description', content: "At KRL INC, we take pride in our commitment to customer satisfaction. We are dedicated to providing our customers with the information they need to make informed decisions about their roofing needs." })
    this.meta.updateTag({ name: 'keywords', content: "Best aerial roof reports,Residential roof reports,Commercial roof reports" })

    
  }
}
