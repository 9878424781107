import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-data-found-placeholder',
  templateUrl: './no-data-found-placeholder.component.html',
  styleUrls: ['./no-data-found-placeholder.component.scss']
})
export class NoDataFoundPlaceholderComponent {
  @Input() show_404: boolean = true;
  @Input() message?: string = 'Oh no, something went wrong!';
  @Input() subText?: string = 'Either something went wrong or this page doesn\'t exist anymore.'
  @Input() buttonText?: string = 'Take me to Homepage';
  @Input() buttonActionURL?: string ='/';
  @Input() showNavigationButton?:boolean = true;
  constructor() { }

}
