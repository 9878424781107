export * from './auth.guard';
export * from './error.interceptor';
export * from './fake-backend';
export * from './jwt.interceptor';

export const DATE_FORMATES={
    MMM_DD_YYYY : 'MMM-DD-YYYY',
    YYYY_MM_DD : 'YYYY-MM-DD',
    YYYY_mm_DD : 'YYYY-MM-DD',
    MM_DD_YYYY : 'MM-DD-YYYY',
    DD_MM_YYYY : 'DD-MM-YYYY',
    YYYY_MM_DD_HH_mm_ss : 'YYYY-MM-DD HH:mm:ss',
}

