<section class="common-sections-pb1" #services id="services">
	<div class="container">
		<div class="row service-heading">
			<div class="col-12 text-center">
				<!-- Title -->
				<h2>Our <span class="text-primary">Services</span></h2>
			</div>
		</div>
		<div class="row g-4 justify-content-between align-items-center">
			<!-- Left side START -->
			<div class="col-lg-5 position-relative pt-6">
				
				<div class="tiny-slider arrow-round arrow-blur arrow-hover" [ngClass]="{'d-none': residentialDisplay}">
					<div class="tiny-slider-inner mb-8" data-autoplay="true" data-arrow="true" data-edge="1" data-dots="false" data-items-xl="1" data-items-lg="1" data-items-md="1" data-items-sm="1">
						<div *ngFor="let item of residentialImages">
							<div class="card">
								<img src="{{item.src}}" class="card-img" alt="Residential roof report" style="height:70vh">
								<!-- Card body -->
								<div class="position-absolute top-100 start-50 translate-middle w-100">
									<div class="card-body text-center bg-mode shadow rounded mx-2 p-3">
										<h6 class="card-title mb-1"><a href="#">{{item.title}}</a></h6>
										<small>{{item.smallMsg}}</small>
										<div class="mt-2"><a (click)="orderNow()" class="btn btn-sm btn-dark mb-0">Order Now</a></div>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>


				<div class="tiny-slider arrow-round arrow-blur arrow-hover" [ngClass]="{'d-none': commercialDisplay}">
					<div class="tiny-slider-inner mb-8" data-autoplay="true" data-arrow="true" data-edge="1" data-dots="false" data-items-xl="1" data-items-lg="1" data-items-md="1" data-items-sm="1">
						
						<div *ngFor="let item of commercialImages">
							<div class="card">
								<img src="{{item.src}}" class="card-img" alt="Commercial roof report" style="height:70vh">
								<!-- Card body -->
								<div class="position-absolute top-100 start-50 translate-middle w-100">
									<div class="card-body text-center bg-mode shadow rounded mx-2 p-3">
										<h6 class="card-title mb-1"><a >{{item.title}}</a></h6>
										<small>{{item.smallMsg}}</small>
										<div class="mt-2"><a (click)="orderNow()" class="btn btn-sm btn-dark mb-0">Order Now</a></div>
									</div>
								</div>
							</div>
						</div>
						
					</div>	
				</div>

				<div class="tiny-slider arrow-round arrow-blur arrow-hover" [ngClass]="{'d-none': gutterDisplay}">
					<div class="tiny-slider-inner mb-8" data-autoplay="true" data-arrow="true" data-edge="1" data-dots="false" data-items-xl="1" data-items-lg="1" data-items-md="1" data-items-sm="1">
						
						<div *ngFor="let item of gutterImages">
							<div class="card">
								<img src="{{item.src}}" class="card-img" alt="Gutter length report" style="height:70vh">
								<!-- Card body -->
								<div class="position-absolute top-100 start-50 translate-middle w-100">
									<div class="card-body text-center bg-mode shadow rounded mx-2 p-3">
										<h6 class="card-title mb-1"><a >{{item.title}}</a></h6>
										<small>{{item.smallMsg}}</small>
										<div class="mt-2"><a (click)="orderNow()" class="btn btn-sm btn-dark mb-0">Order Now</a></div>
									</div>
								</div>
							</div>
						</div>
						
					</div>	
				</div>

				<div class="tiny-slider arrow-round arrow-blur arrow-hover" [ngClass]="{'d-none': blueprintDisplay}">
					<div class="tiny-slider-inner mb-8" data-autoplay="true" data-arrow="true" data-edge="1" data-dots="false" data-items-xl="1" data-items-lg="1" data-items-md="1" data-items-sm="1">
						
						<div *ngFor="let item of blueprintImages">
							<div class="card">
								<img src="{{item.src}}" class="card-img" alt="Blueprints roof report" style="height:70vh">
								<!-- Card body -->
								<div class="position-absolute top-100 start-50 translate-middle w-100">
									<div class="card-body text-center bg-mode shadow rounded mx-2 p-3">
										<h6 class="card-title mb-1"><a >{{item.title}}</a></h6>
										<small>{{item.smallMsg}}</small>
										<div class="mt-2"><a (click)="orderNow()" class="btn btn-sm btn-dark mb-0">Order Now</a></div>
									</div>
								</div>
							</div>
						</div>
						
					</div>	
				</div>

			</div>
			<!-- Left side END -->
			
			<!-- Right side START -->
			<div class="col-lg-7 mt-2" >
				<ul class="nav nav-pills nav-pills-dark type-toggle service-type-font-size" id="pills-tab" role="tablist" *ngIf="!isMobile">
					<li class="nav-item service-type-item" id="service-li-1" role="presentation">
						<button id="service-type-1" class="nav-link rounded-start rounded-0 mb-0" [ngClass]="{'active': reportType === 'residential'}" id="pills-one-way-tab" data-bs-toggle="pill" data-bs-target="#pills-one-way" type="button" role="tab" aria-selected="true" (click)="toggle('residential')">Residential</button>
					</li>
					<li class="nav-item service-type-item" id="service-li-2" role="presentation">
						<button id="service-type-2" class="nav-link  rounded-0 mb-0" [ngClass]="{'active': reportType === 'commercial'}" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('commercial')">Commercial</button>
					</li>
					<li class="nav-item service-type-item" id="service-li-3" role="presentation" >
						<button id="service-type-3" class="nav-link  rounded-0  mb-0" [ngClass]="{'active': reportType === 'gutter'}" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('gutter')">Gutter</button>
					</li>
					<li class="nav-item service-type-item" id="service-li-4" role="presentation"  >
						<button id="service-type-4" class="nav-link  rounded-0 rounded-end mb-0" [ngClass]="{'active': reportType === 'blueprint'}" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('blueprint')">Blueprint</button>
					</li>
				</ul>

				<ul class="nav nav-pills nav-pills-dark type-toggle service-type-font-size pb-2" id="pills-tab" role="tablist"  *ngIf="isMobile">
					<div class="service-type-mobile">
						<li class="nav-item" id="service-li-1" role="presentation">
							<button id="service-type-1" class="nav-link rounded-start rounded-0 mb-0" [ngClass]="{'active': reportType === 'residential'}" id="pills-one-way-tab" data-bs-toggle="pill" data-bs-target="#pills-one-way" type="button" role="tab" aria-selected="true" (click)="toggle('residential')">Residential</button>
						</li>
						<li class="nav-item" id="service-li-2" role="presentation">
							<button id="service-type-2" class="nav-link rounded-end rounded-0 mb-0" [ngClass]="{'active': reportType === 'commercial'}" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('commercial')">Commercial</button>
						</li>
					</div>
					<div class="pt-2 service-type-mobile">
						<li class="nav-item " id="service-li-3" role="presentation" >
							<button id="service-type-3" class="nav-link  rounded-start rounded-0  mb-0" [ngClass]="{'active': reportType === 'gutter'}" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('gutter')">Gutter</button>
						</li>
						<li class="nav-item" id="service-li-4" role="presentation"  >
							<button id="service-type-4" class="nav-link  rounded-0 rounded-end mb-0" [ngClass]="{'active': reportType === 'blueprint'}" id="pills-round-trip-tab" data-bs-toggle="pill" data-bs-target="#pills-round-trip" type="button" role="tab" aria-selected="false" (click)="toggle('blueprint')">Blueprint</button>
						</li>
					</div>
					
				</ul>
				<h4 class="service-message-title mt-2">{{reportMessage}} <span *ngIf="reportType === 'blueprint'" style="font-size: 15px; color: #315ca6;"><a target="_blank" [routerLink]="['/blue-print-report']">&nbsp;More Info..</a></span></h4>

				<ng-container  >
					<p class="mb-3 mb-lg-5" > {{smallMessage}}</p>
					<!-- Features START -->
					<div class="row g-4">
						<!-- Item -->
						<div class="col-sm-6">
							<div class="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle"><i class="{{featuresIcons[0]}}"></i></div>
							<h5 class="mt-2">{{features[0]}}</h5>
						</div>
						
						<!-- Item -->
						<div class="col-sm-6">
							<div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle"><i class="{{featuresIcons[1]}}"></i></div>
							<h5 class="mt-2">{{features[1]}}</h5>
						</div>
						<!-- Item -->
						<div class="col-sm-6">
							<div class="icon-lg bg-success bg-opacity-10 text-success rounded-circle"><i class="{{featuresIcons[2]}}"></i></div>
							<h5 class="mt-2">{{features[2]}}</h5>
						</div>
						<!-- Item -->
						<div class="col-sm-6">
							<div class="icon-lg bg-info bg-opacity-10 text-info rounded-circle"><i class="{{featuresIcons[3]}}"></i></div>
							<h5 class="mt-2">{{features[3]}}</h5>
						</div>		
					</div>
					<!-- Features END -->
				</ng-container>
				
				<div class="pt-5">
					<a class="btn btn-dark w-100 mb-0" (click)="downlaodPDF()" >Download Sample</a>
				</div>
			</div>
			<!-- Right side END -->
		</div>
	</div>
</section>
<!-- =======================
About END -->