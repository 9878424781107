<section class="vh-xxl-100  mb-4">
	<div class="container h-100 d-flex px-0 px-sm-4">
		<div class="row justify-content-center align-items-center m-auto">
			<div class="col-12">
				<app-loader class="loading-content d-flex justify-content-center align-items-center vh-98" *ngIf="isLoading" [message]="'Please wait, While we are verifying your email address!'"></app-loader>
				<app-copyrights [mode]="'mini'"  *ngIf="isLoading" ></app-copyrights>
				<div class="bg-mode shadow rounded-3 overflow-hidden" *ngIf="!isLoading">
					<div class="row g-0">
						<!-- Vector Image -->
						<div class="col-lg-6 d-flex align-items-center order-2 order-lg-1">
							<div class="p-3 p-lg-5">
								<img src="https://ik.imagekit.io/krlinc/assets/images/element/contact.png" alt="Login">
								
							</div>
							<!-- Divider -->
							<div class="vr opacity-1 d-none d-lg-block"></div>
						</div>
					
						<!-- Information -->
						<div class="col-lg-6 order-1">
							
							<div class="p-4 p-sm-7">
								<!-- Logo -->
								
								<!-- Title -->
								<h1 class="mb-2 h3">Welcome back</h1>
								<p class="mb-0">New here?<a [routerLink]="['/sign-up']"> Create an account</a></p>
								
								<!-- Form START -->
								<form class="mt-4 text-start" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
									<!-- Email -->
									<div class="mb-3">
										<label class="form-label">Enter email id</label>
										<input type="email" class="form-control" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" 
										[attr.disabled]="sendVerification? 'true': null">
									</div>
									<!-- Password -->
									<div class="mb-3 position-relative"  *ngIf="!sendVerification">
										<label class="form-label">Enter password</label>
										<input class="form-control fakepassword" type="{{isviewed?'text':'password'}}" id="psw-input"  formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
										<span class="position-absolute top-50 end-0 translate-middle-y p-0 mt-3">
											<i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" (click)="isviewed = !isviewed"></i>
										</span>
									</div>

                                    <ul class="list-unstyled" *ngIf="error">
                                        <li class="mb-1"><i class="fas fa-times-circle text-danger me-2"></i> {{error}}</li>
                                    </ul>
									<ul class="list-unstyled" *ngIf="success">
                                        <li class="mb-1"><i class="bi bi-check-circle text-success me-2"></i> {{success}}</li>
                                    </ul>
									<ul class="list-unstyled" *ngIf="warning">
                                        <li class="mb-1"><i class="bi bi-exclamation-circle text-warning me-2"></i> {{warning}}</li>
                                    </ul>
                                      
									<!-- Remember me -->
									<div class="mb-3 d-sm-flex justify-content-between"  *ngIf="!sendVerification">
										<div>
											<input type="checkbox" class="form-check-input" id="rememberCheck"  formControlName="rememberMe">
											<label class="form-check-label" for="rememberCheck">Remember me?</label>
										</div>
										<a [routerLink]="['/forgot-password']">Forgot password?</a>
									</div>
									<div class="mb-3 d-sm-flex justify-content-end"  *ngIf="sendVerification">
										<a [routerLink]="['/login']">Back to Login?</a>
									</div>
									<!-- Button -->
									<div *ngIf="!sendVerification">
										<button type="submit" class="btn btn-primary w-100 mb-0" [disabled]="loading" >
											<span *ngIf="!loading">Login</span>
											<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
										</button>
									</div>
		
									<!-- Button -->
									<div  *ngIf="sendVerification">
										<button type="button" (click)="sendVerificationLink()" class="btn btn-primary w-100 mb-0" [disabled]="loading">
											<span *ngIf="!loading">Send me the verification link?</span>
											<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
										</button>
									</div>

									<!-- Divider -->
									<!-- <div class="position-relative my-4">
										<hr>
										<p class="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Or sign in with</p>
									</div>
		
									<div class="vstack gap-3">
										<a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-google text-google-icon me-2"></i>Sign in with Google</a>
										<a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>Sign in with Facebook</a>
									</div> -->
		
									<!-- Copyright -->
									<!-- <app-copyrights [mode]="'mini'"></app-copyrights> -->
                                   
								</form>
								<!-- Form END -->
							</div>		
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

